import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col, Spinner, Alert, Alert as BootstrapAlert, Badge } from "react-bootstrap";
import axios from "../../axios";

const url = process.env.REACT_APP_BE_URL;

function RequestModal({ open, onClose, data2 }) {
    const [isSubmissionLoading, setSubmissionLoading] = useState(false);
    const [submissionType, setSubmissionType] = useState(null);
    const [data, setData] = useState(null);
    const userID = JSON.parse(localStorage.getItem("userInfo"))._id;

    useEffect(() => {
        setData(data2);
    }, [data2]);

    const handleRequestApproalOrReject = (result) => {
        setSubmissionLoading(true);
        setSubmissionType(result);
        const config = {
            headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token}`,
            },
        };

        if (result === "delete") {
            axios.delete(`${url}/request/${data._id}`, config)
                .then(response => {
                    setSubmissionLoading(false);
                    onClose();
                })
                .catch(e => {
                    setSubmissionLoading(false);
                    onClose();
                });
        } else {
            const payload = { userID: userID, message: result };
            axios.put(`${url}/request/${data._id}`, payload, config)
                .then(response => {
                    setSubmissionLoading(false);
                    onClose();
                })
                .catch(e => {
                    onClose();
                    setSubmissionLoading(false);
                });
        }
    };

    return (
        <Modal show={open} onHide={onClose} centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Request Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {data ? (
                    <>
                        <Row className="my-3">
                            <Col xs={4}>
                                <strong>{data.projectName ? "Project Name:" : "Organization Name:"}</strong>
                            </Col>
                            <Col xs={8}>
                                {data.projectName ? data.projectName : data.organizationName}
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col xs={4}>
                                <strong>Request Type:</strong>
                            </Col>
                            <Col xs={8}>
                                {data.type}
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col xs={4}>
                                <strong>Status:</strong>
                            </Col>
                            <Col xs={8}>
                                <Badge
                                    bg={data.status === "pending" ? "warning" : "success"}
                                    text={data.status === "pending" ? "dark" : "light"}
                                >
                                    {data.status}
                                </Badge>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col xs={4}>
                                <strong>Approval Count:</strong>
                            </Col>
                            <Col xs={8}>
                                {data.approvedCount?.length}
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col xs={4}>
                                <strong>Reject Count:</strong>
                            </Col>
                            <Col xs={8}>
                                {data.rejectedApprovals?.length}
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col xs={4}>
                                <strong>Required Count:</strong>
                            </Col>
                            <Col xs={8}>
                                {data?.reqApprovCount.length}
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col xs={4}>
                                <strong>Changed Data:</strong>
                            </Col>
                            <Col xs={8}>
                                <div style={{ maxHeight: '200px', overflowY: 'auto', backgroundColor: '#f8f9fa', padding: '10px', borderRadius: '4px' }}>
                                    <pre>{JSON.stringify(data?.changeData, null, 2)}</pre>
                                </div>
                            </Col>
                        </Row>
                    </>
                ) : (
                    <BootstrapAlert variant="info">
                        No data available to display.
                    </BootstrapAlert>
                )}
            </Modal.Body>
            <Modal.Footer>
                {data ? (
                    <>
                        {data.status === "pending" && data.reqUserID === userID ? (
                            <Button
                                variant="danger"
                                onClick={() => handleRequestApproalOrReject("delete")}
                                disabled={isSubmissionLoading}
                            >
                                {isSubmissionLoading ? <Spinner animation="border" size="sm" /> : 'Delete'}
                            </Button>
                        ) : data.status === "approved" ? null : (
                            <>
                                <Button
                                    variant="primary"
                                    onClick={() => handleRequestApproalOrReject("approve")}
                                    disabled={data.status === "approved" || data.approvedCount.includes(userID) || isSubmissionLoading}
                                >
                                    {submissionType === "approve" && isSubmissionLoading ? <Spinner animation="border" size="sm" /> : 'Approve'}
                                </Button>
                                <Button
                                    variant="danger"
                                    onClick={() => handleRequestApproalOrReject("reject")}
                                    disabled={data.status === "approved" || data.rejectedApprovals.includes(userID) || isSubmissionLoading}
                                    className="ms-2"
                                >
                                    {submissionType === "reject" && isSubmissionLoading ? <Spinner animation="border" size="sm" /> : 'Reject'}
                                </Button>
                            </>
                        )}
                        <Button variant="secondary" onClick={onClose}>
                            Close
                        </Button>
                    </>
                ) : (
                    <Button variant="secondary" onClick={onClose}>
                        Close
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
}

export default RequestModal;
