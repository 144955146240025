import React, { useState } from "react";
import { Button, Modal, Form, Image } from "react-bootstrap";
import axios from "../../axios";
import { ErrorNotification, SuccessNotification } from "../../notifications/notifications";

const url = process.env.REACT_APP_BE_URL;

const ImageUploadModal = ({ show, onClose, userData, updateData }) => {
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [submissionLoading, setSubmissionLoading] = useState(false);


  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setImageFile(reader.result);
        setImagePreview(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const onImageSubmit = async () => {
    setBtnDisabled(true);
    setSubmissionLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };

    try {
      let payLoad = {
        profilePic: imageFile,
      };

      const { status } = await axios.put(
        `${url}/user/${userData._id}`,
        payLoad,
        config
      );

      if (status === 200) {
        SuccessNotification("Profile Picture Updated!");
        setBtnDisabled(false);
        updateData();
        onClose();
      }
    } catch (error) {
      ErrorNotification(error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed");
    } finally {
      setSubmissionLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Upload Profile Picture</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Select an image</Form.Label>
            <Form.Control type="file" onChange={handleImageChange} />
          </Form.Group>
          {imagePreview && (
            <div className="text-center mb-3">
              <Image
                src={imagePreview}
                alt="Selected Preview"
                fluid
                roundedCircle
              />
            </div>
          )}
          <Button
            variant="primary"
            onClick={onImageSubmit}
            disabled={!imageFile || btnDisabled}
          >
            {submissionLoading ? (
              <span className="spinner-border spinner-border-sm" />
            ) : (
              "Upload"
            )}

          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ImageUploadModal;
