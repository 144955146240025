import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "../../../../../axios";
import { Container, Row, Col, Card, Button, Alert } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";

// images
import profile from "../../../../../assets/default-resource-img.png";
import FullScreenSpinner from "../../../../../Components/FullScreenSpinner/FullScreenSpinner";
import InviteCollaboratorsModal from "../../../../../Components/orgModels/InviteCollaboratorsModal";
import CreateJobModal from "../../../../../Components/orgModels/CreateJobModal";
import DuplicateJobModal from "../../../../../Components/orgModels/DuplicateJobModal";
import PublishOrganizationModal from "../../../../../Components/orgModels/PublishOrganizationModal";
import EditOrganizationModal from "../../../../../Components/orgModels/EditOrganizationModal";
import { ErrorNotification, SuccessNotification } from "../../../../../notifications/notifications"
import ListAsResourceModel from "../../../../../Components/orgModels/ListAsResourceModel";

const url = process.env.REACT_APP_BE_URL;

const OrganizationDetails = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  const navigate = useNavigate();
  const { orgId } = useParams();

  const [organization, setOrganization] = useState(null);
  const [jobs, setJobs] = useState(null);
  const [adminUsers, setAdminUsers] = useState(null);
  const [loading, setLoading] = useState(true);
  const [resources, setResources] = useState([]);
  const [isOrganizationListed, setIsOrganizationListed] = useState(false);

  // Check if the organization is already listed as a resource
  useEffect(() => {
    if (resources && organization) {
      const isListed = resources.some(resource => resource.name === organization.name);
      setIsOrganizationListed(isListed);
    }
  }, [resources, organization]);


  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token}`,
    },
  };

  const fetchResources = async (filter) => {
    const _resources = await axios.get(
      `${url}/resource/my-resources/${filter}`,
      axiosConfig
    );
    setResources(_resources.data);
  };

  const fetchResourcesData = async () => {
    await fetchResources("owned");
    setLoading(false);
  };

  // State for job creation
  const [newJob, setNewJob] = useState({
    title: "",
    jobType: "",
    timeAvailability: "",
    description: "",
    responsibilities: [],
    requirements: [],
    qualifications: [],
    skills: [],
  });

  // Modals
  const [showCreateJobModal, setShowCreateJobModal] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [showEditOrgModal, setShowEditOrgModal] = useState(false);
  const [showListAsResource, setShowListAsResource] = useState(false);
  const [showDuplicateJobModal, setShowDuplicateJobModal] = useState(false); // Modal for duplicating a job
  const [showInviteCollaboratorsModal, setShowInviteCollaboratorsModal] =
    useState(false); // Modal for inviting collaborators

  const [selectedJob, setSelectedJob] = useState(null);
  const [updatedJob, setUpdatedJob] = useState(null);

  const handleCloseCreateJobModal = () => setShowCreateJobModal(false);
  const handleClosePublishModal = () => setShowPublishModal(false);
  const handleCloseEditOrgModal = () => setShowEditOrgModal(false);
  const handleCloseListAsResourceModal = () => setShowListAsResource(false);
  const handleCloseDuplicateJobModal = () => {
    setShowDuplicateJobModal(false);
    setSelectedJob(null);
    setUpdatedJob(null);
  };
  const handleCloseInviteCollaboratorsModal = () =>
    setShowInviteCollaboratorsModal(false);

  const fetchData = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
            }`,
        },
      };

      // Fetch organization data
      const { data: orgData } = await axios.get(
        `${url}/organization/${orgId}`,
        config
      );
      setOrganization(orgData);

      // Fetch admin users
      const res = await axios.get(
        `${url}/organization/adminUsers/${orgId}`,
        config
      );
      setAdminUsers(res.data);

      // Fetch jobs
      const jobArr = await Promise.all(
        orgData.jobs.map(async (jobID) => {
          const { data: jobData } = await axios.get(
            `${url}/job/${jobID}`,
            config
          );
          return jobData;
        })
      );
      setJobs(jobArr);

      setLoading(false);
    } catch (error) {
      ErrorNotification(error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Failed to fetch data.");
    }
  };

  useEffect(() => {
    fetchData();
    fetchResourcesData()
  }, [orgId]);

  const userID = JSON.parse(localStorage.getItem("userInfo"))._id;
  const adminCheck = organization?.admins?.filter((id) => {
    return userID === id;
  });

  console.log(adminCheck);


  const userCheck = organization?.users?.filter((id) => {
    return userID === id;
  });

  const handlePublish = () => {
    setShowPublishModal(true);
  };

  const handleEditOrg = () => {
    setShowEditOrgModal(true);
  };

  const handleListAsAResource = () => {
    setShowListAsResource(true);
  };

  const handleDuplicateJob = (job) => {
    setSelectedJob(job);
    setUpdatedJob({ ...job });
    setShowDuplicateJobModal(true);
  };

  const handleInviteCollaborators = () => {
    setShowInviteCollaboratorsModal(true);
  };

  const handlePublishInvite = () => {
    handleClosePublishModal();
    setShowInviteCollaboratorsModal(true);
  };

  const handleDeleteOrg = async () => {
    if (!organization) {
      ErrorNotification("Organization data is not available.");
      return;
    }

    // Check if the organization has jobs associated with it
    if (organization.jobs && organization.jobs.length > 0) {
      ErrorNotification("Organization cannot be deleted as it has associated jobs.");
      return;
    }

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token}`,
        },
      };

      // API call to delete the organization
      await axios.delete(`${url}/organization/delete/${orgId}`, config);

      // Notify user of success
      SuccessNotification("Organization deleted successfully!");

      // Redirect or navigate to another page after deletion
      navigate("/organizations");

    } catch (error) {
      ErrorNotification(error.response?.data?.message || "Failed to delete organization.");
    }
  };


  return (
    <section className="position-relative overflow-hidden bg-gradient2 py-3 px-3">
      <Container className="min-vh-100 bg-light p-4 mt-5 mt-lg-0">
        {loading ? (
          <FullScreenSpinner />
        ) : (
          <>
            <Row className="mb-4 justify-content-between align-items-center">
              <Col xs="auto">
                <div className="d-flex justify-center align-items-center">
                  {organization?.logo && (
                    <img
                      src={organization?.logo.url}
                      alt={`${organization?.name} Logo`}
                      className="img-fluid avatar-sm rounded-sm me-3"
                    />
                  )}
                  <span className="h2">{organization?.name}</span>
                </div>
              </Col>
              <Col xs="auto" className="d-flex gap-2 flex-row">
                {userCheck?.length > 0 && (
                  <Button
                    className={` ${organization.status === "pending" ? "btn-soft-warning" : "btn-soft-success"}`}
                  >
                    <FeatherIcon icon={organization.status === "pending" ? "clock" : "check"} />
                    <span className="d-none d-md-inline ms-2">
                      {organization.status === "pending" ? "Pending" : "Published"}
                    </span>
                  </Button>
                )}

                {adminCheck?.length > 0 && organization.status === "pending" && (
                  <>
                    <Button className="btn-soft-primary" onClick={handlePublish}>
                      <FeatherIcon icon="check" />
                      <span className="d-none d-md-inline ms-2">Publish</span>
                    </Button>

                    <Button className="btn-soft-danger" onClick={handleDeleteOrg}>
                      <FeatherIcon icon="trash-2" />
                      <span className="d-none d-md-inline ms-2">Delete</span>
                    </Button>

                    <Button className="btn-soft-secondary" onClick={handleInviteCollaborators}>
                      <FeatherIcon icon="plus" />
                      <span className="d-none d-md-inline ms-2">Invite Collaborators</span>
                    </Button>
                  </>
                )}

                {adminCheck?.length > 0 && (
                  <>
                    <Button
                      className="btn-soft-success"
                      onClick={handleListAsAResource}
                      disabled={isOrganizationListed} // Disable if already listed
                    >
                      <FeatherIcon icon="list" />
                      <span className="d-none d-md-inline ms-2">
                        {isOrganizationListed ? "Listed" : "List as a Resource"}
                      </span>
                    </Button>
                    <Button className="btn-soft-warning" onClick={handleEditOrg}>
                      <FeatherIcon icon="edit-3" />
                      <span className="d-none d-md-inline ms-2">Edit Details</span>
                    </Button>
                  </>
                )}
              </Col>

            </Row>
            <Row className="mb-4">
              <Col md={8}>
                <p>
                  <strong>Industry:</strong> {organization?.industry}
                </p>
                <p>
                  <strong>Description:</strong> {organization?.description}
                </p>
                <p>
                  <strong>Website:</strong>{" "}
                  <a
                    href={organization?.website}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {organization?.website}
                  </a>
                </p>
              </Col>
            </Row>

            {/* Founding Members */}
            {adminUsers?.length > 0 && (
              <Row className="mb-4">
                <Col>
                  <h2>Founders/Co-Founders</h2>
                  {adminUsers && adminUsers.length > 0 ? (
                    <div className="d-flex flex-column">
                      {adminUsers.map((user) => (
                        <Card
                          key={user._id}
                          className="mb-3 shadow-sm border-0 rounded"
                          style={{ width: "100%" }}
                        >
                          <Card.Body className="d-flex align-items-center">
                            <img
                              src={user.profilePic.url ? user.profilePic.url : profile}
                              alt={`${user.firstName} ${user.lastName}`}
                              className="avatar-lg rounded-circle me-3"
                              style={{ width: "60px", height: "60px" }}
                            />
                            <div className="flex-grow-1">
                              <Card.Title className="mb-1">
                                {user.firstName} {user.lastName}
                              </Card.Title>
                            </div>
                            {/* <Link to="#" className="btn btn-soft-info">
                              View Profile
                            </Link> */}
                          </Card.Body>
                        </Card>
                      ))}
                    </div>
                  ) : (
                    <p>No admin users found.</p>
                  )}
                </Col>
              </Row>
            )}

            {/* job */}
            <Row className="mb-4">
              <Col>
                <Row className="mb-2 justify-content-between align-items-center">
                  <Col xs="auto">
                    <h2>Jobs</h2>
                  </Col>
                  {adminCheck?.length > 0 && (
                    <Col xs="auto">
                      <Button
                        className="btn-soft-secondary"
                        onClick={() => {
                          setShowCreateJobModal(true);
                        }}
                      >
                        <FeatherIcon icon="plus" className="me-2" />
                        Create a Job
                      </Button>
                    </Col>
                  )}
                </Row>
                {jobs && jobs.length > 0 ? (
                  jobs.map((job) => (
                    <Card
                      key={job._id}
                      className="mb-3 shadow-sm border-0 rounded"
                      style={{ width: "100%" }}
                    >
                      <Card.Body>
                        <div className="d-block d-md-flex justify-content-between align-items-center mb-2">
                          <div>
                            <Card.Title className="mb-1">
                              {job.title}
                            </Card.Title>
                            <Card.Text className="mb-0">
                              <small>
                                {job.jobType} - {job.timeAvailability}
                              </small>
                            </Card.Text>
                          </div>
                          <div className="mt-2 mt-md-0">
                            {adminCheck?.length > 0 && <Button
                              variant="outline-info"
                              size="sm"
                              className="me-2"
                              onClick={() => handleDuplicateJob(job)}
                            >
                              <FeatherIcon icon="copy" className="me-1" /> Duplicate
                            </Button>}

                            <Link
                              to={`/jobdetails/${job._id}`}
                              className="btn btn-outline-success btn-sm"
                            >
                              <FeatherIcon icon="eye" />
                            </Link>
                          </div>
                        </div>
                        <Button
                          variant={
                            job.status === "active"
                              ? "success"
                              : "outline-secondary"
                          }
                          size="sm"
                        >
                          {job.status === "active" ? "Active" : "Deactivated"}
                        </Button>
                      </Card.Body>
                    </Card>
                  ))
                ) : (
                  <p>No jobs found.</p>
                )}
              </Col>
            </Row>
          </>
        )}

        {/* Invite Collaborators Modal */}
        <InviteCollaboratorsModal
          showInviteCollaboratorsModal={showInviteCollaboratorsModal}
          handleCloseInviteCollaboratorsModal={
            handleCloseInviteCollaboratorsModal
          }
          orgId={orgId}
          fetchData={fetchData}
        />

        {/* Create Job Modal */}
        <CreateJobModal
          show={showCreateJobModal}
          handleClose={handleCloseCreateJobModal}
          newJob={newJob}
          fetchData={fetchData}
          orgId={orgId}
          setNewJob={setNewJob}
        />

        {/* Duplicate Job Modal */}
        <DuplicateJobModal
          show={showDuplicateJobModal}
          handleClose={handleCloseDuplicateJobModal}
          updatedJob={updatedJob}
          fetchData={fetchData}
        />

        {/* Publish Organization Modal */}
        <PublishOrganizationModal
          orgId={orgId}
          show={showPublishModal}
          handleClose={handleClosePublishModal}
          handleInviteCollaborators={handlePublishInvite}
          fetchData={fetchData}
        />

        {/* Edit Organization Modal */}
        <EditOrganizationModal
          show={showEditOrgModal}
          handleClose={handleCloseEditOrgModal}
          organization={organization}
          orgId={orgId}
          fetchData={fetchData}
        />

        {/* List As Resource Modal */}
        <ListAsResourceModel
          show={showListAsResource}
          handleClose={handleCloseListAsResourceModal}
          organization={organization}
          orgId={orgId}
          fetchData={fetchData}
          fetchResourcesData={fetchResourcesData}
        />
      </Container>
    </section>
  );
};

export default OrganizationDetails;
