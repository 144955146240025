import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axiosInstance from "../../axios";
import { ErrorNotification, SuccessNotification } from "../../notifications/notifications";

// Predefined skills
const predefinedSkills = [
  "HTML",
  "CSS",
  "JavaScript",
  "TypeScript",
  "NodeJS",
  "MongoDB",
  "MySQL",
];

const AddSkillModal = ({
  show,
  handleClose,
  userId,
  getData,
  skillsList,
}) => {
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [newSkill, setNewSkill] = useState(""); // State for new skill input
  const [allSkills, setAllSkills] = useState([...predefinedSkills]); // Combined list of predefined and new skills
  const [submissionLoading, setSubmissionLoading] = useState(false);

  useEffect(() => {
    if (skillsList) {
      // Merge predefined skills and skillsList, avoiding duplicates
      const uniqueSkills = [
        ...new Set([...predefinedSkills, ...skillsList]),
      ];
      setAllSkills(uniqueSkills);
      setSelectedSkills(skillsList);
    }
  }, [skillsList]);

  const handleChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedSkills((prev) => [...prev, value]);
    } else {
      setSelectedSkills((prev) => prev.filter((skill) => skill !== value));
    }
  };

  const handleNewSkillChange = (e) => {
    setNewSkill(e.target.value);
  };

  const handleAddNewSkill = () => {
    if (newSkill && !allSkills.includes(newSkill)) {
      setAllSkills((prev) => [...prev, newSkill]);
      setSelectedSkills((prev) => [...prev, newSkill]);
      setNewSkill(""); // Clear the input after adding
    }
  };

  // Add skill to the list when pressing Enter in the input field
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission
      handleAddNewSkill(); // Call the function to add new skill
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedSkills.length > 0) {
      setSubmissionLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token}`,
        },
      };

      try {
        const payLoad = {
          skills: selectedSkills,
        };

        const res = await axiosInstance.put(
          `${process.env.REACT_APP_BE_URL}/user/${userId}`,
          payLoad,
          config
        );

        if (res.status === 200) {
          SuccessNotification("Skills updated successfully!");
          getData();
          handleClose();
        }
      } catch (error) {
        ErrorNotification(error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed");
      } finally {
        setSubmissionLoading(false);
      }
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size="lg"
      backdrop="static"
      animation={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>Add New Skill</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit} className="py-2">
          <Form.Group controlId="formSkill">
            <Form.Label>Select Skill</Form.Label>
            {allSkills.map((type, index) => (
              <Form.Check
                type="checkbox"
                key={index}
                id={`skill-${index}`}
                label={type}
                value={type}
                checked={selectedSkills.includes(type)}
                onChange={handleChange}
              />
            ))}
            <Form.Label>Add New Skill</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter new skill"
              value={newSkill}
              onChange={handleNewSkillChange}
              onKeyPress={handleKeyPress} // Capture Enter keypress here
            />
            <Button
              variant="primary"
              className="mt-2"
              onClick={handleAddNewSkill}
            >
              Add New Skill
            </Button>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          disabled={submissionLoading}
          onClick={handleSubmit} // Only submit when clicking this button
        >
          {submissionLoading ? (
            <span className="spinner-border spinner-border-sm" />
          ) : (
            "Add Skills"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddSkillModal;
