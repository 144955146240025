import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axiosInstance from "../../../../../axios";
import {
  Button,
  Card,
  Col,
  Container,
  ListGroup,
  Row,
} from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import FullScreenSpinner from "../../../../../Components/FullScreenSpinner/FullScreenSpinner";
import { CircularProgress } from "@mui/material";
import RequiredSkillsModal from "../../../../../Components/jobModels/RequiredSkillsModal";
import JobRequirementsModal from "../../../../../Components/jobModels/JobRequirementsModal";
import JobResponsibilitiesModal from "../../../../../Components/jobModels/JobResponsibilitiesModal";
import QualificationsModal from "../../../../../Components/jobModels/QualificationsModal";
import EditDetailsModal from "../../../../../Components/jobModels/EditDetailsModal";
import { ErrorNotification, SuccessNotification } from "../../../../../notifications/notifications"

// Constants for the API URL
const url = process.env.REACT_APP_BE_URL;

const JobDetails = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const [projectData, setProjectData] = useState(null);
  const [orgData, setOrgData] = useState(null);
  const [appliedJobs, setAppliedJobs] = useState(null);
  const [usercanEdit, setUsercanEdit] = useState(false);
  const [inAdminsArry, setinAdminsArry] = useState(false);
  const [jobData, setJobData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [jobDetailsStatusLoading, setJobDetailsStatusLoading] = useState(false);
  const [interestDisabled, setInterestDisabled] = useState(false);
  const [btnDisabled, setbtnDisabled] = useState(false);

  const [requirementsList, setRequirementsList] = useState([]);
  const [skillsList, setSkillsList] = useState([]);
  const [responsibilitiesList, setResponsibilitiesList] = useState([]);
  const [qualificationsList, setQualificationsList] = useState([]);

  // State for managing the modal visibility
  const [showEditDetailsModal, setShowEditDetailsModal] = useState(false);

  // Handler to show the EditDetailsModal
  const handleShowEditDetailsModal = () => setShowEditDetailsModal(true);

  // Handler to hide the EditDetailsModal
  const handleCloseEditDetailsModal = () => setShowEditDetailsModal(false);

  const { jobId } = useParams();

  const userId = JSON.parse(localStorage.getItem("userInfo"));

  const config = {
    headers: {
      Authorization: `Bearer ${userId.token}`,
    },
  };

  const getData = async () => {
    try {
      const { data } = await axiosInstance.get(`${url}/job/${jobId}`, config);

      setJobData(data);
      setProjectData(data?.projectID);
      setOrgData(data?.organizationID);
      setQualificationsList(data.qualifications);
      setRequirementsList(data.requirements);
      setResponsibilitiesList(data.responsibilities);
      setSkillsList(data.skills);
    } catch (error) {
      ErrorNotification(error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed");
    } finally {
      setLoading(false); // Set loading to false after fetching data
    }
  };

  // Fetch applied jobs for the user
  const getJob = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };

    try {
      const res = await axiosInstance.get(
        `${url}/application/${userId._id}`,
        config
      );

      if (res.status == 200) {
        setAppliedJobs(res);
      }
    } catch (error) {
      ErrorNotification(error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed");
    }
  };

  // Handle submit to toggle job status
  const handleSubmit = async () => {
    setJobDetailsStatusLoading(true);
    try {
      const payload = {
        status: jobData?.status === "inactive" ? "active" : "inactive",
      };
      const res = await axiosInstance.put(
        `${url}/job/${jobId}`,
        payload,
        config
      );
      if (res.status === 200) {
        getData();
        SuccessNotification(res.data?.message ? res.data?.message : res.data ? res.data : "Job Details updated!");
      }
    } catch (error) {
      ErrorNotification(error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed");
    }
    setJobDetailsStatusLoading(false);
  };

  //Delete Button Functions for Required Skills
  const deleteRequiredskills = async (item) => {
    let newSkills = jobData.skills.filter((skill) => skill !== item);

    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };

    try {
      let skillsPayLoad = {
        skills: newSkills,
      };
      const res = await axiosInstance.put(
        `${url}/job/${jobId}`,
        skillsPayLoad,
        config
      );

      if (res.status === 200) {
        getData();
        SuccessNotification(res.data?.message ? res.data?.message : res.data ? res.data : "Deleted successfully");
      }
    } catch (error) {
      ErrorNotification(error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed");
    }
  };

  //Delete Button Functions for job requirements
  const deleteJobRequirements = async (requirements) => {
    let newJobrequirements = jobData.requirements.filter(
      (requirement) => requirement !== requirements
    );
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };

    try {
      let jobRequirementspayLoad = {
        requirements: newJobrequirements,
      };
      const res = await axiosInstance.put(
        `${url}/job/${jobId}`,
        jobRequirementspayLoad,
        config
      );

      if (res.status === 200) {
        getData();
        SuccessNotification(res.data?.message ? res.data?.message : res.data ? res.data : "Deleted successfully");
      }
    } catch (error) {
      ErrorNotification(error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed");
    }
  };

  // Delete Button Functions for job responsibilities
  const deleteJobResponsibilities = async (responsibilities) => {
    let newJobresponsibilities = jobData.responsibilities.filter(
      (responsibility) => responsibility !== responsibilities
    );
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };

    try {
      let jobResponsibilitiespayLoad = {
        responsibilities: newJobresponsibilities,
      };
      const res = await axiosInstance.put(
        `${url}/job/${jobId}`,
        jobResponsibilitiespayLoad,
        config
      );

      if (res.status === 200) {
        getData();
        SuccessNotification(res.data?.message ? res.data?.message : res.data ? res.data : "Deleted successfully");
      }
    } catch (error) {
      ErrorNotification(error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed");
    }
  };

  // Delete Button Functions for qualifications
  const deleteJobQualifications = async (qualifications) => {
    let newJobqualifications = jobData.qualifications.filter(
      (qualifi) => qualifi !== qualifications
    );
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };

    try {
      let jobQualificationspayLoad = {
        qualifications: newJobqualifications,
      };
      const res = await axiosInstance.put(
        `${url}/job/${jobId}`,
        jobQualificationspayLoad,
        config
      );

      if (res.status === 200) {
        getData();
        SuccessNotification(res.data?.message ? res.data?.message : res.data ? res.data : "Deleted successfully");
      }
    } catch (error) {
      ErrorNotification(error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed");
    }
  };

  // Handle application submission
  const putJob = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };

    setbtnDisabled(true);

    let applicationPayload = {
      userID: `${userId._id}`,
      jobID: `${jobId}`,
    };

    try {
      const { status, data } = await axiosInstance.post(
        `${url}/application`,
        applicationPayload,
        config
      );
      if (status === 200) {
        setbtnDisabled(false);
        getJob();
        SuccessNotification("Successfully applied!");
      }
    } catch (error) {
      setbtnDisabled(false);
      ErrorNotification(error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed");
    }
  };

  const findAlradyIn = () => {
    if (appliedJobs.data.find((applications) => applications.jobID === jobId)) {
      setInterestDisabled(true);
    } else {
      setInterestDisabled(false);
    }
  };

  useEffect(() => {
    getData();
    getJob();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (projectData || orgData) {
      checkUserData();
    }
    // eslint-disable-next-line
  }, [projectData, orgData]);

  useEffect(() => {
    if (appliedJobs) {
      findAlradyIn();
    }
    // eslint-disable-next-line
  }, [appliedJobs]);

  // Check user data to determine editing permissions
  const checkUserData = () => {
    let canEdit = false;
    let isAdmin = false;

    if (projectData) {
      if (projectData?.admins?.find((admin) => admin === userId._id)) {
        canEdit = true;
        isAdmin = true;
      }
    }

    if (orgData) {
      if (orgData?.admins?.find((admin) => admin === userId._id)) {
        canEdit = true;
        isAdmin = true;
      }
    }

    setUsercanEdit(canEdit);
    setinAdminsArry(isAdmin);
  };

  useEffect(() => {
    getData();
    getJob();
  }, [jobId]);

  console.log(jobData);

  const [showJobRequirementsModal, setShowJobRequirementsModal] =
    useState(false);

  const handleShowJobRequirementsModal = () =>
    setShowJobRequirementsModal(true);
  const handleCloseJobRequirementsModal = () =>
    setShowJobRequirementsModal(false);

  const [showRequiredSkillsModal, setShowRequiredSkillsModal] = useState(false);

  const handleShowRequiredSkillsModal = () => setShowRequiredSkillsModal(true);
  const handleCloseRequiredSkillsModal = () =>
    setShowRequiredSkillsModal(false);

  const [showJobResponsibilitiesModal, setShowJobResponsibilitiesModal] =
    useState(false);

  const handleShowJobResponsibilitiesModal = () =>
    setShowJobResponsibilitiesModal(true);
  const handleCloseJobResponsibilitiesModal = () =>
    setShowJobResponsibilitiesModal(false);

  const [showQualificationsModal, setShowQualificationsModal] = useState(false);

  const handleShowQualificationsModal = () => setShowQualificationsModal(true);
  const handleCloseQualificationsModal = () =>
    setShowQualificationsModal(false);

  return (
    <>
      <section className="position-relative overflow-hidden bg-gradient2 py-3 px-3">
        <Container className="min-vh-100 bg-light p-4 mt-5 mt-lg-0">
          {loading ? (
            <FullScreenSpinner />
          ) : (
            <>
              <Row className="mb-4 justify-content-between align-items-center">
                <Col xs="auto">
                  <div className="d-flex justify-center">
                    <span className="h2">{jobData.title}</span>
                  </div>
                </Col>
                <Col xs="auto" className="d-flex gap-2 flex-row">
                  {inAdminsArry && (
                    <Button
                      className={`${jobData?.status === "inactive" ? "btn-soft-warning" : "btn-soft-success"}`}
                    >
                      <FeatherIcon icon={jobData?.status === "inactive" ? "clock" : "check"} />
                      <span className="d-none d-md-inline  ms-2">
                        {jobData?.status === "inactive" ? "Inactive" : "Active"}
                      </span>
                    </Button>
                  )}

                  {!inAdminsArry && !interestDisabled && (
                    <Button className="btn-soft-primary" onClick={putJob}>
                      <FeatherIcon icon="eye" />
                      <span className="d-none d-md-inline  ms-2">I'm interested</span>
                    </Button>
                  )}

                  {!inAdminsArry && interestDisabled && (
                    <Button className="btn-soft-success">
                      <FeatherIcon icon="check" />
                      <span className="d-none d-md-inline  ms-2">Already applied</span>
                    </Button>
                  )}

                  {usercanEdit && (
                    <Link to={`/jobapplicantlist/${jobId}`}>
                      <Button className="btn-soft-info ">
                        <FeatherIcon icon="users" />
                        <span className="d-none d-md-inline  ms-2">View Applicant List</span>
                      </Button>
                    </Link>
                  )}

                  <Link
                    to={
                      jobData?.organizationID
                        ? `/organizationdetails/${jobData.organizationID._id}`
                        : `/projectdetails/${jobData?.projectID?._id}`
                    }
                  >
                    <Button className="btn-soft-secondary ">
                      <FeatherIcon icon="info" />
                      <span className="d-none d-md-inline ms-2">
                        {orgData ? "Organization Details" : "Project Details"}
                      </span>
                    </Button>
                  </Link>
                </Col>

              </Row>

              <Row>
                <Col xs="auto">
                  {jobData && (
                    <>
                      <p className="mb-3">{jobData?.description}</p>
                      <div className="mb-2">
                        <strong>Job Type:</strong> {jobData.jobType}
                      </div>
                      <div className="mb-2">
                        <strong>Time Availability:</strong>{" "}
                        {jobData.timeAvailability}
                      </div>
                      <div className="mb-2">
                        <strong>
                          {projectData ? "Project:" : "Organization:"}
                        </strong>{" "}
                        {projectData ? projectData.name : orgData.name}
                      </div>
                      <div>
                        <strong>
                          {projectData ? "Industry:" : "Organization Industry:"}
                        </strong>{" "}
                        {projectData ? projectData.industry : orgData.industry}
                      </div>
                    </>
                  )}
                </Col>
              </Row>
              {usercanEdit && (
                <Row className="mt-4">
                  <Col xs="auto">
                    {jobData?.status === "active" ? (
                      <Button
                        className="btn-soft-orange me-2"
                        onClick={() => {
                          handleSubmit();
                        }}
                        disabled={jobDetailsStatusLoading} // Disable button while loading
                      >
                        {jobDetailsStatusLoading ? (
                          <CircularProgress
                            size={20}
                            style={{ color: "white" }}
                          />
                        ) : (
                          <>
                            <FeatherIcon icon="x" />
                            <span className="d-none d-md-inline  ms-2">Make Inactive</span>

                          </>
                        )}
                      </Button>
                    ) : (
                      <Button
                        className="btn-soft-success me-2"
                        onClick={() => {
                          handleSubmit();
                        }}
                        disabled={jobDetailsStatusLoading} // Disable button while loading
                      >
                        {jobDetailsStatusLoading ? (
                          <CircularProgress
                            size={20}
                            style={{ color: "white" }}
                          />
                        ) : (
                          <>
                            <FeatherIcon icon="check" />
                            <span className="d-none d-md-inline  ms-2">Make Active</span>
                          </>
                        )}
                      </Button>
                    )}

                    <Button
                      className="btn-soft-warning"
                      onClick={handleShowEditDetailsModal}
                    >
                      <FeatherIcon icon="edit-3" />
                      <span className="d-none d-md-inline ms-2">Edit Details</span>
                    </Button>
                  </Col>
                </Row>
              )}

              <Row className="mt-5">
                {/* Required Skills Section */}
                <Col lg={6} className="mb-4">
                  <Card>
                    <Card.Body>
                      <Card.Title className="d-flex justify-content-between align-items-center pb-2">
                        Required Skills
                        {usercanEdit && (
                          <Button
                            variant="outline-primary"
                            size="sm"
                            className="ms-2 p-1"
                            onClick={handleShowRequiredSkillsModal}
                          >
                            <FeatherIcon icon="plus" className="icon-xs" />
                          </Button>
                        )}
                      </Card.Title>
                      {jobData.skills && jobData.skills.length > 0 ? (
                        <ListGroup>
                          {jobData.skills.map((skill, index) => (
                            <ListGroup.Item key={index}>
                              <div className="d-flex justify-content-between align-items-center">
                                {skill}
                                {usercanEdit && (
                                  <Button
                                    variant="outline-danger"
                                    size="sm"
                                    className="p-1"
                                    onClick={() => deleteRequiredskills(skill)}
                                  >
                                    <FeatherIcon
                                      icon="trash-2"
                                      className="icon-xs"
                                    />
                                  </Button>
                                )}
                              </div>
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      ) : (
                        <div className="text-center">
                          <p>No required skills found.</p>
                          {!usercanEdit && (
                            <Button
                              variant="primary"
                              onClick={handleShowRequiredSkillsModal}
                            >
                              Add New
                            </Button>
                          )}
                        </div>
                      )}
                    </Card.Body>
                  </Card>
                </Col>

                {/* Job Requirements Section */}
                <Col lg={6} className="mb-4">
                  <Card>
                    <Card.Body>
                      <Card.Title className="d-flex justify-content-between align-items-center pb-2">
                        Job Requirements
                        {usercanEdit && (
                          <Button
                            variant="outline-primary"
                            size="sm"
                            className="ms-2 p-1"
                            onClick={handleShowJobRequirementsModal}
                          >
                            <FeatherIcon icon="plus" className="icon-xs" />
                          </Button>
                        )}
                      </Card.Title>
                      {jobData.requirements &&
                        jobData.requirements.length > 0 ? (
                        <ListGroup>
                          {jobData.requirements.map((requirement, index) => (
                            <ListGroup.Item key={index}>
                              <div className="d-flex justify-content-between align-items-center">
                                {requirement}
                                {usercanEdit && (
                                  <Button
                                    variant="outline-danger"
                                    size="sm"
                                    className="p-1"
                                    onClick={() =>
                                      deleteJobRequirements(requirement)
                                    }
                                  >
                                    <FeatherIcon
                                      icon="trash-2"
                                      className="icon-xs"
                                    />
                                  </Button>
                                )}
                              </div>
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      ) : (
                        <div className="text-center">
                          <p>No job requirements found.</p>
                          {usercanEdit && (
                            <Button
                              variant="primary"
                              onClick={handleShowJobRequirementsModal}
                            >
                              Add New
                            </Button>
                          )}
                        </div>
                      )}
                    </Card.Body>
                  </Card>
                </Col>

                {/* Job Responsibilities Section */}
                <Col lg={6} className="mb-4">
                  <Card>
                    <Card.Body>
                      <Card.Title className="d-flex justify-content-between align-items-center pb-2">
                        Job Responsibilities
                        {usercanEdit && (
                          <Button
                            variant="outline-primary"
                            size="sm"
                            className="ms-2 p-1"
                            onClick={handleShowJobResponsibilitiesModal}
                          >
                            <FeatherIcon icon="plus" className="icon-xs" />
                          </Button>
                        )}
                      </Card.Title>
                      {jobData.responsibilities &&
                        jobData.responsibilities.length > 0 ? (
                        <ListGroup>
                          {jobData.responsibilities.map(
                            (responsibility, index) => (
                              <ListGroup.Item key={index}>
                                <div className="d-flex justify-content-between align-items-center">
                                  {responsibility}
                                  {usercanEdit && (
                                    <Button
                                      variant="outline-danger"
                                      size="sm"
                                      className="p-1"
                                      onClick={() =>
                                        deleteJobResponsibilities(
                                          responsibility
                                        )
                                      }
                                    >
                                      <FeatherIcon
                                        icon="trash-2"
                                        className="icon-xs"
                                      />
                                    </Button>
                                  )}
                                </div>
                              </ListGroup.Item>
                            )
                          )}
                        </ListGroup>
                      ) : (
                        <div className="text-center">
                          <p>No job responsibilities found.</p>
                          {usercanEdit && (
                            <Button
                              variant="primary"
                              onClick={handleShowJobResponsibilitiesModal}
                            >
                              Add New
                            </Button>
                          )}
                        </div>
                      )}
                    </Card.Body>
                  </Card>
                </Col>

                {/* Qualifications Section */}
                <Col lg={6} className="mb-4">
                  <Card>
                    <Card.Body>
                      <Card.Title className="d-flex justify-content-between align-items-center pb-2">
                        Qualifications
                        {usercanEdit && (
                          <Button
                            variant="outline-primary"
                            size="sm"
                            className="ms-2 p-1"
                            onClick={handleShowQualificationsModal}
                          >
                            <FeatherIcon icon="plus" className="icon-xs" />
                          </Button>
                        )}
                      </Card.Title>
                      {jobData.qualifications &&
                        jobData.qualifications.length > 0 ? (
                        <ListGroup>
                          {jobData.qualifications.map(
                            (qualification, index) => (
                              <ListGroup.Item key={index}>
                                <div className="d-flex justify-content-between align-items-center">
                                  {qualification}
                                  {usercanEdit && (
                                    <Button
                                      variant="outline-danger"
                                      size="sm"
                                      className="p-1"
                                      onClick={() =>
                                        deleteJobQualifications(qualification)
                                      }
                                    >
                                      <FeatherIcon
                                        icon="trash-2"
                                        className="icon-xs"
                                      />
                                    </Button>
                                  )}
                                </div>
                              </ListGroup.Item>
                            )
                          )}
                        </ListGroup>
                      ) : (
                        <div className="text-center">
                          <p>No qualifications found.</p>
                          {usercanEdit && (
                            <Button
                              variant="primary"
                              onClick={handleShowQualificationsModal}
                            >
                              Add New
                            </Button>
                          )}
                        </div>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </Container>
      </section>

      <JobRequirementsModal
        show={showJobRequirementsModal}
        handleClose={handleCloseJobRequirementsModal}
        jobId={jobId}
        getData={getData}
        requirements={requirementsList}
      />
      <RequiredSkillsModal
        show={showRequiredSkillsModal}
        handleClose={handleCloseRequiredSkillsModal}
        jobId={jobId}
        getData={getData}
        skills={skillsList}
      />
      <JobResponsibilitiesModal
        show={showJobResponsibilitiesModal}
        handleClose={handleCloseJobResponsibilitiesModal}
        jobId={jobId}
        getData={getData}
        responsibilities={responsibilitiesList}
      />
      <QualificationsModal
        show={showQualificationsModal}
        handleClose={handleCloseQualificationsModal}
        jobId={jobId}
        getData={getData}
        qualifications={qualificationsList}
      />

      <EditDetailsModal
        show={showEditDetailsModal}
        handleClose={handleCloseEditDetailsModal}
        jobData={jobData}
        getData={getData}
        jobId={jobId}
        setJobData={setJobData}
      />
    </>
  );
};

export default JobDetails;
