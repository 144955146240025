import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "../../axios";
import { ErrorNotification, SuccessNotification } from "../../notifications/notifications";

const InviteModal = ({ show, handleClose }) => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [submissionLoading, setSubmissionLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmissionLoading(true);
    // Basic validation
    if (!email) {
      setEmailError("Email is required");
      return;
    }

    // Regular expression for validating email
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setEmailError("Invalid email address");
      return;
    }

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token}`,
        },
      };

      const res = await axios.post(`${process.env.REACT_APP_BE_URL}/invite`, { email }, config);

      if (res.status === 200) {
        SuccessNotification("Invitation sent successfully!");
        handleClose(); // Close the modal after submission

      }
    } catch (error) {
      ErrorNotification(error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed");

    } finally {
      setSubmissionLoading(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered // Ensures the modal is centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Invite Users</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formEmail">
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              isInvalid={!!emailError}
            />
            <Form.Control.Feedback type="invalid">
              {emailError}
            </Form.Control.Feedback>
          </Form.Group>
          <Button variant="primary" type="submit" className="mt-3" disabled={submissionLoading}>
            {submissionLoading ? (
              <span className="spinner-border spinner-border-sm" />
            ) : (
              "Send Invitation"
            )}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default InviteModal;
