import React, { useState } from "react";
import { Dropdown, Nav } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { useDispatch } from "react-redux";
import { logout as logoutAction } from "../../../store/actions/authActions";

// images
import user from "../../../assets/defaultProfile.jpg";
import { Link, useNavigate } from "react-router-dom";
import InviteModal from "../../accountModels/InviteModal";
import axios from "../../../axios";

const url = process.env.REACT_APP_BE_URL;

const ProfileDropdown = ({ profileOptions }) => {
  const [showInviteModal, setShowInviteModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCloseInviteModal = () => setShowInviteModal(false);

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const handleLogout = async () => {
    try {
      const res = await axios.put(`${url}/user/logout/`, { id: userInfo._id });
      if (res.status === 200) {
        dispatch(logoutAction(userInfo._id)); // Dispatch the logout action
        navigate("/");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Dropdown as="li" className="nav-item">
        <Dropdown.Toggle as={Nav.Link} id="user">
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <img
                src={userInfo.profilePic.url ? userInfo.profilePic.url : user}
                alt="user"
                className="avatar avatar-xs rounded-circle me-2 border"
              />
            </div>
            <div className="flex-grow-1 ms-1 lh-base">
              <span className="fw-semibold fs-13 d-block line-height-normal">
                {userInfo.firstName} {userInfo.lastName}
              </span>
              <span className="text-muted fs-13">User</span>
            </div>
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu className="p-2" renderOnMount>
          {(profileOptions || []).map((profile, index) => (
            <React.Fragment key={index.toString()}>
              {index === profileOptions.length - 1 && (
                <Dropdown.Divider as="div" />
              )}
              {profile.label === "Invite" ? (
                <Dropdown.Item
                  className="p-2"
                  onClick={() => setShowInviteModal(true)}
                >
                  <div className="d-flex align-items-center">
                    <FeatherIcon
                      icon={profile.icon}
                      className="icon icon-xxs me-1 icon-dual"
                    />
                    {profile.label}
                  </div>
                </Dropdown.Item>
              ) : profile.label === "Sign Out" ? (
                <Dropdown.Item className="p-2" onClick={handleLogout}>
                  <div className="d-flex align-items-center">
                    <FeatherIcon
                      icon={profile.icon}
                      className="icon icon-xxs me-1 icon-dual"
                    />
                    {profile.label}
                  </div>
                </Dropdown.Item>
              ) : (
                <Dropdown.Item className="p-2">
                  <Link
                    to={profile.redirectTo}
                    className="d-flex align-items-center"
                  >
                    <FeatherIcon
                      icon={profile.icon}
                      className="icon icon-xxs me-1 icon-dual"
                    />
                    {profile.label}
                  </Link>
                </Dropdown.Item>
              )}
            </React.Fragment>
          ))}
        </Dropdown.Menu>
      </Dropdown>

      {/* Invite Modal */}
      <InviteModal
        show={showInviteModal}
        handleClose={handleCloseInviteModal}
      />
    </>
  );
};

export default ProfileDropdown;
