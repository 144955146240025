import axios from "../../axios";
import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Image } from "react-bootstrap";
import { ErrorNotification, SuccessNotification } from "../../notifications/notifications";

const url = process.env.REACT_APP_BE_URL;

const EditOrganizationModal = ({
  show,
  handleClose,
  organization,
  orgId,
  fetchData,
}) => {
  // State to manage form fields
  const [orgName, setOrgName] = useState("");
  const [industry, setIndustry] = useState("");
  const [description, setDescription] = useState("");
  const [website, setWebsite] = useState("");
  const [logoPreview, setLogoPreview] = useState("");
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [submissionLoading, setSubmissionLoading] = useState(false);

  // State to manage validation errors
  const [errors, setErrors] = useState({});

  // Initialize state with the organization prop when modal opens
  useEffect(() => {
    if (organization) {
      setOrgName(organization.name || "");
      setIndustry(organization.industry || "");
      setDescription(organization.description || "");
      setWebsite(organization.website || "");
      setLogoPreview(organization.logo?.url || "");
    }
  }, [organization]);

  // Validate form fields
  const validate = () => {
    let errors = {};

    if (!orgName.trim()) {
      errors.orgName = "Organization name is required.";
    }

    if (!industry) {
      errors.industry = "Industry is required.";
    }

    if (!description.trim()) {
      errors.description = "Description is required.";
    }

    if (website && !/^(https?:\/\/)?[\w-]+(\.[\w-]+)+[/#?]?.*$/.test(website)) {
      errors.website = "Please enter a valid URL.";
    }

    if (selectedLogo && !selectedLogo.type.startsWith("image/")) {
      errors.logo = "Please select a valid image file.";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Handle image change
  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      if (file && !file.type.startsWith("image/")) {
        setErrors((prev) => ({ ...prev, logo: "Please select a valid image file." }));
        return;
      }

      const reader = new FileReader();

      reader.onloadend = () => {
        setSelectedLogo(file);
        setLogoPreview(reader.result);
        setErrors((prev) => ({ ...prev, logo: "" }));
      };

      reader.readAsDataURL(file);
    }
  };

  // Handle image removal
  const handleRemoveLogo = () => {
    setSelectedLogo(null);
    setLogoPreview("");
  };

  // Handle form submission
  const handleSubmit = async () => {
    setSubmissionLoading(true);
    if (!validate()) return;

    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token}`,
      },
    };

    try {
      let payload = {
        name: orgName,
        industry,
        description,
        website,
      };

      if (selectedLogo) {
        payload.logo = selectedLogo; // Add the logo to the payload if selected
      }

      const res = await axios.put(
        `${url}/organization/${organization._id}`,
        payload,
        config
      );

      if (res.status === 200) {
        SuccessNotification(res.data?.message ? res.data?.message : res.data ? res.data : "Organization Edited successfully!");

        fetchData(); // Refresh data if needed
        handleClose(); // Close the modal
      }
    } catch (error) {
      ErrorNotification(error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed");

    } finally {
      setSubmissionLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Edit Organization Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="orgName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              value={orgName}
              onChange={(e) => setOrgName(e.target.value)}
              isInvalid={!!errors.orgName}
              required
            />
            <Form.Control.Feedback type="invalid">
              {errors.orgName}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="orgIndustry">
            <Form.Label>Industry</Form.Label>
            <Form.Control
              as="select"
              value={industry}
              onChange={(e) => setIndustry(e.target.value)}
              isInvalid={!!errors.industry}
              required
            >
              <option value="">Select Industry</option>
              <option>Technology</option>
              <option>Finance</option>
              <option>Healthcare</option>
              {/* Add more industries as needed */}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.industry}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="orgDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              isInvalid={!!errors.description}
              required
            />
            <Form.Control.Feedback type="invalid">
              {errors.description}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="orgWebsite">
            <Form.Label>Website</Form.Label>
            <Form.Control
              type="url"
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
              isInvalid={!!errors.website}
            />
            <Form.Control.Feedback type="invalid">
              {errors.website}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="orgLogo">
            <Form.Label>Logo</Form.Label>
            <Form.Control
              type="file"
              onChange={handleImageChange}
              isInvalid={!!errors.logo}
            />
            <Form.Control.Feedback type="invalid">
              {errors.logo}
            </Form.Control.Feedback>
            {logoPreview && (
              <>
                <div className="mt-3">
                  <Image
                    src={logoPreview}
                    alt="Logo Preview"
                    thumbnail
                    style={{ maxWidth: "150px", maxHeight: "150px" }}
                  />
                </div>
                <div>
                  <Button
                    variant="danger"
                    onClick={handleRemoveLogo}
                    className="mt-2"
                  >
                    Remove Logo
                  </Button>
                </div>
              </>
            )}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          {submissionLoading ? (
            <span className="spinner-border spinner-border-sm" />
          ) : (
            "Save Changes"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditOrganizationModal;
