import React, { useState } from "react";
import { Button, Col, Row, Alert, Spinner } from "react-bootstrap";
import axios from "../../../axios"; // Make sure the axios instance is correctly imported

const ResetPassword = () => {
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const resetPassword = async () => {
    setResetPasswordLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
            }`,
        },
      };
      const { status } = await axios.get(`/user/resetUserPassword`, config);

      if (status === 200) {
        setAlert({
          showAlert: true,
          severity: "success",
          message:
            "Please check your mail inbox, Password reset link has been sent!",
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "success",
            message: "",
          });
        }, 16000);
      }
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${error.response?.data?.message
            ? error.response.data.message
            : "Action Failed"
          }`,
      });
      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);
    }
    setResetPasswordLoading(false);
  };

  return (
    <>
      <h4 className="mt-3 mt-lg-0">Reset Password</h4>
      <p className="mt-2">
        Click the button below to request a password reset. You will receive an
        email with instructions to reset your password.
      </p>

      {alert.showAlert && (
        <Alert
          variant={alert.severity === "success" ? "success" : "danger"}
          onClose={() => setAlert({ ...alert, showAlert: false })}
          dismissible
        >
          {alert.message}
        </Alert>
      )}

      <Row className="mt-4">
        <Col lg={12}>
          <Button onClick={resetPassword} disabled={resetPasswordLoading}>
            {resetPasswordLoading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Send Reset Link"
            )}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default ResetPassword;
