import { Col, Container, Row, Button } from "react-bootstrap";

// components
import SwiperSlider1 from "../../Components/swipers/SwiperSlider1";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <section className="position-relative overflow-hidden hero-13 pt-7 pt-lg-5 pb-6">
      <Container>
        <Row className="align-items-center text-center text-sm-start">
          <Col lg={6}>
            <div className="mb-lg-0 mb-5">
              <h1 className="hero-title">
                Your Hub for Trusted Startup &nbsp;
                <span className="highlight highlight-primary d-inline-block">
                  Resources
                </span>
                &nbsp; &
                &nbsp;
                <span className="highlight highlight-success d-inline-block">
                  Connections
                </span>
              </h1>
              <p className="fs-15 te</p>xt-muted pt-3">
                TINT is a community for startups to access and share trusted resources. 
                Discover and contribute valuable insights, processes, and technologies. 
                Need help? Let us know, and we'll connect you to the right resources.
              </p>

              <div className="pt-3">
                <Link to="/signup" className="text-decoration-none">
                  <Button className="btn-primary">Join Now</Button>
                </Link>
              </div>
            </div>
          </Col>
          <Col lg={{ offset: 1, span: 5 }} className="hero-right">
            <div
              className="img-container"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <div className="slider">
                <SwiperSlider1 />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Hero;
