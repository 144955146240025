import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { scroller } from "react-scroll";

const NavigationScrollLink = ({ to, children, ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    if (location.pathname !== "/") {
      // If not on the landing page, navigate to home first
      navigate("/", { state: { scrollTo: to } });
    } else {
      // If already on the landing page, use react-scroll to scroll
      scroller.scrollTo(to, {
        smooth: true,
        duration: 500,
      });
    }
  };

  return (
    <span {...props} onClick={handleClick} style={{ cursor: "pointer" }}>
      {children}
    </span>
  );
};

export default NavigationScrollLink;
