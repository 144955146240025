import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axiosInstance from "../../axios";
import { ErrorNotification, SuccessNotification } from "../../notifications/notifications";

const startUpList = [
  "Business to Business (B2B)",
  "Multi-sided (B2B&C)",
  "Peer to Peer (P2P) (Marketplace)",
  "Business to Business to Consumer (B2B2C)",
  "Consumer to Business (C2B)",
  "Business to Government (B2G)",
  "Business to Consumer (B2C)"
];

const AddStartupTypeModal = ({
  show,
  handleClose,
  userId,
  getData,
  startupTypesList,
}) => {
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [validated, setValidated] = useState(false);
  const [submissionLoading, setSubmissionLoading] = useState(false);

  useEffect(() => {
    if (startupTypesList) {
      setSelectedTypes(startupTypesList);
    }
  }, [startupTypesList]);

  const handleChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedTypes((prev) => [...prev, value]);
    } else {
      setSelectedTypes((prev) => prev.filter((type) => type !== value));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedTypes.length > 0) {
      setSubmissionLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
            }`,
        },
      };

      try {
        const payLoad = {
          startupTypes: selectedTypes,
        };

        const res = await axiosInstance.put(
          `${process.env.REACT_APP_BE_URL}/user/${userId}`,
          payLoad,
          config
        );

        if (res.status === 200) {
          SuccessNotification("Startup types updated successfully!");
          getData();
          handleClose();
        }
      } catch (error) {
        ErrorNotification(error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed");
      } finally {
        setSubmissionLoading(false);
      }
    } else {
      setValidated(true);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size="lg"
      backdrop="static"
      animation={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>Add New Startup Type</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit} className="py-2">
          <Form.Group controlId="formStartupType">
            <Form.Label>Select Startup Types</Form.Label>
            {startUpList.map((type, index) => (
              <Form.Check
                type="checkbox"
                key={type}
                id={`startupType-${index}`}
                label={type}
                value={type}
                checked={selectedTypes.includes(type)}
                onChange={handleChange}
              />
            ))}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          disabled={submissionLoading}
          onClick={handleSubmit}
        >
          {submissionLoading ? (
            <span className="spinner-border spinner-border-sm" />
          ) : (
            "Add Type"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddStartupTypeModal;
