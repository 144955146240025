import saas1 from "../../assets/images/hero/saas1.jpg";
import saas2 from "../../assets/images/hero/saas2.jpg";
import saas3 from "../../assets/images/hero/saas3.jpg";

const slides1 = [
  {
    image: saas1,
    slideTitle: "Empower Your Startup Journey",
    description:
      "Join TINT to access and share trusted resources that propel your startup forward. Connect with a community of like-minded innovators.",
  },
  {
    image: saas2,
    slideTitle: "Connect and Collaborate",
    description:
      "Engage with our trusted community to explore cutting-edge technologies, share knowledge, and foster mentorship for shared growth.",
  },
  {
    image: saas3,
    slideTitle: "Fuel Innovation with Trusted Resources",
    description:
      "Access a curated collection of tools, technologies, and insights to innovate and scale your startup efficiently.",
  },
];

export { slides1 };
