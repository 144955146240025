import React, { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import Joyride from "react-joyride";
import axios from "../../../axios";

const Menu = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const url = process.env.REACT_APP_BE_URL;

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const [runTour, setRunTour] = useState(false); // Start with false, trigger on mount

  // Define the steps for the tour
  const steps = [
    {
      target: '.nav-link[data-step="1"]', // Step 1: Dashboard
      content: "This is where your profile and affiliations are.",
      placement: 'right',
      disableBeacon: true,
    },
    {
      target: '.nav-link[data-step="2"]', // Step 2: Projects
      content: "You can create and manage your startup projects from here.",
      placement: 'right',
      disableBeacon: true,
    },
    {
      target: '.nav-link[data-step="3"]', // Step 3: Organizations
      content: "You can create and manage your organizations from here.",
      placement: 'right',
      disableBeacon: true,
    },
    {
      target: '.nav-link[data-step="4"]', // Step 4: Jobs
      content: "You can find and apply for jobs from here.",
      placement: 'right',
      disableBeacon: true,
    },
    {
      target: '.nav-link[data-step="5"]', // Step 5: Resources
      content: "You can find startup resources and add your own resources from here.",
      placement: 'right',
      disableBeacon: true,
    },
  ];

  const getActiveState = (path) => {
    switch (path) {
      case `/profile/${userInfo._id}`:
        return "dashboard";
      case "/projects":
        return "projects";
      case "/organizations":
        return "organizations";
      case "/jobsearch":
        return "job-search";
      case "/categories":
        return "resources";
      default:
        return "";
    }
  };

  const active = getActiveState(currentPath);

  // Callback function to handle Joyride events
  const handleTourCallback = async (data) => {
    const { action, status } = data;

    // Check if the tour was finished or skipped
    if (status === "finished" || status === "skipped") {
      setRunTour(false); // Stop the tour
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token}`,
          },
        };

        const userId = JSON.parse(localStorage.getItem("userInfo"))._id;

        // Send request to update productTour to false
        const { data: response, status } = await axios.put(
          `${url}/user/${userId}`,
          {
            productTour: false, // Updating productTour to false when the tour ends or is skipped
          },
          config
        );

        if (status === 200) {
          console.log("Product tour status updated successfully:", response);

          // Optionally, update local storage
          localStorage.setItem("productTour", JSON.stringify(false));
        }
      } catch (error) {
        console.error("Failed to update productTour status:", error);
      }
    }
  };

  useEffect(() => {
    const productTour = JSON.parse(localStorage.getItem("productTour"));
    if (productTour) {
      const timer = setTimeout(() => {
        setRunTour(true); // Start the tour if productTour is true
      }, 1000);
      return () => clearTimeout(timer); // Cleanup on unmount
    }
  }, []);

  return (
    <>
      <Joyride
        steps={steps}
        hideCloseButton
        continuous
        run={runTour}
        callback={handleTourCallback}
        styles={{
          options: {
            zIndex: 10000,
            backgroundColor: '#ffffff', // Tour background color
            overlayColor: 'rgba(0, 0, 0, 0.8)', // Overlay color for background
            arrowColor: '#007bff', // Arrow color
            primaryColor: '#007bff', // Primary color for buttons and highlights
            textColor: '#333', // Main text color
            spotlightPadding: 10, // Padding around highlighted target
            beaconSize: 30, // Size of the beacons
          },
          buttonNext: {
            backgroundColor: '#007bff',
            color: '#ffffff',
            borderRadius: '5px',
            padding: '10px 20px',
            fontWeight: 'bold',
            marginRight: '5px',
          },
          buttonBack: {
            backgroundColor: '#6c757d',
            color: '#ffffff',
            borderRadius: '5px',
            padding: '10px 20px',
            fontWeight: 'bold',
            marginRight: '5px',
          },
          buttonSkip: {
            backgroundColor: '#dc3545',
            color: '#ffffff',
            borderRadius: '5px',
            padding: '10px 20px',
            fontWeight: 'bold',
          },
          tooltip: {
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
            fontFamily: 'Arial, sans-serif',
            fontSize: '14px',
            lineHeight: '1.5',
          },
          tooltipContent: {
            padding: '15px',
          },
        }}
        locale={{
          back: 'Back',
          next: 'Next',
          last: 'End', // Customizes the last button to say "End"
          skip: 'Skip',
        }}
        disableCloseOnEsc={true} // Allow closing the tour with Escape
        showSkipButton={true} // Show skip button
        showBackButton={true} // Show back button
        showProgress
      />

      <Nav as="ul" className="mx-auto">
        <Nav.Item as="li" className="pe-2">
          <Link
            to={`/profile/${userInfo._id}`}
            className={`nav-link ${active === "dashboard" ? "active" : ""}`}
            data-step="1" // Add data-step attributes for targeting
          >
            <div className="d-flex align-items-center">
              <span className="icon-xxs me-1 flex-shrink-0 d-flex align-items-center">
                <FeatherIcon icon="grid" className="icon-dual-dark" />
              </span>
              <div className="flex-grow-1 d-flex align-items-center">Dashboard</div>
            </div>
          </Link>
        </Nav.Item>

        <Nav.Item as="li" className="pe-2">
          <Link
            to="/projects"
            className={`nav-link ${active === "projects" ? "active" : ""}`}
            data-step="2" // Add data-step attributes for targeting
          >
            <div className="d-flex align-items-center">
              <span className="icon-xxs me-1 flex-shrink-0 d-flex align-items-center">
                <FeatherIcon icon="folder" className="icon-dual-dark" />
              </span>
              <div className="flex-grow-1 d-flex align-items-center">Projects</div>
            </div>
          </Link>
        </Nav.Item>

        <Nav.Item as="li" className="pe-2">
          <Link
            to="/organizations"
            className={`nav-link ${active === "organizations" ? "active" : ""}`}
            data-step="3" // Add data-step attributes for targeting
          >
            <div className="d-flex align-items-center">
              <span className="icon-xxs me-1 flex-shrink-0 d-flex align-items-center">
                <FeatherIcon icon="briefcase" className="icon-dual-dark" />
              </span>
              <div className="flex-grow-1 d-flex align-items-center">Organizations</div>
            </div>
          </Link>
        </Nav.Item>

        <Nav.Item as="li" className="pe-2">
          <Link
            to="/jobsearch"
            className={`nav-link ${active === "job-search" ? "active" : ""}`}
            data-step="4" // Add data-step attributes for targeting
          >
            <div className="d-flex align-items-center">
              <span className="icon-xxs me-1 flex-shrink-0 d-flex align-items-center">
                <FeatherIcon icon="search" className="icon-dual-dark" />
              </span>
              <div className="flex-grow-1 d-flex align-items-center">Jobs</div>
            </div>
          </Link>
        </Nav.Item>

        <Nav.Item as="li" className="pe-2">
          <Link
            to="/categories"
            className={`nav-link ${active === "resources" ? "active" : ""}`}
            data-step="5" // Add data-step attributes for targeting
          >
            <div className="d-flex align-items-center">
              <span className="icon-xxs me-1 flex-shrink-0 d-flex align-items-center">
                <FeatherIcon icon="archive" className="icon-dual-dark" />
              </span>
              <div className="flex-grow-1 d-flex align-items-center">Resources</div>
            </div>
          </Link>
        </Nav.Item>
      </Nav>
    </>
  );
};

export default Menu;
