import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import NavigationScrollLink from "../navigationscrolllink/NavigationScrollLink";

// images
import logo from "../../assets/TINT.png";

const Footer = () => {
  return (
    <section className="pt-5 pb-4 bg-gradient3 position-relative">
      <Container>
        <Row className="gy-5 align-items-start">
          <Col lg={6} md={6} xs={12}>
            <NavigationScrollLink
              className="navbar-brand d-flex align-items-center mb-4"
              to="top"
              smooth={true}
              duration={500}
            >
              <img src={logo} height="40" alt="TINT Logo" />
            </NavigationScrollLink>
            <p className="text-muted">
              TINT fosters a thriving startup ecosystem through resource sharing
              and collaboration.
            </p>
          </Col>

          <Col lg={2} md={3} xs={6}>
            <h6 className="mb-3 fs-16 fw-semibold text-uppercase">
              Navigation
            </h6>
            <ul className="list-unstyled">
              <li className="my-2">
                <NavigationScrollLink
                  to="top"
                  className="text-muted"
                  smooth={true}
                  duration={500}
                >
                  Home
                </NavigationScrollLink>
              </li>
              <li className="my-2">
                <NavigationScrollLink
                  to="aboutus"
                  className="text-muted"
                  smooth={true}
                  duration={500}
                >
                  About Us
                </NavigationScrollLink>
              </li>
              <li className="my-2">
                <NavigationScrollLink
                  to="features"
                  className="text-muted"
                  smooth={true}
                  duration={500}
                >
                  Features
                </NavigationScrollLink>
              </li>
              <li className="my-2">
                <Link to="/contact" className="text-muted">
                  Contact
                </Link>
              </li>
            </ul>
          </Col>

          <Col lg={2} md={3} xs={6}>
            <h6 className="mb-3 fs-16 fw-semibold text-uppercase">
              Social Media
            </h6>
            <ul className="list-unstyled">
              <li className="my-2">
                <Link to="#" className="text-muted d-flex align-items-center">
                  <FeatherIcon icon="facebook" className="icon icon-xs me-2" />
                  Facebook
                </Link>
              </li>
              <li className="my-2">
                <Link to="#" className="text-muted d-flex align-items-center">
                  <FeatherIcon icon="twitter" className="icon icon-xs me-2" />
                  Twitter
                </Link>
              </li>
              <li className="my-2">
                <Link to="#" className="text-muted d-flex align-items-center">
                  <FeatherIcon icon="linkedin" className="icon icon-xs me-2" />
                  LinkedIn
                </Link>
              </li>
              <li className="my-2">
                <Link to="#" className="text-muted d-flex align-items-center">
                  <FeatherIcon icon="instagram" className="icon icon-xs me-2" />
                  Instagram
                </Link>
              </li>
            </ul>
          </Col>

          <Col lg={2} md={3} xs={12}>
            <h6 className="mb-3 fs-16 fw-semibold text-uppercase">Legal</h6>
            <ul className="list-unstyled">
              <li className="my-2">
                <Link to="/privacy-policy" className="text-muted">
                  Privacy Policy
                </Link>
              </li>
              <li className="my-2">
                <Link to="/terms-and-conditions" className="text-muted">
                  Terms and Conditions
                </Link>
              </li>
            </ul>
          </Col>
        </Row>

        <hr className="my-4" />

        <Row className="align-items-center text-center text-md-start">
          <Col md={6} xs={12} className="mb-3 mb-md-0">
            <p className="text-muted mb-0">
              &copy; {new Date().getFullYear()} TINT. All rights reserved.
            </p>
          </Col>

          <Col
            md={6}
            xs={12}
            className="d-flex justify-content-center justify-content-md-end"
          >
            <ul className="list-unstyled d-flex mb-0">
              <li className="me-3">
                <Link to="#" className="text-muted">
                  <FeatherIcon icon="facebook" className="icon icon-xs" />
                </Link>
              </li>
              <li className="me-3">
                <Link to="#" className="text-muted">
                  <FeatherIcon icon="twitter" className="icon icon-xs" />
                </Link>
              </li>
              <li className="me-3">
                <Link to="#" className="text-muted">
                  <FeatherIcon icon="linkedin" className="icon icon-xs" />
                </Link>
              </li>
              <li>
                <Link to="#" className="text-muted">
                  <FeatherIcon icon="instagram" className="icon icon-xs" />
                </Link>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Footer;
