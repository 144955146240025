import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { login } from "../../store/actions/authActions";
import axios from "../../axios";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";

// components
import FormInput from "../../Components/form/FormInput";
import VerticalForm from "../../Components/form/VerticalForm";
import AuthLayout from "./AuthLayout";

// notifications
import {
  SuccessNotification,
  ErrorNotification,
} from "../../notifications/notifications";

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [callBack, setCallBack] = useState(false);

  /*
  form validation schema
  */
  const schemaResolver = yupResolver(
    yup.object().shape({
      email: yup
        .string()
        .required(t("Please enter Email"))
        .email(t("Please enter valid Email")),
      password: yup.string().required(t("Please enter Password")),
    })
  );

  const url = process.env.REACT_APP_BE_URL;

  /*
  handle form submission
  */
  const onSubmit = async (formData) => {
    try {
      setCallBack(true);
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      // eslint-disable-next-line
      const { data, status } = await axios.post(
        `${url}/user/login`,
        { email: formData.email, password: formData.password },
        config
      );

      if (data.productTour) {
        // Store productTour in local storage or state
        localStorage.setItem("productTour", JSON.stringify(data.productTour));
      }

      const { _id } = data;

      // Dispatch the login action
      dispatch(login(JSON.stringify(data), _id));
      navigate(`/profile/${_id}`);
      setCallBack(false);

    } catch (error) {
      setCallBack(false);

      // Show error notification
      ErrorNotification(
        `${error.response.data.message
          ? error.response.data.message
          : error.response.data
            ? error.response.data
            : "Invalid credentials"
        }`
      );
    }
  };

  return (
    <>
      <AuthLayout
        hasSlider
        bottomLinks={
          <p className="text-muted">
            {t("Don't have an account?")}
            <Link to="/signup" className="text-primary fw-semibold ms-1">
              {t("Sign Up")}
            </Link>
          </p>
        }
      >
        <h6 className="h5 mb-0 mt-3">{t("Welcome back!")}</h6>
        <p className="text-muted mt-1 mb-4">
          {t("Enter your email address and password to access TINT Dashboard.")}
        </p>

        <VerticalForm onSubmit={onSubmit} resolver={schemaResolver}>
          <FormInput
            type="email"
            name="email"
            label={t("Email")}
            placeholder={t("Email")}
            containerClass={"mb-3"}
          />

          <FormInput
            label={t("Password")}
            type="password"
            name="password"
            placeholder={t("Password")}
            action={
              <Link
                to="/resetpassword"
                className="float-end text-muted text-unline-dashed ms-1 fs-13"
              >
                {t("Forgot your password?")}
              </Link>
            }
            containerClass={"mb-5"}
          />

          <div className="mb-0 text-center d-grid">
            <Button type="submit">
              {callBack ? (
                <CircularProgress size={20} sx={{ color: "white" }} />
              ) : (
                t("Log In")
              )}
            </Button>
          </div>
        </VerticalForm>
      </AuthLayout>
    </>
  );
};

export default Login;
