import { Link } from "react-router-dom";
import { Badge, Col, Container, Row } from "react-bootstrap";

const Footer2 = () => {
  return (
    <section className="section py-4 position-relative">
      <Container>
        <Row className="align-items-center text-center text-lg-start">
          <Col>
            <ul className="list-inline list-with-separator mb-0">
              <li className="list-inline-item me-0">
                <Link to="/">Home</Link>
              </li>
              <li className="list-inline-item me-0">
                <Link to="/privacy-policy">Privacy</Link>
              </li>
              <li className="list-inline-item me-0">
                <Link to="/terms-and-conditions">Terms</Link>
              </li>
            </ul>
          </Col>
          <Col lg="auto" className="mt-2 mt-lg-0">
            <p className="fs-14 mb-0">
              {new Date().getFullYear()} © TINT. All rights reserved. Crafted by{" "}
              <a href="https://coderthemes.com/">Tecsota</a>
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Footer2;
