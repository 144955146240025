import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axiosInstance from "../../axios";
import { ErrorNotification, SuccessNotification } from "../../notifications/notifications";

const predefinedSkills = [
  "HTML",
  "CSS",
  "Javascript",
  "Typescript",
  "NodeJS",
  "MongoDB",
  "MySQL",
];

const RequiredSkillsModal = ({
  show,
  handleClose,
  jobId,
  getData,
  skills,
}) => {
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [newSkill, setNewSkill] = useState(""); // State for new skill input
  const [allSkills, setAllSkills] = useState([...predefinedSkills]); // Combined list of predefined and new skills
  const [validated, setValidated] = useState(false);
  const [submissionLoading, setSubmissionLoading] = useState(false);

  // Initialize selected skills and merge with predefined skills
  useEffect(() => {
    if (skills) {
      const uniqueSkills = [...new Set([...predefinedSkills, ...skills])]; // Merge and remove duplicates
      setAllSkills(uniqueSkills);
      setSelectedSkills(skills);
    }
  }, [skills]);

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedSkills((prev) => [...prev, value]);
    } else {
      setSelectedSkills((prev) => prev.filter((skill) => skill !== value));
    }
  };

  const handleNewSkillChange = (e) => {
    setNewSkill(e.target.value);
  };

  const handleAddNewSkill = () => {
    if (newSkill && !allSkills.includes(newSkill)) {
      setAllSkills((prev) => [...prev, newSkill]);
      setSelectedSkills((prev) => [...prev, newSkill]);
      setNewSkill(""); // Clear the input after adding
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission
      handleAddNewSkill(); // Add new skill
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedSkills.length > 0) {
      setSubmissionLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token}`,
        },
      };

      try {
        const payLoad = { skills: selectedSkills };
        const res = await axiosInstance.put(
          `${process.env.REACT_APP_BE_URL}/job/${jobId}`,
          payLoad,
          config
        );

        if (res.status === 200) {
          SuccessNotification(res.data?.message || "Skills updated successfully!");
          getData();
          handleClose();
        }
      } catch (error) {
        ErrorNotification(error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed");

      } finally {
        setSubmissionLoading(false);
      }
    } else {
      setValidated(true);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size="lg"
      backdrop="static"
      animation={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Required Skills</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group controlId="formRequiredSkills">
            <Form.Label>Select Required Skills</Form.Label>
            {allSkills.map((skill, index) => (
              <Form.Check
                type="checkbox"
                key={skill}
                id={`skill-${index}`}
                value={skill}
                label={skill}
                onChange={handleCheckboxChange}
                checked={selectedSkills.includes(skill)}
              />
            ))}
            <Form.Label>Add New Skill</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter new skill"
              value={newSkill}
              onChange={handleNewSkillChange}
              onKeyPress={handleKeyPress} // Capture Enter keypress here
            />
            <Button
              variant="primary"
              className="mt-2"
              size="sm"
              onClick={handleAddNewSkill}
            >
              Add New Skill
            </Button>
          </Form.Group>
          {validated && selectedSkills.length === 0 && (
            <div className="text-danger">Please select at least one skill.</div>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={handleSubmit}
          disabled={submissionLoading}
        >
          {submissionLoading ? (
            <span className="spinner-border spinner-border-sm" />
          ) : (
            "Add Skills"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RequiredSkillsModal;
