import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Card, Spinner, Alert, ListGroup, Badge } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import axios from '../../../../../axios';
import FeatherIcon from "feather-icons-react";
import { ErrorNotification, SuccessNotification } from "../../../../../notifications/notifications"

const url = process.env.REACT_APP_BE_URL;

const JobApplicantList = () => {
  const navigate = useNavigate();
  const { jobId } = useParams();
  const [applicantList, setApplicantList] = useState([]);
  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(true);
  const [statusLoading, setStatusLoading] = useState(false);
  const [selectedApplicant, setSelectedApplicant] = useState(null);

  const loggedInUserId = JSON.parse(localStorage.getItem("userInfo"))._id;

  const getApplicantList = async () => {
    const config = {
      headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token}` },
    };
    try {
      const { data } = await axios.get(`${url}/application/job/${jobId}`, config);
      setApplicantList(data);
    } catch (error) {
      ErrorNotification(error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed");
    } finally {
      setLoading(false);
    }
  };

  const getJob = async () => {
    const config = {
      headers: { Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token}` },
    };
    try {
      const { data } = await axios.get(`${url}/job/${jobId}`, config);
      setJob(data);
    } catch (error) {
      ErrorNotification(error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed");
    }
  };

  useEffect(() => {
    getJob();
  }, [jobId]);

  useEffect(() => {
    if (job?.projectID || job?.organizationID) {
      getApplicantList();
    }
  }, [job]);

  const changeStatus = async (status) => {
    if (selectedApplicant === null) return; // Ensure an applicant is selected

    setStatusLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token}`,
      },
    };

    try {
      let endpoint;
      let payload;

      if (status === "accepted") {
        payload = {
          applicationID: applicantList[selectedApplicant]._id
        };
        endpoint = `${url}/application/mail/${applicantList[selectedApplicant].userID}`;
      } else if (status === "completed") {
        endpoint = `${url}/application/complete/${applicantList[selectedApplicant]._id}`;
        payload = { status: status };
      } else {
        endpoint = `${url}/application/mail/${applicantList[selectedApplicant].userID}`;
      }

      const { data, status: responseStatus } = await axios.put(endpoint, payload, config);

      if (responseStatus === 200) {
        SuccessNotification("Status Changed successfully");
        getApplicantList();
        getJob();
      }
    } catch (error) {
      ErrorNotification(error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed");
    } finally {
      setStatusLoading(false);
    }
  };

  const handleContactClick = (email) => {
    window.location.href = `mailto:${email}`;
  };

  const getBadgeColor = (status) => {
    switch (status) {
      case "pending":
        return "warning";
      case "mailSent":
        return "info";
      case "rejected":
        return "danger";
      case "accepted":
        return "success";
      case "completed":
        return "dark";
      default:
        return "secondary";
    }
  };

  return (
    <section className="position-relative overflow-hidden bg-gradient2 py-3 px-3">
      <Container className="min-vh-100 bg-light p-4 mt-5 mt-lg-0">
        <Row className="mb-4 justify-content-between align-items-center">
          <Col xs="auto">
            <h2>Job Applicant List</h2>
          </Col>
          <Col xs="auto">
            <Button
              className="btn-soft-primary me-2"
              onClick={() => navigate(`/jobDetails/${jobId}`)}
            >
              <FeatherIcon icon="arrow-left" className="me-2" />
              Back to Job Details
            </Button>
          </Col>
        </Row>
        <Row className="flex-grow-1">
          <Col>
            {loading ? (
              <div className="text-center">
                <Spinner animation="border" />
              </div>
            ) : (
              <>
                <h4 className="text-primary fw-bold mb-2">
                  {job?.projectID ? `Project: ${job.projectID.name}` : `Organization: ${job.organizationID.name}`}
                </h4>
                <h4 className="text-secondary fw-normal mb-4">
                  Job: {job?.title}
                </h4>

                {applicantList.length > 0 ? (
                  <ListGroup>
                    {applicantList.map((applicant, index) => (
                      <Card key={applicant.userID} className="mb-3 shadow-sm border-light rounded">
                        <Card.Body>
                          <Card.Title className="text-primary">{applicant.username}</Card.Title>
                          <Badge
                            bg={getBadgeColor(applicant.status)}
                            className="mb-3"
                          >
                            Status: {applicant.status}
                          </Badge>
                          <div className="d-flex flex-wrap gap-2">
                            <Button
                              variant="outline-primary"
                              onClick={() => handleContactClick(applicant.email)}
                              disabled={statusLoading}
                            >
                              Contact
                            </Button>
                            <Button
                              variant="outline-secondary"
                              onClick={() => navigate(`/profile/${applicant.userID}`)}
                              disabled={statusLoading}
                            >
                              View Profile
                            </Button>
                            {/* <Button
                              variant="outline-success"
                              onClick={() => {
                                setSelectedApplicant(index);
                                changeStatus("accepted");
                              }}
                              disabled={statusLoading}
                            >
                              Accept
                            </Button>
                            <Button
                              variant="outline-danger"
                              onClick={() => {
                                setSelectedApplicant(index);
                                changeStatus("rejected");
                              }}
                              disabled={statusLoading}
                            >
                              Reject
                            </Button> */}
                            <Button
                              variant="outline-info"
                              onClick={() => {
                                setSelectedApplicant(index);
                                changeStatus("completed");
                              }}
                              disabled={statusLoading}
                            >
                              Mark as Completed
                            </Button>
                          </div>
                        </Card.Body>
                      </Card>
                    ))}
                  </ListGroup>
                ) : (
                  <p className="text-center">No applicants for this job yet.</p>
                )}
              </>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default JobApplicantList;
