import React, { useState } from "react";
import {
  Grid,
  Button,
  TextField,
  Box,
  Select,
  FormControl,
  InputLabel,
  Alert,
  Snackbar,
  Input,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import Drawer from "../Components/Drawer";
import { useDispatch, useSelector } from "react-redux";
import { getForm1Data } from "../store/actions/projectActions";
import axiosInstance from "../axios";
import { useEffect } from "react";
import { FormLabel } from "react-bootstrap";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CancelIcon from "@mui/icons-material/Cancel";

const CreateOrganization1Schema = Yup.object().shape({
  organizationName: Yup.string()
    .required("Organization Name is required")
    .min(3, "Too short")
    .max(40, "Too long"),
  industry: Yup.string().required("Industry is required"),
  description: Yup.string().required("Organization Description is required"),
  // .min(100, "Too short")
  // .max(500, "Too long")
  website: Yup.string().url("Invalid URL").required("Website is required"),
});

const TitleStyles = {
  backgroundColor: "#fff",
  color: "#000",
  fontWeight: "800",
  display: "flex",
  fontSize: "2rem",
  marginTop: "3rem",
  marginBottom: "3rem",
};

const fieldStyles = {
  width: { xs: 315, sm: 398 },
  marginBottom: "10px",
};

const formStyles = {
  display: "flex",
  flexDirection: "column",
};

const CustomizedSelectForFormik = ({ children, form, field }) => {
  const { name, value } = field;
  const { setFieldValue } = form;

  return (
    <Select
      label="industry"
      name={name}
      value={value}
      onChange={(e) => {
        setFieldValue(name, e.target.value);
      }}
    >
      {children}
    </Select>
  );
};

function CreateOrganization1() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state?.project?.projectDetails);
  const navigate = useNavigate();

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const [loading, setLoading] = useState(false);
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [showUpload, setShowUpload] = useState(false);

  const [initialValues1, setInitialValues1] = useState({
    organizationName: data ? data.name : "",
    industry: data ? data.industry : "",
    description: data ? data.description : "",
    website: data ? data.website : "",
    logo: null,
  });

  useEffect(() => {
    console.log("initialValues1", initialValues1);
  }, [initialValues1]);

  const [logoPreview, setLogoPreview] = useState("");

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setSelectedLogo(reader.result);
        setLogoPreview(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleToggleUpload = () => {
    setShowUpload(!showUpload);
  };

  const handleCancelUpload = () => {
    setShowUpload(false);
    setLogoPreview(null);
    setSelectedLogo(null);
  };

  const handleSubmit1 = async (values, onSubmitProps) => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    let payload = {
      name: values.organizationName,
      industry: values.industry,
      description: values.description,
      website: values.website,
      logo: selectedLogo,
      users: [userInfo._id],
    };

    console.log("payload", payload);

    dispatch(getForm1Data(payload));

    const config = {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("userInfo")).token
        }`,
      },
    };
    setLoading(true);
    try {
      const res = await axiosInstance.post(
        `${process.env.REACT_APP_BE_URL}/organization`,
        payload,
        config
      );
      if (res.status === 200) {
        setAlert({
          showAlert: true,
          severity: "success",
          message: "Organization added successfully!",
        });

        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "success",
            message: "",
          });
          navigate("/organizations");
        }, 3000);

        onSubmitProps.setSubmitting(false);
        onSubmitProps.resetForm();
        setInitialValues1({
          organizationName: "",
          industry: "",
          description: "",
          website: "",
          logo: "",
        });
        setLogoPreview("");
      }
    } catch (error) {
      console.log(error);
      setAlert({
        showAlert: true,
        severity: "error",
        message: error.response?.data?.message || "Action Failed",
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "error",
          message: "",
        });
      }, 16000);

      onSubmitProps.setSubmitting(false);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setAlert({
      ...alert,
      showAlert: false,
    });
  };

  return (
    <>
      <Drawer />
      <Grid container justifyContent="center" sx={{ paddingTop: "70px" }}>
        <Snackbar
          open={alert.showAlert}
          autoHideDuration={15000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            severity={alert.severity}
            onClose={handleClose}
            sx={{
              fontSize: "16px",
              overflowWrap: "break-word",
              marginTop: "4rem",
              marginRight: {
                lg: "2rem",
                md: "2rem",
                sm: "1rem",
              },
            }}
          >
            {alert.message}
          </Alert>
        </Snackbar>
        <Grid item style={TitleStyles}>
          Organization Details
        </Grid>
        <Grid
          container
          item
          direction="column"
          alignItems="center"
          paddingBottom={10}
        >
          <Formik
            initialValues={initialValues1}
            onSubmit={handleSubmit1}
            validationSchema={CreateOrganization1Schema}
          >
            {({ errors, isValid, touched, dirty }) => (
              <Form style={formStyles}>
                <Grid item>
                  <Field
                    sx={fieldStyles}
                    name="organizationName"
                    label="Organization Name"
                    as={TextField}
                    variant="outlined"
                    required
                    size="small"
                    margin="dense"
                    error={
                      Boolean(errors.organizationName) &&
                      Boolean(touched.organizationName)
                    }
                    helperText={
                      Boolean(touched.organizationName) &&
                      errors.organizationName
                    }
                  ></Field>
                </Grid>
                <Grid item>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth required>
                      <InputLabel name="industry">Industry</InputLabel>
                      <Field
                        component={CustomizedSelectForFormik}
                        name="industry"
                        as={Select}
                        error={
                          Boolean(errors.industry) && Boolean(touched.industry)
                        }
                        helperText={
                          Boolean(touched.industry) && errors.industry
                        }
                      >
                        <MenuItem value="Consumer Discretionary">
                          Consumer Discretionary
                        </MenuItem>
                        <MenuItem value="Consumer Staples">
                          Consumer Staples
                        </MenuItem>
                        <MenuItem value="Construction">Construction</MenuItem>
                        <MenuItem value="Energy">Energy</MenuItem>
                        <MenuItem value="Materials">Materials</MenuItem>
                        <MenuItem value="Industrials">Industrials</MenuItem>
                        <MenuItem value="Healthcare">Healthcare</MenuItem>
                        <MenuItem value="Financials">Financials</MenuItem>
                        <MenuItem value="Information Technology">
                          Information Technology
                        </MenuItem>
                        <MenuItem value="Real Estate">Real Estate</MenuItem>
                        <MenuItem value="Communication Services">
                          Communication Services
                        </MenuItem>
                        <MenuItem value="Utilities">Utilities</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                      </Field>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item>
                  <Field
                    sx={fieldStyles}
                    name="description"
                    label="Organization Description (Min. 100 characters)"
                    as={TextField}
                    variant="outlined"
                    size="small"
                    margin="dense"
                    multiline
                    rows={10}
                    error={
                      Boolean(errors.description) &&
                      Boolean(touched.description)
                    }
                    helperText={
                      Boolean(touched.description) && errors.description
                    }
                  ></Field>
                </Grid>
                <Grid item>
                  <Field
                    sx={fieldStyles}
                    name="website"
                    label="Website"
                    as={TextField}
                    variant="outlined"
                    size="small"
                    margin="dense"
                    error={Boolean(errors.website) && Boolean(touched.website)}
                    helperText={Boolean(touched.website) && errors.website}
                  ></Field>
                </Grid>

                {/* Logo Upload Field */}
                <Grid item>
                  <FormControl variant="outlined" fullWidth margin="normal">
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={2}
                    >
                      <FormLabel
                        component="legend"
                        sx={{
                          fontWeight: "bold",
                          fontSize: "16px",
                          color: "text.primary",
                        }}
                      >
                        Organization Logo
                      </FormLabel>
                      <Button
                        variant="contained"
                        color={showUpload ? "secondary" : "primary"}
                        startIcon={
                          showUpload ? <CancelIcon /> : <CloudUploadIcon />
                        }
                        onClick={handleToggleUpload}
                        sx={{
                          textTransform: "none",
                          borderRadius: "20px",
                        }}
                      >
                        {showUpload ? "Cancel" : "Upload Logo"}
                      </Button>
                    </Box>
                    {showUpload && (
                      <Box>
                        <Box display="flex" alignItems="center" mb={2}>
                          <TextField
                            type="file"
                            id="logo"
                            name="logo"
                            onChange={handleImageChange}
                            inputProps={{ accept: "image/*" }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            sx={{
                              display: "none",
                            }}
                          />
                          <label htmlFor="logo">
                            <Button
                              variant="outlined"
                              component="span"
                              startIcon={<CloudUploadIcon />}
                              sx={{
                                textTransform: "none",
                                borderRadius: "20px",
                                mr: 2,
                              }}
                            >
                              Choose File
                            </Button>
                          </label>
                        </Box>
                        {logoPreview && (
                          <>
                            <IconButton
                              onClick={handleCancelUpload}
                              color="error"
                            >
                              <CancelIcon />
                            </IconButton>
                            <Box
                              display="flex"
                              flexDirection="column"
                              alignItems="center"
                            >
                              <Typography
                                variant="body2"
                                sx={{ mb: 1, color: "text.secondary" }}
                              >
                                Logo Preview:
                              </Typography>
                              <img
                                src={logoPreview}
                                alt="Logo Preview"
                                style={{
                                  maxWidth: "150px",
                                  maxHeight: "150px",
                                  borderRadius: "8px",
                                  boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
                                }}
                              />
                            </Box>
                          </>
                        )}
                      </Box>
                    )}
                  </FormControl>
                </Grid>

                <Grid item>
                  <Button
                    sx={{
                      borderRadius: "8px",
                      fontSize: "1rem",
                      fontWeight: "700",
                      height: "2.5rem",
                      marginTop: "1rem",
                    }}
                    color="primary"
                    fullWidth
                    variant="contained"
                    disabled={!dirty || !isValid || loading}
                    type="submit"
                    size="large"
                  >
                    {loading ? <CircularProgress size={24} /> : "Submit"}
                  </Button>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </>
  );
}

export default CreateOrganization1;
