import React from "react";
import Navbar4 from "../../../Components/navbar/Navbar4/Navbar4";
import Footer2 from "../../../Components/footer/Footer2";
import { Outlet } from "react-router-dom";

const LayoutPage = () => {
  return (
    <div>
      <Navbar4 fixedWidth />
      <Outlet />
      <Footer2 />
    </div>
  );
};

export default LayoutPage;
