import React, { useEffect, useState } from "react";
import Drawer from "../Components/Drawer";

import IconButton from "@mui/material/IconButton";
import ImageChangemodel from "./ProfilePageModels/ImageChangemodel";
import InputAdornment from "@mui/material/InputAdornment";
import {
  Avatar,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
  Alert,
  Snackbar,
  Divider,
  Menu,
  Skeleton,
  Badge,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import AddIcon from "@mui/icons-material/Add";
import Education from "./Education";
import Experiance from "./Experiance";
import Links from "./Links";
import Skills from "./Skills";
import StartupTypes from "./StartupTypes";
import { styled, alpha } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";

import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";
import axios from "../axios";
import "../styles/AllMainPagesCss.css";
import SchoolIcon from "@mui/icons-material/School";
import CardTravelIcon from "@mui/icons-material/CardTravel";
import LinkIcon from "@mui/icons-material/Link";
import DeleteIcon from "@mui/icons-material/Delete";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate, useParams } from "react-router-dom";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";

const url = process.env.REACT_APP_BE_URL;

let GeneralDetailsSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("First Name is required!")
    .min(3, "Too short!")
    .max(20, "Too long!"),
  lastName: Yup.string()
    .required("Last Name is required!")
    .min(3, "Too short")
    .max(20, "Too long"),
  email: Yup.string().email().required("Email is required!"),
  interesetedIn: Yup.string().required("Interested In is required!"),
});

const SubTitleHeaders = {
  marginTop: "5px",
  fontWeight: "700",
  fontSize: {
    lg: "1.2rem",
    md: "1.2rem",
    sm: "1.1rem",
    xs: "1rem",
  },
};
const SubTitleHeaders2 = {
  marginTop: "5px",
  marginBottom: "10px",
  fontWeight: "700",
  fontSize: {
    lg: "1.2rem",
    md: "1.2rem",
    sm: "1.1rem",
    xs: "1.1rem",
  },
};

const Profile = () => {
  const [hideEmail, sethideEmail] = useState(false);
  const [isResetPasswordLoading, setResetPasswordLoading] = useState(false);

  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  //uplode image model
  const [openImageChnageModel, setopenImageChnageModel] = useState(false);

  //image model close
  const closeImageChnageModel = () => {
    setopenImageChnageModel(false);
  };

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const { userId } = useParams();
  const [checkUser, setCheckUser] = useState(false);

  //Affiliations
  // eslint-disable-next-line
  const [projects, setProjects] = useState([]);
  const [projectsByUser, setProjectsByUser] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [equity, setEquity] = useState([]);

  // Education
  const [indexEdu, setIndexEdu] = useState(null);
  const [isEditDataEdu, setIsEditDataEdu] = useState(false);
  const [openModelEdu, setOpenModelEdu] = React.useState(false);
  const [anchorEdu, setAnchorEdu] = React.useState(null);
  const openEdu = Boolean(anchorEdu);

  // Experience
  const [indexExpe, setIndexExpe] = useState(null);
  const [isEditDataExpe, setIsEditDataExpe] = useState(false);
  const [openModelExpe, setOpenModelExpe] = React.useState(false);
  const [anchorExpe, setAnchorExpe] = React.useState(null);
  const openExpe = Boolean(anchorExpe);
  const [check, setCheck] = useState(true);

  //Links
  const [indexLink, setIndexLink] = useState(null);
  const [isEditDataLink, setIsEditDataLink] = useState(false);
  const [openModelLink, setOpenModelLink] = React.useState(false);
  const [anchorLink, setAnchorLink] = React.useState(null);
  const openLink = Boolean(anchorLink);

  // General Details
  const [interestedIn, setInterestedIn] = React.useState("");
  const [btnDisabled, setbtnDisabled] = React.useState(false);
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    interesetedIn: "",
  });

  // Skills
  const [openModelSkill, setOpenModelSkill] = React.useState(false);

  // Startup Types

  // eslint-disable-next-line
  const [startup, setStartup] = useState([]);
  const [openModelStartupTypes, setOpenModelStartupTypes] =
    React.useState(false);
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  //Styled Menu Component
  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

  //Menu Open Functions
  const handleClickEdu = (event) => {
    setAnchorEdu(event.currentTarget);
  };
  const handleClickExpe = (event) => {
    setAnchorExpe(event.currentTarget);
  };
  const handleClickLink = (event) => {
    setAnchorLink(event.currentTarget);
  };

  //Menu Close Functions
  const handleCloseMenuEdu = () => {
    setAnchorEdu(null);
  };
  const handleCloseMenuExpe = () => {
    setAnchorExpe(null);
  };
  const handleCloseMenuLink = () => {
    setAnchorLink(null);
  };

  const items = JSON.parse(localStorage.getItem("userInfo"));

  //Common Get Data
  const getData = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("userInfo")).token
          }`,
        },
      };
      // eslint-disable-next-line
      const { data, status } = await axios.get(
        `${url}/user/${userId ? userId : items._id}`,
        config
      );
      setData(data);
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${
          error.response.data.message
            ? error.response.data.message
            : error.response.data
            ? error.response.data
            : "Action Failed"
        }`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);
    }
  };

  //Affiliations Function
  const getProjectsByUserId = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("userInfo")).token
          }`,
        },
      };
      const { data, status } = await axios.get(
        `${url}/project/user/${userId}`,
        config
      );
      if (status === 200) {
        setProjectsByUser(data);
        if (data?.length === 0) {
          setLoading(true);
        }
      }
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${
          error.response.data.message
            ? error.response.data.message
            : error.response.data
            ? error.response.data
            : "Action Failed"
        }`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);
    }
  };
  const getProjects = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("userInfo")).token
          }`,
        },
      };
      const { data, status } = await axios.get(`${url}/project`, config);
      if (status === 200) {
        setProjects(data);
      }
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${
          error.response.data.message
            ? error.response.data.message
            : error.response.data
            ? error.response.data
            : "Action Failed"
        }`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);
    }
  };

  const getJobs = async () => {
    let jobArr = [];
    const config = {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("userInfo")).token
        }`,
      },
    };
    try {
      await data?.jobs?.forEach(async (jobID, index) => {
        const res = await axios.get(`${url}/job/${jobID}`, config);
        if (res.status === 200) {
          jobArr.push(res.data);
          if (data?.jobs?.length === jobArr.length) {
            if (jobArr?.length === 0) {
              setLoading(true);
            }
            return setJobs(jobArr);
          }
        }
      });
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${
          error.response.data.message
            ? error.response.data.message
            : error.response.data
            ? error.response.data
            : "Action Failed"
        }`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);
    }
  };

  const getUserRole = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("userInfo")).token
        }`,
      },
    };
    try {
      const { data, status } = await axios.get(
        `${url}/equity/role/${userId}`,
        config
      );
      if (status === 200) {
        setEquity(data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getUserRole();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data) {
      setInitialValues({
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: data?.email,
        interesetedIn: data?.interests,
      });
      setInterestedIn(data.interests);
    }
  }, [data]);

  useEffect(() => {
    getProjects();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getProjectsByUserId();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getJobs();
    // eslint-disable-next-line
  }, [data]);

  //General Details Functions

  function handleClose() {
    setAlert({
      ...alert,
      showAlert: false,
      severity: "",
      message: "",
    });
  }

  const handleChange = (event) => {
    setInterestedIn(event.target.value);
  };

  const handleSubmit = async (values) => {
    setbtnDisabled(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("userInfo")).token
          }`,
        },
      };

      const { data, status } = await axios.put(
        `${url}/user/${userId}`,
        {
          firstName: values.firstName,
          lastName: values.lastName,
          interests: interestedIn,
        },
        config
      );
      if (status === 200) {
        localStorage.setItem(
          "userInfo",
          JSON.stringify({
            ...data,
            signVerifyToken: items.signVerifyToken,
            token: items.token,
          })
        );
        setbtnDisabled(false);
        setAlert({
          showAlert: true,
          severity: "success",
          message: "Profile updated!",
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "success",
            message: "",
          });
        }, 16000);
      }
    } catch (error) {
      setbtnDisabled(false);
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${
          error.response.data.message
            ? error.response.data.message
            : error.response.data
            ? error.response.data
            : "Action Failed"
        }`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);
    }
  };
  useEffect(() => {
    if (items._id === userId) {
      setCheckUser(true);
    }
    // eslint-disable-next-line
  }, []);

  // Modal Close functions
  const handleCloseModelEdu = () => {
    setOpenModelEdu(false);
    setIsEditDataEdu(false);
    setIndexEdu(null);
  };

  const handleCloseModelExpe = () => {
    setOpenModelExpe(false);
    setIsEditDataExpe(false);
    setIndexExpe(null);
    setCheck(true);
  };

  const handleCloseModelLink = () => {
    setOpenModelLink(false);
    setIsEditDataLink(false);
    setIndexLink(null);
  };

  const handleCloseModelSkill = () => {
    setOpenModelSkill(false);
  };

  const handleCloseModelStartupTypes = () => {
    setOpenModelStartupTypes(false);
  };

  // Edit Button Functions
  const editDataEdu = () => {
    setAnchorEdu(null);
    setIsEditDataEdu(true);
  };

  const editDataExpe = () => {
    setAnchorExpe(null);
    setIsEditDataExpe(true);
  };

  const editDataLink = () => {
    setAnchorLink(null);
    setIsEditDataLink(true);
  };

  //Delete Button Functions
  const deleteDataEducation = async () => {
    setAnchorEdu(null);
    let newEducation = data.education.filter(
      (edu, index) => index !== indexEdu
    );

    const config = {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("userInfo")).token
        }`,
      },
    };

    try {
      let payLoad = {
        education: newEducation,
      };
      const { status } = await axios.put(
        `${url}/user/${items._id}`,
        payLoad,
        config
      );
      getData();
      if (status === 200) {
        setAlert({
          showAlert: true,
          severity: "success",
          message: "Item is deleted successfully!",
        });

        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "success",
            message: "",
          });
        }, 16000);
      }
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${
          error.response.data.message
            ? error.response.data.message
            : error.response.data
            ? error.response.data
            : "Action Failed"
        }`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);
    }
  };

  const deleteDataExpe = async () => {
    setAnchorExpe(null);
    let newExpereince = data.pastExperience.filter(
      (expe, index) => index !== indexExpe
    );

    const config = {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("userInfo")).token
        }`,
      },
    };

    try {
      let payLoad = {
        pastExperience: newExpereince,
      };
      const { status } = await axios.put(
        `${url}/user/${items._id}`,
        payLoad,
        config
      );
      getData();
      if (status === 200) {
        setAlert({
          showAlert: true,
          severity: "success",
          message: "Item is deleted successfully!",
        });

        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "success",
            message: "",
          });
        }, 16000);
      }
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${
          error.response.data.message
            ? error.response.data.message
            : error.response.data
            ? error.response.data
            : "Action Failed"
        }`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);
    }
  };

  const deleteDataLink = async () => {
    setAnchorLink(null);
    let newLink = data.links.filter((link, index) => index !== indexLink);

    const config = {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("userInfo")).token
        }`,
      },
    };

    try {
      let payLoad = {
        links: newLink,
      };
      const { status } = await axios.put(
        `${url}/user/${items._id}`,
        payLoad,
        config
      );
      getData();
      if (status === 200) {
        setAlert({
          showAlert: true,
          severity: "success",
          message: "Item is deleted successfully!",
        });

        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "success",
            message: "",
          });
        }, 16000);
      }
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${
          error.response.data.message
            ? error.response.data.message
            : error.response.data
            ? error.response.data
            : "Action Failed"
        }`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);
    }
  };

  const deleteDataSkill = async (item) => {
    let newSkills = data.skills.filter((skill) => skill !== item);

    const config = {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("userInfo")).token
        }`,
      },
    };

    try {
      let payLoad = {
        skills: newSkills,
      };
      const { status } = await axios.put(
        `${url}/user/${items._id}`,
        payLoad,
        config
      );
      getData();
      if (status === 200) {
        setAlert({
          showAlert: true,
          severity: "success",
          message: "Skill is deleted successfully!",
        });

        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "success",
            message: "",
          });
        }, 16000);
      }
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${
          error.response.data.message
            ? error.response.data.message
            : error.response.data
            ? error.response.data
            : "Action Failed"
        }`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);
    }
  };

  const deleteDataStartupTypes = async (item) => {
    let newStartup = data.startupTypes.filter((type) => type !== item);
    setStartup(newStartup);

    const config = {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("userInfo")).token
        }`,
      },
    };

    try {
      let payLoad = {
        startupTypes: newStartup,
      };
      const { status } = await axios.put(
        `${url}/user/${items._id}`,
        payLoad,
        config
      );
      getData();

      if (status === 200) {
        setAlert({
          showAlert: true,
          severity: "success",
          message: "Startup Type is deleted successfully!",
        });

        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "success",
            message: "",
          });
        }, 16000);
      }
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${
          error.response.data.message
            ? error.response.data.message
            : error.response.data
            ? error.response.data
            : "Action Failed"
        }`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);
    }
  };

  useEffect(() => {
    if (isEditDataEdu) {
      setOpenModelEdu(true);
    }
    if (isEditDataExpe) {
      setOpenModelExpe(true);
    }
    if (isEditDataLink) {
      setOpenModelLink(true);
    }
  }, [isEditDataEdu, isEditDataExpe, isEditDataLink]);

  //Modal Close Functions

  const handleCreateModelEdu = () => {
    setIndexEdu(null);
    setOpenModelEdu(true);
  };

  const handleCreateModelExpe = () => {
    setIndexExpe(null);
    setOpenModelExpe(true);
  };

  const handleCreateModelLink = () => {
    setIndexLink(null);
    setOpenModelLink(true);
  };

  const handleCreateModelSkill = () => {
    setOpenModelSkill(true);
  };

  const handleCreateModelStartupTypes = () => {
    setOpenModelStartupTypes(true);
  };

  const resetPassword = async () => {
    setResetPasswordLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("userInfo")).token
          }`,
        },
      };
      const { status } = await axios.get(
        `${url}/user/resetUserPassword`,
        config
      );
      if (status === 200) {
        setAlert({
          showAlert: true,
          severity: "success",
          message:
            "Please check your mail inbox,Password reset link has been sent!",
        });
        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "success",
            message: "",
          });
        }, 16000);
      }
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${
          error.response.data.message
            ? error.response.data.message
            : error.response.data
            ? error.response.data
            : "Action Failed"
        }`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);
    }
    setResetPasswordLoading(false);
  };

  return (
    <>
      <Drawer />
      <Grid
        container
        lg={12}
        md={12}
        sm={12}
        xs={12}
        direction="column"
        // className="PageSize"
        alignItems="center"
        justifyContent="center"
      >
        <Snackbar
          open={alert.showAlert}
          autoHideDuration={15000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            severity={alert.severity}
            onClose={handleClose}
            sx={{
              fontSize: "16px",
              overflowWrap: "break-word",
              marginTop: "4rem",
              marginRight: {
                lg: "2rem",
                md: "2rem",
                sm: "1rem",
              },
            }}
          >
            {alert.message}
          </Alert>
        </Snackbar>

        <Grid container lg={12}>
          <Grid container item lg={1} md={1.5} sm={1.8} xs={1}>
            {/* <Sidebar /> */}
          </Grid>
          {data ? (
            <Grid
              container
              item
              marginTop={10}
              lg={10.5}
              md={9.8}
              sm={10}
              xs={10}
            >
              <ImageChangemodel
                open={openImageChnageModel}
                onClose={closeImageChnageModel}
                userData={userInfo}
                updateData={getData}
              />

              <Grid
                container
                item
                marginBottom={4}
                lg={12}
                md={12}
                sm={12}
                xs={12}
              >
                <Grid
                  item
                  container
                  alignItems="center"
                  justifyContent="center"
                  lg={3}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  {items._id === userId ? (
                    <>
                      <Badge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        badgeContent={
                          <IconButton
                            sx={{
                              color: "black",
                              backgroundColor: "#BDBDBD",
                              width: "2.5rem",
                              height: "2.5rem",
                              padding: "0.5rem",
                              borderRadius: 50,
                            }}
                            onClick={() => {
                              setopenImageChnageModel(true);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        }
                      >
                        <Avatar
                          src={data.profilePic.url}
                          sx={{
                            marginTop: "1rem",
                            width: {
                              lg: 200,
                              md: 200,
                              sm: 160,
                              xs: 130,
                            },
                            height: {
                              lg: 200,
                              md: 200,
                              sm: 160,
                              xs: 130,
                            },
                          }}
                        />
                      </Badge>
                    </>
                  ) : (
                    <Avatar
                      src={data.profilePic.url}
                      sx={{
                        marginTop: "1rem",
                        width: {
                          lg: 200,
                          md: 200,
                          sm: 160,
                          xs: 130,
                        },
                        height: {
                          lg: 200,
                          md: 200,
                          sm: 160,
                          xs: 130,
                        },
                      }}
                    />
                  )}
                </Grid>
                {/* General Details */}
                <Grid
                  item
                  container
                  sx={{
                    alignItems: "center",
                    justifyContent: {
                      lg: "flex-start",
                      md: "center",
                      sm: "center",
                      xs: "center",
                    },
                  }}
                  marginTop={2}
                  lg={9}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={GeneralDetailsSchema}
                    enableReinitialize={true}
                  >
                    {({ errors, isValid, touched, dirty }) => (
                      <Form>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Typography
                            sx={{
                              marginLeft: { md: "1rem" },
                              marginTop: "5px",
                              fontWeight: "700",
                              fontSize: {
                                lg: "1.2rem",
                                md: "1.2rem",
                                sm: "1.1rem",
                                xs: "1rem",
                              },
                              marginBottom: {
                                lg: "0.5rem",
                                md: "1rem",
                                sm: "1rem",
                                xs: "1rem",
                              },
                              textAlign: {
                                lg: "left",
                                md: "center",
                                sm: "center",
                                xs: "center",
                              },
                            }}
                            color="primary"
                          >
                            General Details
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          item
                          direction="row"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Grid
                            item
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            sx={{
                              paddingRight: {
                                lg: "1rem",
                                md: "0.5rem",
                                sm: "3rem",
                                xs: "1rem",
                              },
                              paddingLeft: {
                                lg: "1rem",
                                md: "1.3rem",
                                sm: "3rem",
                                xs: "1rem",
                              },
                            }}
                          >
                            <Field
                              sx={{
                                width: "100%",
                              }}
                              name="firstName"
                              label="First Name"
                              as={TextField}
                              variant="outlined"
                              required
                              size="small"
                              margin="dense"
                              disabled={!checkUser}
                              error={
                                Boolean(errors.firstName) &&
                                Boolean(touched.firstName)
                              }
                              helperText={
                                Boolean(touched.firstName) && errors.firstName
                              }
                            ></Field>
                          </Grid>
                          <Grid
                            item
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            sx={{
                              paddingRight: {
                                lg: "1rem",
                                md: "0.5rem",
                                sm: "3rem",
                                xs: "1rem",
                              },
                              paddingLeft: {
                                lg: "1rem",
                                md: "1.3rem",
                                sm: "3rem",
                                xs: "1rem",
                              },
                            }}
                          >
                            <Field
                              sx={{
                                width: "100%",
                              }}
                              name="lastName"
                              label="Last Name"
                              as={TextField}
                              variant="outlined"
                              required
                              size="small"
                              margin="dense"
                              disabled={!checkUser}
                              error={
                                Boolean(errors.lastName) &&
                                Boolean(touched.lastName)
                              }
                              helperText={
                                Boolean(touched.lastName) && errors.lastName
                              }
                            ></Field>
                          </Grid>
                        </Grid>

                        <Grid
                          // style={formStyles}
                          container
                          item
                          direction="row"
                          alignItems="center"
                          justifyContent="center"
                          marginTop={2}
                        >
                          {checkUser ? (
                            <Grid
                              item
                              lg={6}
                              md={6}
                              sm={12}
                              xs={12}
                              sx={{
                                paddingRight: {
                                  lg: "1rem",
                                  md: "0.5rem",
                                  sm: "3rem",
                                  xs: "1rem",
                                },
                                paddingLeft: {
                                  lg: "1rem",
                                  md: "1.3rem",
                                  sm: "3rem",
                                  xs: "1rem",
                                },
                              }}
                            >
                              <Field
                                sx={{
                                  width: "100%",
                                }}
                                type={hideEmail ? "email" : "password"}
                                name="email"
                                label="Email"
                                as={TextField}
                                variant="outlined"
                                required
                                size="small"
                                margin="dense"
                                disabled
                                error={
                                  Boolean(errors.email) &&
                                  Boolean(touched.email)
                                }
                                helperText={
                                  Boolean(touched.email) && errors.email
                                }
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() =>
                                          sethideEmail((oldVal) => !oldVal)
                                        }
                                      >
                                        {hideEmail ? (
                                          <VisibilityOff />
                                        ) : (
                                          <Visibility />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              ></Field>
                            </Grid>
                          ) : null}

                          <Grid
                            item
                            lg={6}
                            md={6}
                            sm={12}
                            xs={12}
                            sx={{
                              paddingRight: {
                                lg: "1rem",
                                md: "0.5rem",
                                sm: "3rem",
                                xs: "1rem",
                              },
                              paddingLeft: {
                                lg: "1rem",
                                md: "1.3rem",
                                sm: "3rem",
                                xs: "1rem",
                              },
                            }}
                          >
                            <Box
                              sx={{
                                // minWidth: 120,
                                marginTop: {
                                  lg: "0rem",
                                  md: "0rem",
                                  sm: "0.5rem",
                                  xs: "0.5rem",
                                },
                              }}
                              className="marginToptext"
                            >
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  Interested In
                                </InputLabel>

                                <Field
                                  as={Select}
                                  sx={{
                                    height: "2.5rem",
                                    marginTop: "0.5rem",
                                    width: "100%",
                                  }}
                                  value={interestedIn}
                                  label="Interested In"
                                  disabled={!checkUser}
                                  onChange={handleChange}
                                  required
                                >
                                  <MenuItem value="Creating projects">
                                    Creating Projects
                                  </MenuItem>
                                  <MenuItem value="Collaborating on projects">
                                    Collaborating on projects
                                  </MenuItem>
                                  <MenuItem value="Both">Both</MenuItem>
                                </Field>
                              </FormControl>
                            </Box>
                          </Grid>
                        </Grid>
                        {checkUser ? (
                          <Grid
                            container
                            item
                            alignItems="center"
                            justifyContent="center"
                            direction="row"
                          >
                            <Grid
                              item
                              sx={{
                                textAlign: {
                                  lg: "initial",
                                  md: "initial",
                                  sm: "center",
                                },
                                paddingRight: {
                                  lg: "1rem",
                                  md: "0.5rem",
                                  sm: "3rem",
                                  xs: "1rem",
                                },
                                paddingLeft: {
                                  lg: "1rem",
                                  md: "1.3rem",
                                  sm: "3rem",
                                  xs: "1rem",
                                },
                              }}
                              lg={6}
                              md={6}
                              sm={12}
                              xs={12}
                            >
                              <Button
                                disabled={btnDisabled}
                                sx={{
                                  borderRadius: "8px",
                                  fontSize: "1rem",
                                  fontWeight: "700",
                                  height: "2.5rem",
                                  marginTop: "1rem",
                                  width: {
                                    lg: "23rem",
                                    md: "23rem",
                                    sm: "100%",
                                    xs: "100%",
                                  },
                                  textTransform: "none",
                                }}
                                color="primary"
                                variant="contained"
                                type="submit"
                                size="large"
                              >
                                {btnDisabled ? (
                                  <CircularProgress size={20} />
                                ) : (
                                  "Update Details"
                                )}
                              </Button>
                            </Grid>
                            <Grid
                              item
                              sx={{
                                textAlign: {
                                  lg: "initial",
                                  md: "initial",
                                  sm: "center",
                                },
                                paddingRight: {
                                  lg: "1rem",
                                  md: "0.5rem",
                                  sm: "3rem",
                                  xs: "1rem",
                                },
                                paddingLeft: {
                                  lg: "1rem",
                                  md: "1.3rem",
                                  sm: "3rem",
                                  xs: "1rem",
                                },
                              }}
                              lg={6}
                              md={6}
                              sm={12}
                              xs={12}
                            >
                              <Button
                                sx={{
                                  borderRadius: "8px",
                                  fontSize: "1rem",
                                  fontWeight: "700",
                                  height: "2.5rem",
                                  marginTop: "1rem",
                                  width: {
                                    lg: "23rem",
                                    md: "23rem",
                                    sm: "100%",
                                    xs: "100%",
                                  },
                                  textTransform: "none",
                                }}
                                color="primary"
                                variant="contained"
                                size="large"
                                onClick={() => resetPassword()}
                                endIcon={
                                  !isResetPasswordLoading && (
                                    <>
                                      <ArrowCircleRightRoundedIcon />
                                    </>
                                  )
                                }
                                disabled={isResetPasswordLoading}
                              >
                                {isResetPasswordLoading ? (
                                  <CircularProgress size={20} />
                                ) : (
                                  "Reset password"
                                )}
                              </Button>
                            </Grid>
                          </Grid>
                        ) : null}
                      </Form>
                    )}
                  </Formik>
                </Grid>
              </Grid>

              {/* Affiliations */}
              {projectsByUser.length > 0 || jobs.length > 0 ? (
                <Grid
                  container
                  alignItems="center"
                  justifyContent={{
                    lg: "flex-end",
                    md: "center",
                    sm: "center",
                    xs: "center",
                  }}
                  xs={12}
                >
                  <Box
                    border={1}
                    borderRadius="8px"
                    padding="16px 16px 16px 35px"
                    sx={{
                      width: {
                        lg: "100%",
                        md: "70%",
                        sm: "85%",
                        xs: "100%",
                      },
                      marginBottom: {
                        lg: "1rem",
                        md: "1rem",
                        sm: "1rem",
                        xs: "1rem",
                      },
                    }}
                  >
                    <Grid container item>
                      <Grid item>
                        <Typography color="primary" sx={SubTitleHeaders}>
                          Affiliations
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      sx={{
                        minHeight: "auto",
                        maxHeight: "170px",
                        overflowX: "hidden",
                        overflowY: "auto",
                      }}
                    >
                      <Grid
                        container
                        item
                        // marginBottom={4}
                        direction={{
                          lg: "row",
                          md: "row",
                          sm: "row",
                          xs: "row",
                        }}
                        alignItems="center"
                        justifyContent="space-evenly"
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                      >
                        {projectsByUser?.map((item) => {
                          return (
                            <Grid
                              key={item._id}
                              item
                              lg={3}
                              md={6}
                              sm={6}
                              xs={12}
                            >
                              <List dense>
                                <ListItem disablePadding disableGutters>
                                  <ListItemButton
                                    onClick={() =>
                                      navigate(`/projectdetails/${item._id}`)
                                    }
                                  >
                                    <ListItemIcon
                                      sx={{ justifyContent: "center" }}
                                    >
                                      <Avatar
                                        sx={{
                                          height: {
                                            lg: 60,
                                            md: 50,
                                            sm: 40,
                                            xs: 40,
                                          },
                                          width: {
                                            lg: 60,
                                            md: 50,
                                            sm: 40,
                                            xs: 40,
                                          },
                                          margin: "0 0.5rem",
                                        }}
                                      >
                                        <AccountTreeOutlinedIcon />
                                      </Avatar>
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={item.name}
                                      secondary={
                                        <>
                                          {equity.find((rec) =>
                                            item.equityID.find((item) => {
                                              return item === rec._id;
                                            })
                                          )?.role
                                            ? equity.find((rec) =>
                                                item.equityID.find((item) => {
                                                  return item === rec._id;
                                                })
                                              )?.role
                                            : "member"}
                                          <br />
                                          {item.status}
                                        </>
                                      }
                                    />
                                  </ListItemButton>
                                </ListItem>
                              </List>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                    <Divider
                      sx={{
                        borderBottomWidth: 2.5,
                        bgcolor: "#E5E5E5",
                      }}
                    />

                    <Grid
                      container
                      item
                      sx={{
                        minHeight: "auto",
                        maxHeight: "170px",
                        overflowX: "hidden",
                        overflowY: "auto",
                      }}
                    >
                      <Grid
                        container
                        item
                        // marginBottom={4}
                        direction={{
                          lg: "row",
                          md: "row",
                          sm: "row",
                          xs: "row",
                        }}
                        alignItems="center"
                        justifyContent="space-evenly"
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                      >
                        {jobs?.map((item) => {
                          return (
                            <Grid
                              key={item._id}
                              item
                              lg={3}
                              md={6}
                              sm={6}
                              xs={12}
                            >
                              <List dense>
                                <ListItem disablePadding disableGutters>
                                  <ListItemButton
                                    onClick={() =>
                                      navigate(`/JobDetails/${item._id}`)
                                    }
                                  >
                                    <ListItemIcon
                                      sx={{ justifyContent: "center" }}
                                    >
                                      <Avatar
                                        sx={{
                                          height: {
                                            lg: 60,
                                            md: 50,
                                            sm: 40,
                                            xs: 40,
                                          },
                                          width: {
                                            lg: 60,
                                            md: 50,
                                            sm: 40,
                                            xs: 40,
                                          },
                                          margin: "0 0.5rem",
                                        }}
                                      >
                                        <FactCheckIcon />
                                      </Avatar>
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={item.title}
                                      secondary={item.status}
                                    />
                                  </ListItemButton>
                                </ListItem>
                              </List>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              ) : loading ? null : (
                <Grid
                  container
                  alignItems="center"
                  justifyContent={{
                    lg: "flex-end",
                    md: "center",
                    sm: "center",
                    xs: "center",
                  }}
                  xs={12}
                >
                  <Box
                    border={1}
                    borderRadius="8px"
                    padding="16px 16px 16px 35px"
                    sx={{
                      width: {
                        lg: "100%",
                        md: "70%",
                        sm: "85%",
                        xs: "100%",
                      },
                      marginBottom: {
                        lg: "1rem",
                        md: "1rem",
                        sm: "1rem",
                        xs: "1rem",
                      },
                    }}
                  >
                    <Grid container item>
                      <Grid item>
                        <Typography color="primary" sx={SubTitleHeaders}>
                          Affiliations
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      sx={{
                        minHeight: "auto",
                        maxHeight: "170px",
                        overflowX: "hidden",
                        overflowY: "auto",
                      }}
                    >
                      <Grid
                        container
                        item
                        // marginBottom={4}
                        direction={{
                          lg: "row",
                          md: "row",
                          sm: "row",
                          xs: "row",
                        }}
                        alignItems="center"
                        justifyContent="space-evenly"
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                      >
                        <Grid item lg={3} md={6} sm={6} xs={12}>
                          <List dense>
                            <ListItem disablePadding disableGutters>
                              <ListItemIcon sx={{ justifyContent: "center" }}>
                                <Skeleton
                                  variant="circular"
                                  sx={{
                                    height: {
                                      lg: 60,
                                      md: 50,
                                      sm: 40,
                                      xs: 40,
                                    },
                                    width: {
                                      lg: 60,
                                      md: 50,
                                      sm: 40,
                                      xs: 40,
                                    },
                                    margin: "0 0.5rem",
                                  }}
                                ></Skeleton>
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <>
                                    <Skeleton variant="rectangular" />
                                  </>
                                }
                                secondary={
                                  <>
                                    <Skeleton
                                      variant="rectangular"
                                      sx={{ marginTop: "5px" }}
                                    />
                                  </>
                                }
                              />
                            </ListItem>
                          </List>
                        </Grid>
                        <Grid item lg={3} md={6} sm={6} xs={12}>
                          <List dense>
                            <ListItem disablePadding disableGutters>
                              <ListItemIcon sx={{ justifyContent: "center" }}>
                                <Skeleton
                                  variant="circular"
                                  sx={{
                                    height: {
                                      lg: 60,
                                      md: 50,
                                      sm: 40,
                                      xs: 40,
                                    },
                                    width: {
                                      lg: 60,
                                      md: 50,
                                      sm: 40,
                                      xs: 40,
                                    },
                                    margin: "0 0.5rem",
                                  }}
                                ></Skeleton>
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <>
                                    <Skeleton variant="rectangular" />
                                  </>
                                }
                                secondary={
                                  <>
                                    <Skeleton
                                      variant="rectangular"
                                      sx={{ marginTop: "5px" }}
                                    />
                                  </>
                                }
                              />
                            </ListItem>
                          </List>
                        </Grid>
                        <Grid item lg={3} md={6} sm={6} xs={12}>
                          <List dense>
                            <ListItem disablePadding disableGutters>
                              <ListItemIcon sx={{ justifyContent: "center" }}>
                                <Skeleton
                                  variant="circular"
                                  sx={{
                                    height: {
                                      lg: 60,
                                      md: 50,
                                      sm: 40,
                                      xs: 40,
                                    },
                                    width: {
                                      lg: 60,
                                      md: 50,
                                      sm: 40,
                                      xs: 40,
                                    },
                                    margin: "0 0.5rem",
                                  }}
                                ></Skeleton>
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <>
                                    <Skeleton variant="rectangular" />
                                  </>
                                }
                                secondary={
                                  <>
                                    <Skeleton
                                      variant="rectangular"
                                      sx={{ marginTop: "5px" }}
                                    />
                                  </>
                                }
                              />
                            </ListItem>
                          </List>
                        </Grid>
                        <Grid item lg={3} md={6} sm={6} xs={12}>
                          <List dense>
                            <ListItem disablePadding disableGutters>
                              <ListItemIcon sx={{ justifyContent: "center" }}>
                                <Skeleton
                                  variant="circular"
                                  sx={{
                                    height: {
                                      lg: 60,
                                      md: 50,
                                      sm: 40,
                                      xs: 40,
                                    },
                                    width: {
                                      lg: 60,
                                      md: 50,
                                      sm: 40,
                                      xs: 40,
                                    },
                                    margin: "0 0.5rem",
                                  }}
                                ></Skeleton>
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <>
                                    <Skeleton variant="rectangular" />
                                  </>
                                }
                                secondary={
                                  <>
                                    <Skeleton
                                      variant="rectangular"
                                      sx={{ marginTop: "5px" }}
                                    />
                                  </>
                                }
                              />
                            </ListItem>
                          </List>
                        </Grid>
                        <Grid item lg={3} md={6} sm={6} xs={12}>
                          <List dense>
                            <ListItem disablePadding disableGutters>
                              <ListItemIcon sx={{ justifyContent: "center" }}>
                                <Skeleton
                                  variant="circular"
                                  sx={{
                                    height: {
                                      lg: 60,
                                      md: 50,
                                      sm: 40,
                                      xs: 40,
                                    },
                                    width: {
                                      lg: 60,
                                      md: 50,
                                      sm: 40,
                                      xs: 40,
                                    },
                                    margin: "0 0.5rem",
                                  }}
                                ></Skeleton>
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <>
                                    <Skeleton variant="rectangular" />
                                  </>
                                }
                                secondary={
                                  <>
                                    <Skeleton
                                      variant="rectangular"
                                      sx={{ marginTop: "5px" }}
                                    />
                                  </>
                                }
                              />
                            </ListItem>
                          </List>
                        </Grid>
                        <Grid item lg={3} md={6} sm={6} xs={12}>
                          <List dense>
                            <ListItem disablePadding disableGutters>
                              <ListItemIcon sx={{ justifyContent: "center" }}>
                                <Skeleton
                                  variant="circular"
                                  sx={{
                                    height: {
                                      lg: 60,
                                      md: 50,
                                      sm: 40,
                                      xs: 40,
                                    },
                                    width: {
                                      lg: 60,
                                      md: 50,
                                      sm: 40,
                                      xs: 40,
                                    },
                                    margin: "0 0.5rem",
                                  }}
                                ></Skeleton>
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <>
                                    <Skeleton variant="rectangular" />
                                  </>
                                }
                                secondary={
                                  <>
                                    <Skeleton
                                      variant="rectangular"
                                      sx={{ marginTop: "5px" }}
                                    />
                                  </>
                                }
                              />
                            </ListItem>
                          </List>
                        </Grid>
                        <Grid item lg={3} md={6} sm={6} xs={12}>
                          <List dense>
                            <ListItem disablePadding disableGutters>
                              <ListItemIcon sx={{ justifyContent: "center" }}>
                                <Skeleton
                                  variant="circular"
                                  sx={{
                                    height: {
                                      lg: 60,
                                      md: 50,
                                      sm: 40,
                                      xs: 40,
                                    },
                                    width: {
                                      lg: 60,
                                      md: 50,
                                      sm: 40,
                                      xs: 40,
                                    },
                                    margin: "0 0.5rem",
                                  }}
                                ></Skeleton>
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <>
                                    <Skeleton variant="rectangular" />
                                  </>
                                }
                                secondary={
                                  <>
                                    <Skeleton
                                      variant="rectangular"
                                      sx={{ marginTop: "5px" }}
                                    />
                                  </>
                                }
                              />
                            </ListItem>
                          </List>
                        </Grid>
                        <Grid item lg={3} md={6} sm={6} xs={12}>
                          <List dense>
                            <ListItem disablePadding disableGutters>
                              <ListItemIcon sx={{ justifyContent: "center" }}>
                                <Skeleton
                                  variant="circular"
                                  sx={{
                                    height: {
                                      lg: 60,
                                      md: 50,
                                      sm: 40,
                                      xs: 40,
                                    },
                                    width: {
                                      lg: 60,
                                      md: 50,
                                      sm: 40,
                                      xs: 40,
                                    },
                                    margin: "0 0.5rem",
                                  }}
                                ></Skeleton>
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <>
                                    <Skeleton variant="rectangular" />
                                  </>
                                }
                                secondary={
                                  <>
                                    <Skeleton
                                      variant="rectangular"
                                      sx={{ marginTop: "5px" }}
                                    />
                                  </>
                                }
                              />
                            </ListItem>
                          </List>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Divider
                      sx={{
                        borderBottomWidth: 2.5,
                        bgcolor: "#E5E5E5",
                      }}
                    />

                    <Grid
                      container
                      item
                      sx={{
                        minHeight: "auto",
                        maxHeight: "170px",
                        overflowX: "hidden",
                        overflowY: "auto",
                      }}
                    >
                      <Grid
                        container
                        item
                        // marginBottom={4}
                        direction={{
                          lg: "row",
                          md: "row",
                          sm: "row",
                          xs: "row",
                        }}
                        alignItems="center"
                        justifyContent="space-evenly"
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                      >
                        <Grid item lg={3} md={6} sm={6} xs={12}>
                          <List dense>
                            <ListItem disablePadding disableGutters>
                              <ListItemIcon sx={{ justifyContent: "center" }}>
                                <Skeleton
                                  variant="circular"
                                  sx={{
                                    height: {
                                      lg: 60,
                                      md: 50,
                                      sm: 40,
                                      xs: 40,
                                    },
                                    width: {
                                      lg: 60,
                                      md: 50,
                                      sm: 40,
                                      xs: 40,
                                    },
                                    margin: "0 0.5rem",
                                  }}
                                ></Skeleton>
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <>
                                    <Skeleton variant="rectangular" />
                                  </>
                                }
                                secondary={
                                  <>
                                    <Skeleton
                                      variant="rectangular"
                                      sx={{ marginTop: "5px" }}
                                    />
                                  </>
                                }
                              />
                            </ListItem>
                          </List>
                        </Grid>
                        <Grid item lg={3} md={6} sm={6} xs={12}>
                          <List dense>
                            <ListItem disablePadding disableGutters>
                              <ListItemIcon sx={{ justifyContent: "center" }}>
                                <Skeleton
                                  variant="circular"
                                  sx={{
                                    height: {
                                      lg: 60,
                                      md: 50,
                                      sm: 40,
                                      xs: 40,
                                    },
                                    width: {
                                      lg: 60,
                                      md: 50,
                                      sm: 40,
                                      xs: 40,
                                    },
                                    margin: "0 0.5rem",
                                  }}
                                ></Skeleton>
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <>
                                    <Skeleton variant="rectangular" />
                                  </>
                                }
                                secondary={
                                  <>
                                    <Skeleton
                                      variant="rectangular"
                                      sx={{ marginTop: "5px" }}
                                    />
                                  </>
                                }
                              />
                            </ListItem>
                          </List>
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          md={6}
                          sm={6}
                          xs={12}
                          sx={{ dispaly: { xs: "none", sm: "flex" } }}
                        >
                          <List dense>
                            <ListItem disablePadding disableGutters>
                              <ListItemIcon sx={{ justifyContent: "center" }}>
                                <Skeleton
                                  variant="circular"
                                  sx={{
                                    height: {
                                      lg: 60,
                                      md: 50,
                                      sm: 40,
                                      xs: 40,
                                    },
                                    width: {
                                      lg: 60,
                                      md: 50,
                                      sm: 40,
                                      xs: 40,
                                    },
                                    margin: "0 0.5rem",
                                  }}
                                ></Skeleton>
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <>
                                    <Skeleton variant="rectangular" />
                                  </>
                                }
                                secondary={
                                  <>
                                    <Skeleton
                                      variant="rectangular"
                                      sx={{ marginTop: "5px" }}
                                    />
                                  </>
                                }
                              />
                            </ListItem>
                          </List>
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          md={6}
                          sm={6}
                          xs={12}
                          sx={{ dispaly: { xs: "none", sm: "flex" } }}
                        >
                          <List dense>
                            <ListItem disablePadding disableGutters>
                              <ListItemIcon sx={{ justifyContent: "center" }}>
                                <Skeleton
                                  variant="circular"
                                  sx={{
                                    height: {
                                      lg: 60,
                                      md: 50,
                                      sm: 40,
                                      xs: 40,
                                    },
                                    width: {
                                      lg: 60,
                                      md: 50,
                                      sm: 40,
                                      xs: 40,
                                    },
                                    margin: "0 0.5rem",
                                  }}
                                ></Skeleton>
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  <>
                                    <Skeleton variant="rectangular" />
                                  </>
                                }
                                secondary={
                                  <>
                                    <Skeleton
                                      variant="rectangular"
                                      sx={{ marginTop: "5px" }}
                                    />
                                  </>
                                }
                              />
                            </ListItem>
                          </List>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              )}

              {/* External Links and Skills */}
              <Skills
                disableEnforceFocus
                open={openModelSkill}
                onClose={handleCloseModelSkill}
                data={data}
                getData={getData}
              />

              <Links
                disableEnforceFocus
                open={openModelLink}
                onClose={handleCloseModelLink}
                isEditData={isEditDataLink}
                setIsEditData={setIsEditDataLink}
                index={indexLink}
                setIndex={setIndexLink}
                data={data}
                getData={getData}
              />

              <Grid container justifyContent="center" lg={12}>
                {/* Skills  */}
                <Grid
                  container
                  item
                  alignItems="center"
                  justifyContent={{
                    lg: "flex-start",
                    md: "center",
                    sm: "center",
                    xs: "center",
                  }}
                  lg={6}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <Box
                    minHeight="268.28px"
                    border={1}
                    borderRadius="8px"
                    padding={2}
                    sx={{
                      width: {
                        lg: "98%",
                        md: "70%",
                        sm: "85%",
                        xs: "100%",
                      },
                      marginBottom: {
                        lg: "1rem",
                        md: "1rem",
                        sm: "1rem",
                        xs: "1rem",
                      },
                    }}
                  >
                    <Grid container>
                      {data?.skills.length > 0 ? (
                        <Grid
                          container
                          item
                          sx={{
                            margin: {
                              sm: "0 34px 0 20px",
                              xs: "10px 16px 0 20px",
                            },
                          }}
                        >
                          <Grid
                            sx={{
                              flexGrow: 1,
                            }}
                          >
                            <Typography color="primary" sx={SubTitleHeaders2}>
                              Skills
                            </Typography>
                          </Grid>
                          {checkUser ? (
                            <Grid item>
                              <Button
                                onClick={handleCreateModelSkill}
                                variant="outlined"
                                size="small"
                              >
                                <AddIcon />
                              </Button>
                            </Grid>
                          ) : null}
                        </Grid>
                      ) : null}

                      <Grid
                        container
                        item
                        justifyContent="center"
                        sx={{
                          minHeight: "190px",
                          maxHeight: "190px",
                          overflowX: "hidden",
                          overflowY: "scroll",
                        }}
                      >
                        <Grid item xs={12}>
                          <List sx={{ maxHeight: "20px" }}>
                            {data?.skills.length > 0 ? (
                              data?.skills.map((item, index) => {
                                return (
                                  <ListItem
                                    key={index}
                                    disablePadding
                                    disableGutters
                                    alignItems="flex-start"
                                    sx={{
                                      marginLeft: "20px",
                                    }}
                                  >
                                    <ListItemText>
                                      <Typography sx={{ fontSize: "0.875rem" }}>
                                        {item}
                                      </Typography>
                                    </ListItemText>
                                    {checkUser ? (
                                      <Button
                                        onClick={() => deleteDataSkill(item)}
                                        sx={{ marginRight: "36px" }}
                                      >
                                        <DeleteIcon sx={{ color: "#C80815" }} />
                                      </Button>
                                    ) : null}
                                  </ListItem>
                                );
                              })
                            ) : (
                              <Grid
                                container
                                item
                                alignItems="center"
                                direction="column"
                                marginTop="70px"
                              >
                                {checkUser ? (
                                  <>
                                    <Grid item>
                                      <Button
                                        onClick={handleCreateModelSkill}
                                        variant="outlined"
                                        size="small"
                                      >
                                        <AddIcon />
                                      </Button>
                                    </Grid>
                                    <Grid item>
                                      <Typography
                                        color="primary"
                                        sx={SubTitleHeaders}
                                      >
                                        Add Your Skills Here
                                      </Typography>
                                    </Grid>
                                  </>
                                ) : (
                                  <Grid item>
                                    <Typography
                                      color="primary"
                                      sx={SubTitleHeaders}
                                    >
                                      No Skills Here
                                    </Typography>
                                  </Grid>
                                )}
                              </Grid>
                            )}
                          </List>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>

                {/* External Links */}
                <Grid
                  container
                  item
                  alignItems="center"
                  justifyContent={{
                    lg: "flex-end",
                    md: "center",
                    sm: "center",
                    xs: "center",
                  }}
                  lg={6}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <Box
                    minHeight="268.28px"
                    border={1}
                    borderRadius="8px"
                    padding={2}
                    sx={{
                      width: {
                        lg: "98%",
                        md: "70%",
                        sm: "85%",
                        xs: "100%",
                      },
                      marginBottom: {
                        lg: "1rem",
                        md: "1rem",
                        sm: "1rem",
                        xs: "1rem",
                      },
                    }}
                  >
                    <Grid container>
                      {data?.links.length > 0 ? (
                        <Grid
                          container
                          item
                          sx={{
                            margin: {
                              sm: "0 34px 0 20px",
                              xs: "10px 16px 0 20px",
                            },
                          }}
                        >
                          <Grid
                            sx={{
                              flexGrow: 1,
                            }}
                          >
                            <Typography color="primary" sx={SubTitleHeaders}>
                              External Links
                            </Typography>
                          </Grid>
                          <Grid item>
                            {checkUser ? (
                              <Button
                                onClick={handleCreateModelLink}
                                variant="outlined"
                                size="small"
                              >
                                <AddIcon />
                              </Button>
                            ) : null}
                          </Grid>
                        </Grid>
                      ) : null}

                      <Grid
                        container
                        item
                        justifyContent="center"
                        sx={{
                          minHeight: "200px",
                          maxHeight: "200px",
                          overflowX: "hidden",
                          overflowY: "scroll",
                        }}
                      >
                        {data?.links.length > 0 ? (
                          data?.links.map((item, index) => {
                            return (
                              <Grid item xs={12} key={index}>
                                <List dense>
                                  <ListItem
                                    disablePadding
                                    disableGutters
                                    alignItems="flex-start"
                                  >
                                    <ListItemIcon
                                      sx={{ justifyContent: "center" }}
                                    >
                                      <Avatar
                                        sx={{
                                          height: {
                                            lg: 60,
                                            md: 50,
                                            sm: 40,
                                            xs: 40,
                                          },
                                          width: {
                                            lg: 60,
                                            md: 50,
                                            sm: 40,
                                            xs: 40,
                                          },
                                          margin: "0 0.5rem",
                                        }}
                                      >
                                        <LinkIcon
                                          sx={{
                                            fontSize: {
                                              lg: 40,
                                              md: 40,
                                              sm: 30,
                                              xs: 30,
                                            },
                                          }}
                                        />
                                      </Avatar>
                                    </ListItemIcon>
                                    <ListItemText
                                      sx={{
                                        width: { xs: "200px" },
                                        overflow: "hidden",
                                      }}
                                      primary={item.linkDescription}
                                      secondary={
                                        <a
                                          rel="noopener noreferrer"
                                          target="_blank"
                                          href={item.links}
                                          style={{
                                            textDecoration: "none",
                                            "&:hover": {
                                              backgroundColor: "#fff",
                                              color: "#3c52b2",
                                            },
                                          }}
                                        >
                                          {item.links}
                                        </a>
                                      }
                                    />
                                    {checkUser ? (
                                      <IconButton
                                        aria-haspopup="true"
                                        aria-expanded={
                                          openLink ? "true" : undefined
                                        }
                                        variant="contained"
                                        disableelevation
                                        onClick={(e) => {
                                          setIndexLink(index);
                                          handleClickLink(e);
                                        }}
                                      >
                                        <MoreVertIcon />
                                      </IconButton>
                                    ) : null}

                                    <StyledMenu
                                      id="demo-customized-menu"
                                      MenuListProps={{
                                        "aria-labelledby":
                                          "demo-customized-button",
                                      }}
                                      anchorEl={anchorLink}
                                      open={openLink}
                                      onClose={handleCloseMenuLink}
                                    >
                                      <MenuItem
                                        onClick={editDataLink}
                                        disableRipple
                                      >
                                        <EditIcon />
                                        Edit
                                      </MenuItem>
                                      <MenuItem
                                        onClick={deleteDataLink}
                                        disableRipple
                                      >
                                        <DeleteIcon />
                                        Delete
                                      </MenuItem>
                                    </StyledMenu>
                                  </ListItem>
                                </List>
                              </Grid>
                            );
                          })
                        ) : (
                          <Grid
                            container
                            item
                            alignItems="center"
                            direction="column"
                            marginTop="70px"
                          >
                            {checkUser ? (
                              <>
                                <Grid item>
                                  <Button
                                    onClick={handleCreateModelLink}
                                    variant="outlined"
                                    size="small"
                                  >
                                    <AddIcon />
                                  </Button>
                                </Grid>
                                <Grid item>
                                  <Typography
                                    color="primary"
                                    sx={SubTitleHeaders}
                                  >
                                    Add Your External Links Here
                                  </Typography>
                                </Grid>
                              </>
                            ) : (
                              <Grid item>
                                <Typography
                                  color="primary"
                                  sx={SubTitleHeaders}
                                >
                                  No External Links Here
                                </Typography>
                              </Grid>
                            )}
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>

              <StartupTypes
                disableEnforceFocus
                open={openModelStartupTypes}
                onClose={handleCloseModelStartupTypes}
                data={data}
                getData={getData}
              />
              {/* Startup Types */}
              <Grid container justifyContent="center" marginBottom={4}>
                {/* Startup Types  */}
                <Grid
                  container
                  item
                  alignItems="center"
                  justifyContent="center"
                  lg={5}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <Box
                    minHeight="268.28px"
                    border={1}
                    borderRadius="8px"
                    padding={2}
                    sx={{
                      width: {
                        lg: "98%",
                        md: "70%",
                        sm: "85%",
                        xs: "100%",
                      },
                      marginBottom: {
                        lg: "1rem",
                        md: "1rem",
                        sm: "1rem",
                        xs: "1rem",
                      },
                    }}
                  >
                    <Grid container>
                      {data?.startupTypes.length > 0 ? (
                        <Grid
                          container
                          item
                          sx={{
                            margin: {
                              sm: "0 34px 0 20px",
                              xs: "10px 16px 0 20px",
                            },
                          }}
                        >
                          <Grid
                            sx={{
                              flexGrow: 1,
                            }}
                          >
                            <Typography color="primary" sx={SubTitleHeaders2}>
                              Startup Types
                            </Typography>
                          </Grid>
                          <Grid item>
                            {checkUser ? (
                              <Button
                                onClick={handleCreateModelStartupTypes}
                                variant="outlined"
                                size="small"
                              >
                                <AddIcon />
                              </Button>
                            ) : null}
                          </Grid>
                        </Grid>
                      ) : null}

                      <Grid
                        container
                        item
                        justifyContent="center"
                        sx={{
                          minHeight: "200px",
                          maxHeight: "200px",
                          overflowX: "hidden",
                          overflowY: "scroll",
                        }}
                      >
                        <Grid item xs={12}>
                          <List sx={{ maxHeight: "20px" }}>
                            {data?.startupTypes.length > 0 ? (
                              data?.startupTypes.map((item, index) => {
                                return (
                                  <ListItem
                                    key={index}
                                    disablePadding
                                    disableGutters
                                    alignItems="flex-start"
                                    sx={{ marginLeft: "20px" }}
                                  >
                                    <ListItemText>
                                      <Typography sx={{ fontSize: "0.875rem" }}>
                                        {item}
                                      </Typography>
                                    </ListItemText>
                                    {checkUser ? (
                                      <Button
                                        onClick={() =>
                                          deleteDataStartupTypes(item)
                                        }
                                        sx={{ marginRight: "37px" }}
                                      >
                                        <DeleteIcon sx={{ color: "#C80815" }} />
                                      </Button>
                                    ) : null}
                                  </ListItem>
                                );
                              })
                            ) : (
                              <Grid
                                container
                                alignItems="center"
                                direction="column"
                                marginTop="80px"
                              >
                                {checkUser ? (
                                  <>
                                    <Grid item>
                                      <Button
                                        onClick={handleCreateModelStartupTypes}
                                        variant="outlined"
                                        size="small"
                                      >
                                        <AddIcon />
                                      </Button>
                                    </Grid>
                                    <Grid item>
                                      <Typography
                                        color="primary"
                                        sx={SubTitleHeaders}
                                      >
                                        Add Your Startup Types Here
                                      </Typography>
                                    </Grid>
                                  </>
                                ) : (
                                  <Grid item>
                                    <Typography
                                      color="primary"
                                      sx={SubTitleHeaders}
                                    >
                                      No Startup Types Here
                                    </Typography>
                                  </Grid>
                                )}
                              </Grid>
                            )}
                          </List>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid
              item
              container
              alignItems="center"
              justifyContent="center"
              height="100vh"
            >
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Profile;
