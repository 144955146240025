import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../../Components/navbar/Navbar";
import Hero from "../../Components/hero/Hero";
import Features from "../../Components/features/Features";
import Aboutus from "../../Components/aboutus/Aboutus";
import Footer from "../../Components/footer/Footer";

import { features } from "./data";

const Landing = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.state?.scrollTo) {
      const { scrollTo } = location.state;
      document.getElementById(scrollTo)?.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  return (
    <>
      <div className="header-2 primary" id="top">
        <Navbar
          navClass="navbar-light zindex-10"
          isSticky={false}
          fixedWidth
          hideSearch
          buttonClass="btn-primary btn-sm"
        />
        <div>
          <Hero />
        </div>
      </div>
      <div id="aboutus">
        <Aboutus />
      </div>
      <div id="features">
        <Features
          features={features}
          containerClass="position-relative overflow-hidden pt-lg-6 py-4 pb-lg-7"
        />
      </div>


      <Footer />
    </>
  );
};

export default Landing;
