import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "../../axios";
import { ErrorNotification, SuccessNotification } from "../../notifications/notifications";

const url = process.env.REACT_APP_BE_URL;

const DuplicateJobModal = ({ show, handleClose, updatedJob, fetchData }) => {
  const [jobTitle, setJobTitle] = useState("");
  const [submissionLoading, setSubmissionLoading] = useState(false);

  // State to track validation errors
  const [validationErrors, setValidationErrors] = useState({
    title: "",
  });

  // Reset the job title when the modal opens or updatedJob changes
  useEffect(() => {
    if (updatedJob) {
      setJobTitle(updatedJob.title || "");
      setValidationErrors({ title: "" }); // Reset validation errors
    }
  }, [updatedJob]);

  // Function to validate form fields
  const validateForm = () => {
    const errors = {};

    // Check if the job title is empty
    if (!jobTitle.trim()) {
      errors.title = "Job title is required";
    }

    setValidationErrors(errors);

    // If no errors, return true
    return Object.keys(errors).length === 0;
  };

  // Handle the duplication of the job
  const handleDuplicate = async () => {
    setSubmissionLoading(true);
    if (!validateForm()) {
      return;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token}`,
      },
    };

    try {
      const jobData = {
        title: jobTitle,
        description: updatedJob?.description,
        jobType: updatedJob?.jobType,
        projectID: updatedJob.projectID?._id,
        qualifications: updatedJob.qualifications,
        requirements: updatedJob.requirements,
        responsibilities: updatedJob.responsibilities,
        skills: updatedJob.skills,
        status: "inactive", // Set the status to inactive for duplication
        timeAvailability: updatedJob.timeAvailability,
      };

      const res = await axios.post(`${url}/job`, jobData, config);

      if (res.status === 200) {
        SuccessNotification("Job duplicated successfully!");
        fetchData(); // Refresh data to reflect changes
        handleClose(); // Close the modal after duplication
      }
    } catch (error) {
      ErrorNotification(error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed");

    } finally {
      setSubmissionLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Duplicate Job</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {updatedJob && (
          <>
            <p>
              What is the name of the new job? This should be different from the original job
            </p>
            <Form>
              <Form.Group controlId="formJobTitle">
                <Form.Label>Job Title</Form.Label>
                <Form.Control
                  type="text"
                  value={jobTitle}
                  onChange={(e) => setJobTitle(e.target.value)}
                  placeholder="Enter new job title"
                  isInvalid={!!validationErrors.title} // Mark input as invalid if there's an error
                />
                <Form.Control.Feedback type="invalid">
                  {validationErrors.title}
                </Form.Control.Feedback>
              </Form.Group>
            </Form>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleDuplicate}
          //disabled={!jobTitle.trim()}
          disabled={submissionLoading}
        >
          {submissionLoading ? (
            <span className="spinner-border spinner-border-sm" />
          ) : (
            "Duplicate"
          )}

        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DuplicateJobModal;
