import React, { useState } from "react";
import {
  Grid,
  Button,
  TextField,
  Modal,
  Box,
  Snackbar,
  Alert,
  Select,
  FormControl,
  InputLabel,
  Input,
  IconButton,
  Typography,
  FormLabel,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "../axios";
import MenuItem from "@mui/material/MenuItem";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CancelIcon from "@mui/icons-material/Cancel";

const url = process.env.REACT_APP_BE_URL;

const CreateOrganization1Schema = Yup.object().shape({
  organizationName: Yup.string()
    .required("Organization Name is required")
    .min(3, "Too short")
    .max(40, "Too long"),
  industry: Yup.string().required("Industry is required"),
  description: Yup.string().required("Project Description is required"),
  website: Yup.string().url("Invalid URL").required("Website is required"),
});

const modalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%", // Adjust the width as needed
  maxWidth: "600px", // Set the max width
  maxHeight: "80vh", // Limit height to viewport height
  overflowY: "auto", // Enable vertical scrolling
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};

const TitleStyles = {
  color: "#000",
  fontWeight: "800",
  fontSize: "1.5rem",
  marginBottom: "1rem",
  textAlign: "center",
};

const fieldStyles = {
  width: "100%",
  marginBottom: "1rem",
};

const formStyles = {
  display: "flex",
  flexDirection: "column",
};

const CustomizedSelectForFormik = ({ children, form, field }) => {
  const { name, value } = field;
  const { setFieldValue } = form;

  return (
    <Select
      label="Industry"
      name={name}
      value={value}
      onChange={(e) => {
        setFieldValue(name, e.target.value);
      }}
      fullWidth
    >
      {children}
    </Select>
  );
};

function EditOrganizationDetailsModal({
  open,
  onClose,
  organization,
  getData,
  initialValues1,
}) {
  const [check, setCheck] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(true);
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [showUpload, setShowUpload] = useState(false);

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setSelectedLogo(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  function handleClose() {
    setOpenSnackbar(false);
    setAlert({
      ...alert,
      showAlert: false,
      severity: "",
      message: "",
    });
  }

  const handleToggleUpload = () => {
    setShowUpload(!showUpload);
  };

  const handleCancelUpload = () => {
    setShowUpload(false);
    setSelectedLogo(null);
  };

  const handleSubmit1 = async (values) => {
    setCheck(true);
    const config = {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("userInfo")).token
        }`,
      },
    };

    try {
      let payload = {
        name: values.organizationName,
        industry: values.industry,
        description: values.description,
        website: values.website,
      };

      if (selectedLogo) {
        payload.logo = selectedLogo; // Add the logo to the payload if selected
      }

      const res = await axios.put(
        `${url}/organization/${organization._id}`,
        payload,
        config
      );

      onClose();
      getData();

      if (res.status === 200) {
        setAlert({
          showAlert: true,
          severity: "success",
          message: `${
            res.data?.message
              ? res.data?.message
              : res.data
              ? res.data
              : "Organization details are updated successfully"
          }`,
        });

        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "success",
            message: "",
          });
        }, 16000);
      } else {
        setAlert({
          showAlert: true,
          severity: "error",
          message: `${res.data?.message ? res.data.message : "Action Failed"}`,
        });
      }
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${
          error.response.data.message
            ? error.response.data.message
            : error.response.data
            ? error.response.data
            : "Action Failed"
        }`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);
      onClose();
    }
    setCheck(false);
  };

  return (
    <>
      <Snackbar
        sx={{ mt: "8vh" }}
        open={alert.showAlert}
        autoHideDuration={15000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={alert.severity}
          sx={{
            fontSize: "16px",
          }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
      <Modal open={open} onClose={onClose}>
        <Box sx={modalStyles}>
          <Grid container direction="column" spacing={3}>
            <Grid item xs={12} style={TitleStyles}>
              Organization Details
            </Grid>
            <Grid item xs={12}>
              <Formik
                initialValues={initialValues1}
                onSubmit={handleSubmit1}
                validationSchema={CreateOrganization1Schema}
              >
                {({ errors, isValid, touched, dirty }) => (
                  <Form style={formStyles}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Field
                          sx={fieldStyles}
                          name="organizationName"
                          label="Organization Name"
                          as={TextField}
                          variant="outlined"
                          required
                          size="small"
                          margin="dense"
                          fullWidth
                          error={
                            Boolean(errors.organizationName) &&
                            Boolean(touched.organizationName)
                          }
                          helperText={
                            Boolean(touched.organizationName) &&
                            errors.organizationName
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth required variant="outlined">
                          <InputLabel id="industry-select-label">
                            Industry
                          </InputLabel>
                          <Field
                            component={CustomizedSelectForFormik}
                            name="industry"
                            labelId="industry-select-label"
                            as={Select}
                            error={
                              Boolean(errors.industry) &&
                              Boolean(touched.industry)
                            }
                          >
                            <MenuItem value="Consumer Discretionary">
                              Consumer Discretionary
                            </MenuItem>
                            <MenuItem value="Consumer Staples">
                              Consumer Staples
                            </MenuItem>
                            <MenuItem value="Construction">
                              Construction
                            </MenuItem>
                            <MenuItem value="Energy">Energy</MenuItem>
                            <MenuItem value="Materials">Materials</MenuItem>
                            <MenuItem value="Industrials">Industrials</MenuItem>
                            <MenuItem value="Healthcare">Healthcare</MenuItem>
                            <MenuItem value="Financials">Financials</MenuItem>
                            <MenuItem value="Information Technology">
                              Information Technology
                            </MenuItem>
                            <MenuItem value="Real Estate">Real Estate</MenuItem>
                            <MenuItem value="Communication Services">
                              Communication Services
                            </MenuItem>
                            <MenuItem value="Utilities">Utilities</MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                          </Field>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          sx={fieldStyles}
                          name="description"
                          label="Project Description (Min. 100 characters)"
                          as={TextField}
                          variant="outlined"
                          size="small"
                          margin="dense"
                          multiline
                          rows={4}
                          fullWidth
                          error={
                            Boolean(errors.description) &&
                            Boolean(touched.description)
                          }
                          helperText={
                            Boolean(touched.description) && errors.description
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          sx={fieldStyles}
                          name="website"
                          label="Website"
                          as={TextField}
                          variant="outlined"
                          size="small"
                          margin="dense"
                          fullWidth
                          error={
                            Boolean(errors.website) && Boolean(touched.website)
                          }
                          helperText={
                            Boolean(touched.website) && errors.website
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel htmlFor="image">Logo</InputLabel>
                        <FormControl
                          variant="outlined"
                          fullWidth
                          margin="normal"
                        >
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            mb={2}
                          >
                            <FormLabel
                              component="legend"
                              sx={{
                                fontWeight: "bold",
                                fontSize: "16px",
                                color: "text.primary",
                              }}
                            >
                              Organization Logo
                            </FormLabel>
                            <Button
                              variant="contained"
                              color={showUpload ? "secondary" : "primary"}
                              startIcon={
                                showUpload ? (
                                  <CancelIcon />
                                ) : (
                                  <CloudUploadIcon />
                                )
                              }
                              onClick={handleToggleUpload}
                              sx={{
                                textTransform: "none",
                                borderRadius: "20px",
                              }}
                            >
                              {showUpload
                                ? "Cancel"
                                : initialValues1.logo
                                ? "Change Logo"
                                : "Upload Logo"}
                            </Button>
                          </Box>
                          {showUpload && (
                            <Box>
                              <Box display="flex" alignItems="center" mb={2}>
                                <TextField
                                  type="file"
                                  id="logo"
                                  name="logo"
                                  onChange={handleImageChange}
                                  inputProps={{ accept: "image/*" }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  sx={{
                                    display: "none",
                                  }}
                                />
                                <label htmlFor="logo">
                                  <Button
                                    variant="outlined"
                                    component="span"
                                    startIcon={<CloudUploadIcon />}
                                    sx={{
                                      textTransform: "none",
                                      borderRadius: "20px",
                                      mr: 2,
                                    }}
                                  >
                                    Choose File
                                  </Button>
                                </label>
                              </Box>
                              {(initialValues1.logo || selectedLogo) && (
                                <>
                                  <IconButton
                                    onClick={handleCancelUpload}
                                    color="error"
                                  >
                                    <CancelIcon />
                                  </IconButton>
                                  <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                  >
                                    <Typography
                                      variant="body2"
                                      sx={{ mb: 1, color: "text.secondary" }}
                                    >
                                      Logo Preview:
                                    </Typography>
                                    <img
                                      src={
                                        !selectedLogo
                                          ? initialValues1.logo.url
                                          : selectedLogo
                                      }
                                      alt="Logo Preview"
                                      style={{
                                        maxWidth: "150px",
                                        maxHeight: "150px",
                                        borderRadius: "8px",
                                        boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
                                      }}
                                    />
                                  </Box>
                                </>
                              )}
                            </Box>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          sx={{
                            borderRadius: "8px",
                            fontSize: "1rem",
                            fontWeight: "700",
                            height: "2.5rem",
                            marginTop: "1rem",
                          }}
                          color="primary"
                          fullWidth
                          variant="contained"
                          disabled={!selectedLogo && (!dirty || !isValid)}
                          type="submit"
                          size="large"
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default EditOrganizationDetailsModal;
