import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Modal,
  Box,
  Snackbar,
  Alert,
  Typography,
  Checkbox
} from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';
import LoadingButton from "@mui/lab/LoadingButton";
import axiosInstance from "../../../axios";
import Drawer from "../../../Components/Drawer";

import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AgreementStatement from "./AgreementStatement"
import { useNavigate, useParams } from "react-router-dom";

import pdfMake from "pdfmake/build/pdfmake";
import htmlToPdfmake from "html-to-pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const url = process.env.REACT_APP_BE_URL;

const TitleStyles = {
  backgroundColor: "#fff",
  color: "#000",
  fontWeight: "800",
  textAlign: "center"
};

const fieldStyles = {
  width: {
    lg: 570,
    md: 430,
    sm: 398,
    xs: "100%",
  },
  marginBottom: "10px",
};


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    lg: 700,
    md: 540,
    sm: 540,
    xs: "89%",
  },
  bgcolor: "#fff",
  boxShadow: 30,
  borderRadius: "8px",
  padding: "10px 10px 30px 10px",
  border: "0px",
};

function NdaStep2() {
  const navigate = useNavigate()
  const [afterEmailsent, setAfterEmailsent] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState();
  const [nda, setNda] = useState(null)
  const { ndaId } = useParams();
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  function handleClose() {
    setAlert({
      ...alert,
      showAlert: false,
      severity: "",
      message: "",
    });
  }

  var today = new Date();
  const [initialValues, setInitialValues] = useState({
    name: "",
    date: today,
    tick: ""
  });


  //validate and set name
  const [nameError, setNameError] = useState(false);

  const setName = (nameValue) => {

    setInitialValues(oldValue => ({
      ...oldValue,
      name: nameValue.target.value,
    })
    )

    if (nameValue.target.value.length === 0) {
      setNameError(true)

    } else {
      setNameError(false)
    }

  }

  //validate and set date
  const [dateError, setDateError] = useState(false);

  const dateSet = (dateValue) => {
    setInitialValues(oldValue => ({
      ...oldValue,
      date: dateValue,
    })
    )

    //date validation
    if (dateValue.setHours(0, 0, 0, 0) !== today.setHours(0, 0, 0, 0)) {
      setDateError(true)
    } else {
      setDateError(false)
    }
  }

  //pdf genaration and nda put
  const [storePdfBase64, setStorePdfBase64] = useState();
  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  const pdfData = ` <b style="text-align:center;" >NON-DISCLOSURE AGREEMENT</b>

  <div style=" font-size:16px;  line-height:1.5; "> 
  <p>
    This Mutual Nondisclosure Agreement (this “Agreement”), effective ${convert(initialValues?.date)},
    is entered into by and between TinT a Delaware Limited Liability Company (“Company”) and
    “${initialValues?.name}” (each herein referred to individually as a “Party,” or collectively as the “Parties”).
    In consideration of the covenants and conditions contained herein, the Parties hereby agree to the following:
  </p> 

  <p>
    1.Purpose<br></br>
    The Parties wish to explore a business opportunity of mutual (the “Opportunity”),
    and in connection with the Opportunity, each Party has disclosed,
    and may further disclose certain confidential technical and business information
    (in such capacity, a Party disclosing the information, the “Discloser”) to the other Party
    (in such capacity, a Party receiving the information, the “Recipient”),
    that Discloser desires Recipient to treat as confidential.
  </p> 
  <p>
    2.Confidential Information
    A.Definition.
    “Confidential Information” means:<br></br>
    (i) any information (including any and all combinations of individual items of information) disclosed
    (directly or indirectly) by Discloser to Recipient pursuant to this Agreement that is in written, graphic,
    machine readable or other tangible form (including, without limitation, research, product plans, products,
    services, equipment, customers, markets, software, inventions, discoveries, ideas, processes, designs, drawings,
    formulations, specifications, product configuration information, marketing and finance documents, prototypes,
    samples, data sets, and equipment) and is marked “Confidential,” “Proprietary” or in some other manner to indicate
    its confidential nature;
    (ii) oral information disclosed (directly or indirectly) by Discloser to Recipient
    pursuant to this Agreement; provided that such information is designated as confidential at the time of its
    initial disclosure and reduced to a written summary by Discloser that is marked in a manner to indicate its
    confidential nature and delivered to Recipient within thirty (30) days after its initial disclosure; and
    (iii) information otherwise reasonably expected to be treated in a confidential manner under the circumstances of
    disclosure under this Agreement or by the nature of the information itself. Confidential Information may include
    information of a third party that is in the possession of Discloser and is disclosed to Recipient under this Agreement.
    B.Exceptions.
    Confidential Information shall not, however, include any information that:
    (i) was publicly known or made generally available without a duty of confidentiality prior to the
    time of disclosure by Discloser to Recipient;
    (ii) becomes publicly known or made generally available without a duty of confidentiality after
    disclosure by Discloser to Recipient through no wrongful action or inaction of Recipient;
    (iii) is in the rightful possession of Recipient without confidentiality obligations at the time
    of disclosure by Discloser to Recipient as shown by Recipient’s then-contemporaneous written files
    and records kept in the ordinary course of business;
    (iv) is obtained by Recipient from a third party without an accompanying duty of confidentiality and
    without a breach of such third party’s obligations of confidentiality; or
    (v) is independently developed by Recipient without use of or reference to Discloser’s Confidential
    Information, as shown by written records and other competent evidence prepared contemporaneously with
    such independent development; provided that any combination of individual items of information shall
    not be deemed to be within any of the foregoing exceptions merely because one or more of the individual
    items are within such exception, unless the combination as a whole is within such exception.

    C.Compelled Disclosure.<br></br>
    If Recipient becomes legally compelled to disclose any Confidential Information,
    other than pursuant to a confidentiality agreement, Recipient will provide Discloser prompt written notice,
    if legally permissible, and will use its best efforts to assist Discloser in seeking a protective order or
    another appropriate remedy. If Discloser waives Recipient’s compliance with this Agreement or fails to obtain
    a protective order or other appropriate remedy, Recipient will furnish only that portion of the Confidential
    Information that is legally required to be disclosed; provided that any Confidential Information so disclosed
    shall maintain its confidentiality protection for all purposes other than such legally compelled disclosure.
  </p> 
  <p>
    3.Non-use and Non-disclosure<br></br>
    Recipient shall not use any Confidential Information of Discloser for any purpose except to evaluate and
    engage in discussions concerning the Opportunity. Recipient shall not disclose any Confidential Information
    of Discloser to third parties or to Recipient’s employees, except that, subject to Section 4 below, Recipient
    may disclose Discloser’s Confidential Information to those employees of Recipient who are required to have such
    information in order to evaluate or engage in discussions concerning the Opportunity. Recipient shall not
    reverse engineer, disassemble, or decompile any prototypes, software, samples, or other tangible objects that
    embody Discloser’s Confidential Information and that are provided to Recipient under this Agreement.
  </p> 
  <p>
    4.Maintenance of Confidentiality<br></br>
    Recipient shall take reasonable measures to protect the secrecy of and avoid disclosure and unauthorized use of
    the Confidential Information of Discloser. Without limiting the foregoing, Recipient shall take at least those
    measures that it employs to protect its own confidential information of a similar nature and shall ensure that
    its employees who have access to Confidential Information of Discloser have signed a non-use and non-disclosure
    agreement in content at least as protective of Discloser and its Confidential Information as the provisions of this
    Agreement, prior to any disclosure of Discloser’s Confidential Information to such employees.
    Recipient shall reproduce Discloser’s proprietary rights notices on any such authorized copies in the same manner
    in which such notices were set forth in or on the original. Recipient shall promptly notify Discloser of any
    unauthorized use or disclosure, or suspected unauthorized use or disclosure, of Discloser’s Confidential Information
    of which Recipient becomes aware.
  </p> 
  <p>
    5.No Obligation<br></br>
    Nothing in this Agreement shall obligate either Party to proceed with any transaction between them,
    and each Party reserves the right, in its sole discretion, to terminate the discussions contemplated by this
    Agreement concerning the Opportunity. Nothing in this Agreement shall be construed to restrict either Party’s
    use or disclosure of its own Confidential Information.
  </p> 
  <p>
    6.No Warranty<br></br>
    ALL CONFIDENTIAL INFORMATION IS PROVIDED “AS IS.” NEITHER PARTY MAKES ANY WARRANTIES, EXPRESS, IMPLIED OR OTHERWISE,
    REGARDING THE ACCURACY, COMPLETENESS OR PERFORMANCE OF ANY CONFIDENTIAL INFORMATION, OR WITH RESPECT TO
    NON-INFRINGEMENT OR OTHER VIOLATION OF ANY INTELLECTUAL PROPERTY RIGHTS OF A THIRD PARTY OR OF RECIPIENT.
  </p> 
  <p>
    7.Return of Materials<br></br>
    All documents and other tangible objects containing or representing Confidential Information that have been disclosed
    by Discloser to Recipient, and all copies or extracts thereof or notes derived therefrom that are in the possession
    of Recipient, shall be and remain the property of Discloser and shall be promptly returned to Discloser or destroyed
    (with proof of such destruction), each upon Discloser’s written request.
  </p> 
  <p>
    8.No License<br></br>
    Nothing in this Agreement is intended to grant any rights to Recipient under any patent, mask work right or copyright
    of Discloser, nor shall this Agreement grant Recipient any rights in or to the Confidential Information of Discloser
    except as expressly set forth in this Agreement.
  </p> 
  <p>
    9.Export Restrictions<br></br>
    Any software and other technical information disclosed under this Agreement may be subject to restrictions and
    controls imposed by the Export Administration Act, Export Administration Regulations and other laws and regulations
    of the United States and any other applicable government or jurisdiction, as enacted from time to time (the “Acts”).
    The Parties shall comply with all restrictions and controls imposed by the Acts.
  </p> 
  <p>
    10.Term<br></br>
    The obligations of Recipient under this Agreement shall survive, with respect to any particular Confidential
    Information of Discloser, until all Confidential Information of Discloser disclosed hereunder becomes publicly
    known or made generally available through no action or inaction of the Recipient.
  </p> 
  <p>
    11.Remedies<br></br>
    Recipient agrees that any violation or threatened violation of this Agreement may cause irreparable injury to Discloser,
    entitling Discloser to seek injunctive relief in addition to all legal remedies.
  </p> 
  <p>
    12.Miscellaneous<br></br>
    This Agreement shall bind and inure to the benefit of the Parties and their respective successors and permitted assigns.
    Neither Party may assign or otherwise transfer this Agreement without the prior written consent of the other Party;
    except that either Party may assign this Agreement without consent in connection with a merger, reorganization,
    consolidation, change of control, or sale of all or substantially all of the assets to which this Agreement pertains;
    provided that the assigning Party provides prompt written notice to the other Party of any such permitted assignment.
    Any assignment or transfer of this Agreement in violation of the foregoing shall be null and void.
    This Agreement will be interpreted and construed in accordance with the laws of the State of Virginia,
    without regard to conflict of law principles. Each Party hereby represents and warrants that the persons executing
    this Agreement on its behalf have express authority to do so, and, in so doing, to bind such Party thereto.
    This Agreement contains the entire agreement between the Parties with respect to the Opportunity and supersedes
    all prior written and oral agreements between the Parties regarding the Opportunity. Recipient shall not have any
    obligation, express or implied by law, with respect to trade secret or proprietary information of Discloser disclosed
    under this Agreement except as set forth herein. If a court or other body of competent jurisdiction finds any provision
    of this Agreement, or portion thereof, to be invalid or unenforceable, such provision will be enforced to the maximum
    extent permissible to affect the intent of the Parties, and the remainder of this Agreement will continue in full force
    and effect. No provision of this Agreement may be waived except by a writing executed by the Party against whom the
    waiver is to be effective. A Party’s failure to enforce any provision of this Agreement shall neither be construed as
    a waiver of the provision nor prevent the Party from enforcing any other provision of this Agreement.
    No provision of this Agreement may be amended or otherwise modified except by a writing signed by the Parties to this
    Agreement. The Parties may execute this Agreement in counterparts, each of which shall be deemed an original,
    but all of which together constitute one and the same agreement. This Agreement may be delivered by facsimile
    transmission, and facsimile copies of executed signature pages shall be binding as originals.
  </p> 
  <p>
    13.Disputes<br></br>
    All disputes arising out of this Agreement will be subject to the exclusive jurisdiction and venue of the state courts
    and federal courts located in Delaware, and each Party hereby consents to the personal jurisdiction thereof.<br></br>

    IN WITNESS WHEREOF, the Parties by their duly authorized representatives have executed this Agreement as of the Effective Date.
  <br></br>
    e-signature statement
  </p>
</div >`

  const getNda = async () => {

    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };

    try {

      const { status, data } = await axiosInstance.get(`${url}/nda/${ndaId}`, config)
      setNda(data)
    } catch (error) {
      console.log(error)
    }


  }

  useEffect(() => {
    if (nda) {
      navigate(`/jobDetails/${nda.jobId}`)
    }
  }, [nda])


  const updateNdaStatus = async () => {

    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };
    setLoadingBtn(true);

    try {
      var docPayload = {
        document: storePdfBase64,
        status: "Signed"
      }

      const res = await axiosInstance.put(
        `${url}/nda/${ndaId}`,
        docPayload,
        config
      );

      if (res.status === 200) {
        getNda()
        setAlert({
          showAlert: true,
          severity: "success",
          message: `${res.data?.message ? res.data?.message : res.data ? res.data : "success"}`,
        });
        setLoadingBtn(false);
        setAfterEmailsent({

          status: true,

          message: `${res.data?.message ?
            res.data.message
            : "success"
            }`
        })

      }

    } catch (error) {
      setLoadingBtn(false);
      setAfterEmailsent({

        status: false,

        message: `${error.response.data ? error.response.data : error.message}`
      });
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action failed"}`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);
    }
  };

  const genPdf = () => {
    //start pdf genarate
    const val = htmlToPdfmake(pdfData)

    const htmalData = { content: val }

    pdfMake.createPdf(htmalData).getDataUrl((data) => {
      setStorePdfBase64(data);

    });
    //end pdf genarate  
  }

  useEffect(() => {
    if (storePdfBase64) {
      updateNdaStatus()
    }
    // eslint-disable-next-line
  }, [storePdfBase64])


  return (
    <>
      <Drawer />

      <Grid container>

        <Grid container item
          lg={1}
          md={1.5}
          sm={1.8}
          xs={1}
        >
          {/* <Sidebar /> */}
        </Grid>

        <Snackbar
          sx={{ mt: "8vh" }}
          open={alert.showAlert}
          autoHideDuration={15000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={handleClose}
            severity={alert.severity}
            sx={{
              fontSize: "16px",
            }}
          >
            {alert.message}
          </Alert>
        </Snackbar>

        <Modal
          open={true}
        // onClose={false}
        >
          <Box sx={style}>
            <Grid container justifyContent="center">
              <Grid item
                lg={11}
                md={10}
                sm={10}
                xs={10}
              >
                <Typography
                  style={TitleStyles}
                  sx={{
                    fontSize: {
                      lg: "2rem",
                      md: "2rem",
                      sm: "1.5rem",
                      xs: "1.4rem",
                    },
                    marginTop: {
                      lg: "2rem",
                      md: "2rem",
                      sm: "2rem",
                      xs: "1rem",
                    },
                    textTransform: "uppercase",
                  }}
                >

                  {afterEmailsent.status ? "Success!" : "Non Disclosure Agreement"}

                </Typography>

                {afterEmailsent.status ?
                  <Typography
                    sx={{
                      textAlign: "center"
                    }}
                  >

                    {afterEmailsent.message}
                  </Typography>

                  :
                  <Typography
                    paragraph
                    sx={{
                      marginTop: "1rem",
                      wordBreak: "break-word",
                      fontSize: {
                        lg: "1rem",
                        md: "1rem",
                        sm: "0.9rem",
                        xs: "0.89rem",
                      },
                      height: {
                        lg: "15rem",
                        md: "15rem",
                        sm: "15rem",
                        xs: "15rem",
                      },
                      overflowY: "auto",
                      borderStyle: "solid",
                      borderWidth: "0.1rem",
                      borderColor: "#c1c1c1",
                      padding: "1rem",
                    }}
                  >
                    <AgreementStatement
                      fillDate={initialValues?.date}
                      partyName={initialValues?.name}
                    />
                  </Typography>}

              </Grid>
              {!afterEmailsent.status &&
                <Grid container item
                  lg={10}
                  md={10}
                  sm={10}
                  xs={10}
                  direction="column" alignItems="center">

                  <Grid item>
                    <FormControlLabel
                      control={<Checkbox

                        value={initialValues.tick}
                        onClick={(tickValue) => {
                          setInitialValues(oldValue => ({
                            ...oldValue,
                            tick: tickValue.target.checked,
                          })
                          )
                        }}

                      />}
                      label="I agree to all the conditions and agreements"
                      sx={{
                        marginBottom: "1rem",
                        wordBreak: "break-word",
                      }}
                    />
                  </Grid>

                  <Grid item>
                    <TextField
                      error={nameError}
                      helperText={nameError && "Name must not be empty"}
                      required
                      sx={fieldStyles}
                      name="name"
                      label="Name"
                      variant="outlined"
                      type="text"
                      value={initialValues.name}
                      onChange={(nameValue) => setName(nameValue)}
                      size="small"
                      margin="dense">
                    </TextField>
                  </Grid>

                  <Grid item>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        error={dateError}
                        label="Date"
                        inputFormat="MM/dd/yyyy"
                        name="date"
                        value={initialValues.date}
                        onChange={(e) => dateSet(e)}
                        renderInput={(params) =>
                          <TextField
                            helperText={dateError && "Date must be current date"}
                            required
                            name="date"
                            sx={{
                              width: {
                                lg: 570,
                                md: 430,
                                sm: 430,
                                xs: "100%",
                              },
                            }}
                            {...params}

                            error={dateError}
                          />}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item>
                    <LoadingButton
                      sx={{
                        borderRadius: "8px",
                        fontSize: "1rem",
                        fontWeight: "700",
                        height: "2.5rem",
                        marginTop: "1rem",
                        textTransform: "capitalize"
                      }}
                      color="primary"
                      fullWidth
                      variant="contained"
                      disabled={!initialValues.tick || !initialValues.name || !initialValues.date}
                      loading={loadingBtn}
                      type="submit"
                      size="large"
                      onClick={genPdf}
                    >
                      Confirm
                    </LoadingButton>
                  </Grid>
                </Grid>}

            </Grid>
          </Box>
        </Modal>
      </Grid>

    </>
  );
}

export default NdaStep2;
