import axios from "../../axios";
import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { ErrorNotification, SuccessNotification } from "../../notifications/notifications";

const url = process.env.REACT_APP_BE_URL;

const EditProjectModal = ({
  show,
  handleClose,
  project,
  projId,
  fetchData,
}) => {
  // State to manage form fields
  const [projName, setProjName] = useState("");
  const [industry, setIndustry] = useState("");
  const [description, setDescription] = useState("");
  const [funding, setFunding] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [submissionLoading, setSubmissionLoading] = useState(false);


  // State to manage validation
  const [errors, setErrors] = useState({});

  // Initialize state with the project prop when modal opens
  useEffect(() => {
    if (project) {
      setProjName(project.name || "");
      setIndustry(project.industry || "");
      setDescription(project.description || "");
      setFunding(project.funding || 0);
      setFilePreview(project.iPSensitiveDetails?.map((doc) => doc.url) || []);
    }
  }, [project]);

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setSelectedFile(reader.result);
        setFilePreview(reader.result);
        setFileName(file.name);
      };

      reader.readAsDataURL(file);
    }
  };

  // Handle PDF removal
  const handleRemoveFile = () => {
    setSelectedFile(null);
    setFilePreview(null);
  };

  // Validate form fields
  const validateForm = () => {
    const newErrors = {};

    if (!projName.trim()) newErrors.projName = "Project name is required";
    if (!industry) newErrors.industry = "Industry is required";
    if (!description.trim()) newErrors.description = "Description is required";

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async () => {
    setSubmissionLoading(true);
    if (!validateForm()) return;

    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token}`,
      },
    };

    try {
      // Construct payload with updated fields
      let payload = {
        name: projName,
        industry,
        description,
        funding,
      };

      // Check if selectedFile has files and update payload
      if (selectedFile) {
        payload.iPSensitiveDetails = [selectedFile];
        payload.public_id = [fileName];
      }

      // Make API request to update project
      const res = await axios.put(`${url}/project/${projId}`, payload, config);

      if (res.status === 200) {
        SuccessNotification(res.data?.message ? res.data?.message : res.data ? res.data : "Project Edited successfully!");

        fetchData(); // Refresh data if needed
        handleClose(); // Close the modal
      }
    } catch (error) {
      console.error("Error updating project:", error);
      ErrorNotification(error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed");
    } finally {
      setSubmissionLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Edit Project Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="projName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              value={projName}
              onChange={(e) => setProjName(e.target.value)}
              isInvalid={!!errors.projName}
              required
            />
            <Form.Control.Feedback type="invalid">
              {errors.projName}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="projIndustry">
            <Form.Label>Industry</Form.Label>
            <Form.Control
              as="select"
              value={industry}
              onChange={(e) => setIndustry(e.target.value)}
              isInvalid={!!errors.industry}
              required
            >
              <option value="">Select Industry</option>
              <option>Technology</option>
              <option>Finance</option>
              <option>Healthcare</option>
              {/* Add more industries as needed */}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.industry}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="projDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              isInvalid={!!errors.description}
              required
            />
            <Form.Control.Feedback type="invalid">
              {errors.description}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="projFunding">
            <Form.Label>Funding</Form.Label>
            <Form.Control
              type="text"
              value={funding}
              onChange={(e) => setFunding(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Sensitive Details (PDF)</Form.Label>
            <Form.Control
              type="file"
              accept=".pdf"
              onChange={handleFileChange}
            />
            {filePreview && (
              <div className="mt-3 text-center">
                <a href={filePreview} target="_blank" rel="noopener noreferrer">
                  View PDF
                </a>
                <Button
                  variant="danger"
                  className="ms-2"
                  onClick={handleRemoveFile}
                >
                  Remove
                </Button>
              </div>
            )}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          {submissionLoading ? (
            <span className="spinner-border spinner-border-sm" />
          ) : (
            "Save Changes"
          )}

        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditProjectModal;
