import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axiosInstance from "../../axios";
import { ErrorNotification, SuccessNotification } from "../../notifications/notifications";

const predefinedResponsibilities = [
  "Phone answering",
  "Customer relations",
  "Product and delivery",
  "Complaint response",
  "Regular Attendance",
];

const JobResponsibilitiesModal = ({
  show,
  handleClose,
  jobId,
  getData,
  responsibilities,
}) => {
  const [selectedResponsibilities, setSelectedResponsibilities] = useState([]);
  const [newResponsibility, setNewResponsibility] = useState(""); // New responsibility input
  const [allResponsibilities, setAllResponsibilities] = useState([...predefinedResponsibilities]); // Combined list
  const [validated, setValidated] = useState(false);
  const [submissionLoading, setSubmissionLoading] = useState(false);

  // Initialize selected responsibilities and merge with predefined ones
  useEffect(() => {
    if (responsibilities) {
      const uniqueResponsibilities = [...new Set([...predefinedResponsibilities, ...responsibilities])]; // Merge and remove duplicates
      setAllResponsibilities(uniqueResponsibilities);
      setSelectedResponsibilities(responsibilities);
    }
  }, [responsibilities]);

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedResponsibilities((prev) => [...prev, value]);
    } else {
      setSelectedResponsibilities((prev) =>
        prev.filter((responsibility) => responsibility !== value)
      );
    }
  };

  const handleNewResponsibilityChange = (e) => {
    setNewResponsibility(e.target.value);
  };

  const handleAddNewResponsibility = () => {
    if (newResponsibility && !allResponsibilities.includes(newResponsibility)) {
      setAllResponsibilities((prev) => [...prev, newResponsibility]);
      setSelectedResponsibilities((prev) => [...prev, newResponsibility]);
      setNewResponsibility(""); // Clear input after adding
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission
      handleAddNewResponsibility(); // Add responsibility on Enter
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedResponsibilities.length > 0) {
      setSubmissionLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token}`,
        },
      };

      try {
        const payLoad = { responsibilities: selectedResponsibilities };

        const res = await axiosInstance.put(
          `${process.env.REACT_APP_BE_URL}/job/${jobId}`,
          payLoad,
          config
        );

        if (res.status === 200) {
          SuccessNotification(res.data?.message || "Responsibilities updated successfully!");
          getData();
          handleClose();
        }
      } catch (error) {
        ErrorNotification(error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed");

      } finally {
        setSubmissionLoading(false);
      }
    } else {
      setValidated(true);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size="lg"
      backdrop="static"
      animation={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Job Responsibilities</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group controlId="formJobResponsibilities">
            <Form.Label>Select Job Responsibilities</Form.Label>
            {allResponsibilities.map((responsibility, index) => (
              <Form.Check
                type="checkbox"
                key={responsibility}
                id={`responsibility-${index}`}
                value={responsibility}
                label={responsibility}
                onChange={handleCheckboxChange}
                checked={selectedResponsibilities.includes(responsibility)}
              />
            ))}
            <Form.Label>Add New Responsibility</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter new responsibility"
              value={newResponsibility}
              onChange={handleNewResponsibilityChange}
              onKeyPress={handleKeyPress} // Capture Enter keypress
            />
            <Button
              variant="primary"
              size="sm"
              className="mt-2"
              onClick={handleAddNewResponsibility}
            >
              Add New Responsibility
            </Button>
          </Form.Group>
          {validated && selectedResponsibilities.length === 0 && (
            <div className="text-danger">
              Please select at least one responsibility.
            </div>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={handleSubmit}
          disabled={submissionLoading}
        >
          {submissionLoading ? (
            <span className="spinner-border spinner-border-sm" />
          ) : (
            "Add Responsibilities"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default JobResponsibilitiesModal;
