import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axiosInstance from "../../axios";
import { ErrorNotification, SuccessNotification } from "../../notifications/notifications";

const AddExternalLinkModal = ({
  show,
  handleClose,
  userId,
  getData,
  mode,
  currentLink,
  index,
  data,
}) => {
  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");
  const [validated, setValidated] = useState(false);
  const [submissionLoading, setSubmissionLoading] = useState(false);
  const [data2, setData2] = useState(null);

  useEffect(() => {
    if (currentLink && mode === "edit") {
      setTitle(currentLink.linkDescription || "");
      setLink(currentLink.links || "");
    } else {
      setTitle("");
      setLink("");
    }
    setData2(data)
  }, [currentLink, mode, data]);

  const isValidURL = (url) => {
    try {
      new URL(url);
      return true;
    } catch (_) {
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false || !isValidURL(link)) {
      e.stopPropagation();
      if (!isValidURL(link)) {
        ErrorNotification("Please provide a valid URL.");
      }
    } else {
      setSubmissionLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token}`,
        },
      };

      try {
        let payLoad;
        if (!currentLink) {
          // Adding a new link
          payLoad = {
            links: [...data2.links, { linkDescription: title, links: link }],
          };
        } else {
          // Editing an existing link
          const updatedLinks = [...data2.links];
          updatedLinks[index] = { linkDescription: title, links: link };
          payLoad = {
            links: updatedLinks,
          };
        }

        const { status } = await axiosInstance.put(
          `${process.env.REACT_APP_BE_URL}/user/${userId}`,
          payLoad,
          config
        );

        if (status === 200) {
          SuccessNotification(`External link ${mode === "edit" ? "updated" : "added"} successfully!`);
          getData();
          handleClose();
        }
      } catch (error) {
        ErrorNotification(error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed");
      } finally {
        setSubmissionLoading(false);
      }
    }

    setValidated(true);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size="lg"
      backdrop="static"
      animation={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {mode === "edit" ? "Edit External Link" : "Add New External Link"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
          className="py-2"
        >
          <Form.Group controlId="formTitle">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please provide a title.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formLink">
            <Form.Label>Link</Form.Label>
            <Form.Control
              type="url"
              value={link}
              onChange={(e) => setLink(e.target.value)}
              required
              isInvalid={validated && !isValidURL(link)}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid link.
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          disabled={submissionLoading}
          onClick={handleSubmit}
        >
          {submissionLoading ? (
            <span className="spinner-border spinner-border-sm" />
          ) : mode === "edit" ? (
            "Update Link"
          ) : (
            "Add Link"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddExternalLinkModal;
