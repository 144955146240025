import { Button, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import avatar from "../../../assets/defaultProfile.jpg";
import axios from "../../../axios";
import { useEffect, useState } from "react";
import ImageUploadModal from "../../../Components/accountModels/ImageUploadModal";
import { SuccessNotification, ErrorNotification } from "../../../notifications/notifications";
import FeatherIcon from "feather-icons-react";
import ListAsResourceModel from "./ListAsResourceModel";

const url = process.env.REACT_APP_BE_URL;

const AccountInformation = () => {
  const [data, setData] = useState(null);
  const [btnDisabled, setBtnDisabled] = useState(false);

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const userId = userInfo._id;

  const [showUploadModal, setShowUploadModal] = useState(false);
  const [submissionLoading, setSubmissionLoading] = useState(false);
  const [showListAsResource, setShowListAsResource] = useState(false);
  const [resources, setResources] = useState([]);
  const [isUserListed, setIsUserListed] = useState(false);

  // Check if the organization is already listed as a resource
  useEffect(() => {
    if (resources && userInfo) {
      const fullName = `${userInfo.firstName} ${userInfo.lastName}`;
      const isListed = resources.some(resource => resource.name === fullName);
      setIsUserListed(isListed);
    }
  }, [resources, userInfo]);

  const handleCloseListAsResourceModal = () => setShowListAsResource(false);

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token}`,
    },
  };

  const fetchResources = async (filter) => {
    const _resources = await axios.get(
      `${url}/resource/my-resources/${filter}`,
      axiosConfig
    );
    setResources(_resources.data);
  };

  const fetchResourcesData = async () => {
    await fetchResources("owned");
  };

  // Function to open modal
  const openModal = () => {
    setShowUploadModal(true);
  };

  // Function to close modal
  const closeModal = () => {
    setShowUploadModal(false);
  };

  const handleListAsAResource = () => {
    setShowListAsResource(true);
  };

  // Form default values
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  const [interests, setInterests] = useState("");
  const [dp, setDp] = useState(null);

  // Validation state
  const [validationErrors, setValidationErrors] = useState({});

  useEffect(() => {
    if (data) {
      setInitialValues({
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: data?.email,
      });
      setInterests(data?.interests || "");
      setDp(data?.profilePic.url || null);
    }
  }, [data]);

  // Fetch user data
  const getData = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
            }`,
        },
      };
      const { data, status } = await axios.get(`${url}/user/${userId}`, config);
      setData(data);
    } catch (error) {
      ErrorNotification(error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed");
    }
  };

  // Validate form fields
  const validateForm = () => {
    const errors = {};
    if (!initialValues.firstName.trim()) {
      errors.firstName = "First Name is required.";
    }
    if (!initialValues.lastName.trim()) {
      errors.lastName = "Last Name is required.";
    }
    if (!initialValues.email.trim()) {
      errors.email = "Email is required.";
    } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(initialValues.email)) {
      errors.email = "Please enter a valid email address.";
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Handle form submission
  const handleSubmitForm = async (e) => {
    e.preventDefault();
    setBtnDisabled(true);
    setSubmissionLoading(true);

    if (!validateForm()) {
      setBtnDisabled(false);
      setSubmissionLoading(false);
      return;
    }

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
            }`,
        },
      };
      const { data, status } = await axios.put(
        `${url}/user/${userId}`,
        {
          firstName: initialValues.firstName,
          lastName: initialValues.lastName,
          email: initialValues.email,
          interests: interests,
        },
        config
      );
      if (status === 200) {
        localStorage.setItem(
          "userInfo",
          JSON.stringify({
            ...data,
            signVerifyToken: userInfo.signVerifyToken,
            token: userInfo.token,
          })
        );
        setBtnDisabled(false);
        SuccessNotification("Profile updated!");
      }
    } catch (error) {
      setBtnDisabled(false);
      ErrorNotification(error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed");
    } finally {
      setSubmissionLoading(false);
    }
  };

  useEffect(() => {
    getData();
    fetchResourcesData()
    // eslint-disable-next-line
  }, []);

  const onImageDelete = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };

    try {
      let payLoad = {
        profilePic: "",
      };

      const { status } = await axios.put(
        `${url}/user/${userInfo._id}`,
        payLoad,
        config
      );

      if (status === 200) {
        SuccessNotification("Profile Picture Deleted!");
        getData();
      }
    } catch (error) {
      ErrorNotification(error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed");
    } finally {
      setSubmissionLoading(false);
    }
  };

  return (
    <>
      <h4 className="mt-3 mt-lg-0">Account Information</h4>

      <form className="account-form" onSubmit={handleSubmitForm}>
        <h6 className="mt-4">Your Avatar</h6>
        <Row className="align-items-center">
          <Col xs="auto">
            <img
              src={dp ? dp : avatar}
              className="img-fluid avatar-md rounded-circle shadow"
              alt="..."
            />
          </Col>
          <Col>
            <Link
              to="#"
              className="btn btn-outline-primary btn-sm"
              onClick={openModal}
            >
              Upload
            </Link>
            <Link
              to="#"
              className="btn btn-outline-danger btn-sm ms-2"
              onClick={onImageDelete}
            >
              Remove
            </Link>
          </Col>
        </Row>

        <hr className="my-4" />

        <Row className="align-items-center">
          <Col lg={6}>
            <Form.Group controlId="firstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                value={initialValues.firstName}
                onChange={(e) =>
                  setInitialValues({
                    ...initialValues,
                    firstName: e.target.value,
                  })
                }
                isInvalid={!!validationErrors.firstName}
              />
              <Form.Control.Feedback type="invalid">
                {validationErrors.firstName}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="lastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                value={initialValues.lastName}
                onChange={(e) =>
                  setInitialValues({
                    ...initialValues,
                    lastName: e.target.value,
                  })
                }
                isInvalid={!!validationErrors.lastName}
              />
              <Form.Control.Feedback type="invalid">
                {validationErrors.lastName}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col lg={6}>
            <Form.Group controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={initialValues.email}
                onChange={(e) =>
                  setInitialValues({ ...initialValues, email: e.target.value })
                }
                disabled
                isInvalid={!!validationErrors.email}
              />
              <Form.Control.Feedback type="invalid">
                {validationErrors.email}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="interestedIn">
              <Form.Label>Interested In</Form.Label>
              <Form.Control
                as="select"
                value={interests}
                onChange={(e) => setInterests(e.target.value)}
              >
                <option value="">Select an option</option>
                <option value="Creating projects">Creating projects</option>
                <option value="Collaborating on projects">
                  Collaborating on Projects
                </option>
                <option value="Both Creating & Collaborating on Projects">
                  Both Creating & Collaborating on Projects
                </option>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-4 mb-2">
          <Col xs={12}>
            <Button type="submit" disabled={btnDisabled}>
              {submissionLoading ? (
                <span className="spinner-border spinner-border-sm" />
              ) : (
                "Save Changes"
              )}
            </Button>
            <Button
              className="btn-soft-success ms-2"
              onClick={handleListAsAResource}
              disabled={isUserListed} // Disable if already listed
            >
              <FeatherIcon icon="list" />
              <span className="d-none d-md-inline ms-2">
                {isUserListed ? "Listed" : "List as a Resource"}
              </span>
            </Button>
          </Col>
        </Row>
      </form>

      <ImageUploadModal
        show={showUploadModal}
        onClose={closeModal}
        userData={userInfo}
        updateData={getData}
      />

      {/* List As Resource Modal */}
      <ListAsResourceModel
        show={showListAsResource}
        handleClose={handleCloseListAsResourceModal}
        userInfo={userInfo}
        fetchData={getData}
        fetchResourcesData={fetchResourcesData}
      />

    </>
  );
};

export default AccountInformation;
