import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../../../../axios";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Spinner,
  Form,
  FormControl,
} from "react-bootstrap";
import defaultImg from "../../../../../assets/default-resource-img.png";

const url = process.env.REACT_APP_BE_URL;

const ResourceCategory = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  const navigate = useNavigate();
  const { catCode } = useParams();
  const [resources, setResources] = useState([]);
  const [loading, setLoading] = useState(true);
  const [typeFilter, setTypeFilter] = useState("");
  const [resourceTypes, setResourceTypes] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
        }`,
    },
  };

  const fetchData = async () => {
    try {
      // Get all resource types
      const _resourceTypes = await axios.get(
        `${url}/resource-type`,
        axiosConfig
      );
      setResourceTypes(_resourceTypes.data);

      // Get resources by category
      const _resources = await axios.get(
        `${url}/resource/category/${catCode}`,
        axiosConfig
      );
      setResources(_resources.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <section className="position-relative overflow-hidden bg-gradient2 py-5 px-3">
      <Container className="min-vh-100 d-flex flex-column mt-5 mt-lg-0">
        <Row className="align-items-center mb-4">
          <Col>
            <h2 className="fw-bold mb-0">
              {capitalizeFirstLetter(catCode)} Resources
            </h2>
          </Col>
        </Row>

        <Row className="mb-4">
          <Col xs={12} md={6} lg={4}>
            <Form.Group controlId="typeFilter">
              <Form.Label className="fw-semibold">Filter by Type</Form.Label>
              <FormControl
                as="select"
                value={typeFilter}
                onChange={(event) => setTypeFilter(event.target.value)}
                className="form-select"
              >
                <option value="">
                  <em>None</em>
                </option>
                {resourceTypes.map((type) => (
                  <option value={type._id} key={type.code}>
                    {type.code}
                  </option>
                ))}
              </FormControl>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          {loading ? (
            <Col
              xs={12}
              className="d-flex justify-content-center align-items-center"
            >
              <Spinner animation="border" role="status" />
            </Col>
          ) : resources.length > 0 ? (
            resources
              .filter(
                (resource) => resource.type === typeFilter || typeFilter === ""
              )
              .map((resource) => (
                <Col
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  className="mb-4 d-flex align-items-stretch"
                  key={resource.code}
                >
                  <Card className="h-100 shadow-sm border-0 overflow-hidden transition-transform transform-scale-hover">
                    <div
                      className="bg-image hover-overlay ripple"
                      data-mdb-ripple-color="light"
                    >
                      <Card.Img
                        variant="top"
                        src={resource?.image?.url || defaultImg}
                        alt={resource.name}
                        style={{ height: "200px", objectFit: "cover" }}
                      />
                    </div>
                    <Card.Body className="d-flex flex-column">
                      <Card.Title className="text-primary mb-1">
                        {resource.name}
                      </Card.Title>
                      <Card.Text className="text-muted flex-grow-1 mb-3">
                        {resource.description.length > 100
                          ? `${resource.description.substring(0, 100)}...`
                          : resource.description}
                      </Card.Text>
                      <Button
                        variant="outline-primary"
                        className="w-100 mt-auto shadow-sm"
                        onClick={() => navigate(`/resource/${resource.code}`)}
                      >
                        View Details
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              ))
          ) : (
            <Col xs={12} className="d-flex justify-content-center">
              <h4 className="fw-bold text-muted">No Resources Found</h4>
            </Col>
          )}
        </Row>
      </Container>
    </section>
  );
};

export default ResourceCategory;
