import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axiosInstance from "../../axios";
import { ErrorNotification, SuccessNotification } from "../../notifications/notifications";

const predefinedRequirements = [
  "Qualified BSC Degree",
  "Interpersonal skills",
  "Qualified MSC Degree",
  "Extended or uncommon working hours",
  "4 year working Experience",
];

const JobRequirementsModal = ({
  show,
  handleClose,
  jobId,
  getData,
  requirements,
}) => {
  const [selectedRequirements, setSelectedRequirements] = useState([]);
  const [newRequirement, setNewRequirement] = useState(""); // State for new requirement input
  const [allRequirements, setAllRequirements] = useState([...predefinedRequirements]); // Combined list of predefined and new requirements
  const [validated, setValidated] = useState(false);
  const [submissionLoading, setSubmissionLoading] = useState(false);

  // Initialize selected requirements and merge with predefined ones
  useEffect(() => {
    if (requirements) {
      const uniqueRequirements = [...new Set([...predefinedRequirements, ...requirements])]; // Merge and remove duplicates
      setAllRequirements(uniqueRequirements);
      setSelectedRequirements(requirements);
    }
  }, [requirements]);

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedRequirements((prev) => [...prev, value]);
    } else {
      setSelectedRequirements((prev) =>
        prev.filter((requirement) => requirement !== value)
      );
    }
  };

  const handleNewRequirementChange = (e) => {
    setNewRequirement(e.target.value);
  };

  const handleAddNewRequirement = () => {
    if (newRequirement && !allRequirements.includes(newRequirement)) {
      setAllRequirements((prev) => [...prev, newRequirement]);
      setSelectedRequirements((prev) => [...prev, newRequirement]);
      setNewRequirement(""); // Clear the input after adding
    }
  };

  // Add requirement when pressing Enter in the input field
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission
      handleAddNewRequirement(); // Add new requirement
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedRequirements.length > 0) {
      setSubmissionLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token}`,
        },
      };

      try {
        const payLoad = { requirements: selectedRequirements };

        const res = await axiosInstance.put(
          `${process.env.REACT_APP_BE_URL}/job/${jobId}`,
          payLoad,
          config
        );

        if (res.status === 200) {
          SuccessNotification(res.data?.message || "Requirements updated successfully!");
          getData();
          handleClose();
        }
      } catch (error) {
        ErrorNotification(error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed");

      } finally {
        setSubmissionLoading(false);
      }
    } else {
      setValidated(true);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size="lg"
      backdrop="static"
      animation={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Job Requirements</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group controlId="formJobRequirements">
            <Form.Label>Select Job Requirements</Form.Label>
            {allRequirements.map((requirement, index) => (
              <Form.Check
                type="checkbox"
                key={requirement}
                id={`requirement-${index}`}
                value={requirement}
                label={requirement}
                onChange={handleCheckboxChange}
                checked={selectedRequirements.includes(requirement)}
              />
            ))}
            <Form.Label>Add New Requirement</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter new requirement"
              value={newRequirement}
              onChange={handleNewRequirementChange}
              onKeyPress={handleKeyPress} // Capture Enter keypress here
            />
            <Button
              variant="primary"
              className="mt-2"
              size="sm"
              onClick={handleAddNewRequirement}
            >
              Add New Requirement
            </Button>
          </Form.Group>
          {validated && selectedRequirements.length === 0 && (
            <div className="text-danger">
              Please select at least one requirement.
            </div>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={handleSubmit}
          disabled={submissionLoading}
        >
          {submissionLoading ? (
            <span className="spinner-border spinner-border-sm" />
          ) : (
            "Add Requirements"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default JobRequirementsModal;
