import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";

// images
import saas3 from "../../assets/images/hero/saas3.jpg";

const AboutUs = () => {
  return (
    <section className="position-relative overflow-hidden py-7 features-3">
      <Container>
        <Row className="align-items-center">
          <Col lg={5}>
            <div
              className="mb-5 mb-lg-0"
              data-aos="fade-up"
              data-aos-duration="200"
            >
              <span className="bg-soft-success avatar avatar-sm rounded-lg icon icon-with-bg icon-xs text-success me-3 mb-4">
                <FeatherIcon icon="info" className="icon-dual-success" />
              </span>
              <h1 className="text-dark">About TINT</h1>
              <p className="text-muted my-4">
                TINT is more than a platform; it's a community that thrives on
                the exchange of trusted resources and experiences. We believe
                that by sharing tools, knowledge, and connections, we can
                collectively propel startups towards unprecedented success.
                Don't see a resource you need? Reach out to us!
              </p>
              <Link to="/signup" className="h6 text-success pt-3">
                Join Now
                <FeatherIcon className="ms-1 icon-xxs" icon="arrow-right" />
              </Link>
            </div>
          </Col>

          <Col lg={{ offset: 1, span: 6 }}>
            <div className="img-content2 position-relative">
              <div className="img-up">
                <img
                  src={saas3}
                  alt="About TINT"
                  className="img-fluid d-block rounded"
                  data-aos="fade-left"
                  data-aos-duration="300"
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutUs;
