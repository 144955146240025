import { Container, Nav, Navbar } from "react-bootstrap";
import classNames from "classnames";

// component
import Menu from "./Menu";
import ProfileDropdown from "./ProfileDropdown";

// dummy data
import { profileOptions } from "./data";

// images
import logo from "../../../assets/TINT.png";
import logoLight from "../../../assets/TINT.png";
import { Link } from "react-router-dom";

const userInfo = JSON.parse(localStorage.getItem("userInfo"));

const Navbar4 = ({ isSticky, navClass, fixedWidth }) => {
  return (
    <header>
      <Navbar
        id={isSticky ? "sticky" : ""}
        collapseOnSelect
        expand="lg"
        className={classNames("topnav-menu", navClass)}
      >
        <Container fluid={!fixedWidth}>
          <Navbar.Brand as={Link} to={`/profile/${userInfo._id}`} className="logo ms-3 ms-sm-0">
            <img
              src={logo}
              height="30"
              className="align-top logo-dark"
              alt="Logo"
            />
            <img
              src={logoLight}
              height="30"
              className="align-top logo-light"
              alt="Logo Light"
            />
          </Navbar.Brand>

          <Navbar.Toggle
            className="me-3"
            aria-controls="topnav-menu-content4"
          />

          <Navbar.Collapse id="topnav-menu-content4">
            <Menu />
            <Nav as="ul" className="align-items-lg-center">
              <ProfileDropdown profileOptions={profileOptions} />
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Navbar4;
