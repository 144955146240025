import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import axios from "../../axios";
import { CircularProgress } from "@mui/material";
import { ErrorNotification, SuccessNotification } from "../../notifications/notifications";


// components
import FormInput from "../../Components/form/FormInput";
import VerticalForm from "../../Components/form/VerticalForm";

import AuthLayout from "./AuthLayout";

const SignUp = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [callBack, setCallBack] = useState(false);
  const [interests, setInterests] = useState("Creating projects");

  /*
  form validation schema
  */
  const schemaResolver = yupResolver(
    yup.object().shape({
      firstName: yup
        .string()
        .required(t("Please enter your first name"))
        .min(3, t("First name must be at least 3 characters"))
        .max(15, t("First name must be less than 15 characters"))
        .matches(/^[a-zA-Z0-9]+$/, t("First name can only contain letters and numbers")),

      lastName: yup
        .string()
        .required(t("Please enter your last name"))
        .min(3, t("Last name must be at least 3 characters"))
        .max(15, t("Last name must be less than 15 characters"))
        .matches(/^[a-zA-Z0-9]+$/, t("Last name can only contain letters and numbers")),

      email: yup
        .string()
        .required(t("Please enter Email"))
        .email(t("Please enter a valid Email")),

      password: yup
        .string()
        .required(t("Please enter Password"))
        .min(8, t("Password must be at least 8 characters"))
        .matches(/[A-Z]/, t("Password must contain at least one uppercase letter"))
        .matches(/[a-z]/, t("Password must contain at least one lowercase letter"))
        .matches(/\d/, t("Password must contain at least one number"))
        .matches(/[@$!%*?&#]/, t("Password must contain at least one special character")),

      confirmPassword: yup
        .string()
        .oneOf([yup.ref("password"), null], t("Passwords must match"))
        .required(t("Please confirm your Password")),
    })
  );

  const url = process.env.REACT_APP_BE_URL;

  /*
  handle form submission
  */
  const onSubmit = async (formData) => {
    console.log(formData, interests);
    try {
      setCallBack(true);
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      // eslint-disable-next-line
      const { data } = await axios.post(
        `${url}/user`,
        {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          password: formData.password,
          interests: interests,
          profilePic: "",
        },
        config
      );
      SuccessNotification("The user created succesfully, Please verify your sign Up verification")
      navigate(`/confirm?email=${encodeURIComponent(formData.email)}`);
      setCallBack(false);
    } catch (error) {
      setCallBack(false);
      if (error?.response?.data === "Email Already Exists!") {
        ErrorNotification("Email Already Exists!")
      } else if (
        error?.response?.data ===
        "You haven't received an invitation for your email"
      ) {
        ErrorNotification("You haven't received an invitation for your email")

      } else if (error?.response?.data === "email must be a valid email") {
        ErrorNotification("email must be a valid email")
      } else if (
        error?.response?.data ===
        "password length must be at least 6 characters long"
      ) {
        ErrorNotification("email must be a valid email")
      } else if (error?.response?.data === "") {
        SuccessNotification("The user created succesfully!")
      } else if (
        error?.response?.data === "Please verify your email invitation"
      ) {
        ErrorNotification("Please verify your email invitation")
      } else if (
        error?.response?.data === "Please verify your sign Up verification"
      ) {
        SuccessNotification("Please verify your sign Up verification!")
        navigate(`/confirm?email=${encodeURIComponent(formData.email)}`);
      }

    }
  };

  return (
    <>
      <AuthLayout
        hasSlider
        bottomLinks={
          <p className="text-muted">
            {t("Already have an account?")}
            <Link to="/signin" className="text-primary fw-semibold ms-1">
              {t("Log In")}
            </Link>
          </p>
        }
      >
        <h6 className="h5 mb-0 mt-3">{t("Create Your Account")}</h6>
        <p className="text-muted mt-1 mb-4">
          {t(
            "Don't have an account? Create your account, it takes less than a minute."
          )}
        </p>

        <VerticalForm onSubmit={onSubmit} resolver={schemaResolver}>
          <FormInput
            type="text"
            name="firstName"
            label={t("First Name")}
            placeholder={t("First Name")}
            containerClass={"mb-3"}
          />
          <FormInput
            type="text"
            name="lastName"
            label={t("Last Name")}
            placeholder={t("Last Name")}
            containerClass={"mb-3"}
          />
          <FormInput
            type="email"
            name="email"
            label={t("Email")}
            placeholder={t("Email")}
            containerClass={"mb-3"}
          />
          <FormInput
            label={t("Password")}
            type="password"
            name="password"
            placeholder={t("Enter your password")}
            containerClass={"mb-3"}
          />
          <FormInput
            label={t("Confirm Password")}
            type="password"
            name="confirmPassword"
            placeholder={t("Confirm your password")}
            containerClass={"mb-3"}
          />
          <Form.Group className="mb-5">
            <Form.Label>{t("Interested In")}</Form.Label>
            <div>
              <Form.Check
                type="radio"
                id="creating-projects"
                label={t("Creating Projects")}
                name="interests"
                value="Creating projects"
                checked={interests === "Creating projects"}
                onChange={(e) => setInterests(e.target.value)}
                custom
              />
              <Form.Check
                type="radio"
                id="collaborating-on-projects"
                label={t("Collaborating on Projects")}
                name="interests"
                value="Collaborating on Projects"
                checked={interests === "Collaborating on Projects"}
                onChange={(e) => setInterests(e.target.value)}
                custom
              />
              <Form.Check
                type="radio"
                id="both"
                label={t("Both Creating & Collaborating on Projects")}
                name="interests"
                value="Both Creating & Collaborating on Projects"
                checked={interests === "Both Creating & Collaborating on Projects"}
                onChange={(e) => setInterests(e.target.value)}
                custom
              />
            </div>
          </Form.Group>
          <div className="mb-0 text-center d-grid">
            <Button type="submit">
              {callBack ? (
                <CircularProgress size={20} sx={{ color: "white" }} />
              ) : (
                t("Register")
              )}
            </Button>
          </div>
        </VerticalForm>
      </AuthLayout>
    </>
  );
};

export default SignUp;
