import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Form,
  Navbar,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import NavbarComponent from "../Components/navbar/Navbar";
import Footer from "../Components/footer/Footer";
import { useEffect } from "react";

// ContactUs component for TINT
const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  // Form validation schema
  const schemaResolver = yupResolver(
    yup.object().shape({
      fname: yup.string().required("Please enter first name"),
      lname: yup.string().required("Please enter last name"),
      email: yup
        .string()
        .required("Please enter Email")
        .email("Please enter a valid Email"),
      message: yup.string().required("Please enter Message"),
    })
  );

  // Form methods
  const methods = useForm({ resolver: schemaResolver });
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = methods;

  // Handle form submission
  const onSubmit = (data) => {
    console.log(data);
    // Handle form submission logic here
  };

  return (
    <>
      <div className="header-7 bg-gradient2">
        <NavbarComponent
          navClass="navbar-light zindex-10"
          isSticky={false}
          fixedWidth
          hideSearch
          buttonClass="btn-primary btn-sm"
        />
        <section className="hero-4 pt-lg-6 pb-sm-9 pb-6 pt-9">
          <Container>
            <Row className="justify-content-center">
              <Col lg={7} className="text-center">
                <h1 className="hero-title mb-0">Contact Us</h1>
                <p className="mb-4 fs-17 text-muted">
                  Please fill out the following form and we will get back to you
                  shortly
                </p>
              </Col>
            </Row>
          </Container>
          <div className="shape bottom">
            <svg
              width="1440px"
              height="40px"
              viewBox="0 0 1440 40"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                id="shape-b"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g id="curve" fill="#fff">
                  <path
                    d="M0,30.013 C239.659,10.004 479.143,0 718.453,0 C957.763,0 1198.28,10.004 1440,30.013 L1440,40 L0,40 L0,30.013 Z"
                    id="Path"
                  ></path>
                </g>
              </g>
            </svg>
          </div>
        </section>
      </div>
      <section className="section pb-lg-7 py-4 position-relative">
        <Container>
          <Row className="align-items-center">
            <Col lg={12}>
              <Card className="shadow-none">
                <Card.Body className="p-xl-5 p-0">
                  <h2 className="mb-2 mt-0 fw-medium">Let's Talk Further</h2>
                  <p className="mb-5">
                    Please fill out the following form and we will get back to
                    you shortly
                  </p>

                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                      <Col md={6}>
                        <Form.Group controlId="formFirstName" className="mb-3">
                          <Form.Label>First Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Your First Name"
                            {...register("fname")}
                            isInvalid={!!errors.fname}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.fname?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId="formLastName" className="mb-3">
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Your Last Name"
                            {...register("lname")}
                            isInvalid={!!errors.lname}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.lname?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={12}>
                        <Form.Group controlId="formEmail" className="mb-3">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Your Email"
                            {...register("email")}
                            isInvalid={!!errors.email}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.email?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg={12}>
                        <Form.Group controlId="formMessage" className="mb-3">
                          <Form.Label>Message</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={5}
                            placeholder="Type your message..."
                            {...register("message")}
                            isInvalid={!!errors.message}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.message?.message}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col lg="auto" className="mb-0">
                        <Button type="submit">
                          Send
                          <span className="icon icon-xs text-white ms-1">
                            <FeatherIcon icon="send" />
                          </span>
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mt-5 align-items-center">
            <Col md={4}>
              <div className="d-flex px-md-1 px-lg-5 mb-md-0 mb-3">
                <span className="bg-soft-primary avatar avatar-sm rounded icon icon-with-bg icon-xs text-primary me-3 flex-shrink-0">
                  <FeatherIcon icon="mail" className="icon-dual-primary" />
                </span>
                <div className="flex-grow-1">
                  <h5 className="m-0 fw-medium">Email</h5>
                  <Link to="#" className="text-muted fw-normal h5 my-1">
                    youremail@gmail.com
                  </Link>
                </div>
              </div>
            </Col>

            <Col md={4}>
              <div className="d-flex px-md-1 px-lg-5 mb-md-0 mb-3">
                <span className="bg-soft-orange avatar avatar-sm rounded icon icon-with-bg icon-xs text-orange me-3 flex-shrink-0">
                  <FeatherIcon icon="phone-call" className="icon-dual-orange" />
                </span>
                <div className="flex-grow-1">
                  <h5 className="m-0 fw-medium">Phone</h5>
                  <Link to="#" className="text-muted fw-normal h5 my-1">
                    +00 123 456 7890
                  </Link>
                </div>
              </div>
            </Col>

            <Col md={4}>
              <div className="d-flex px-md-1 px-lg-5 mb-md-0 mb-3">
                <span className="bg-soft-info avatar avatar-sm rounded icon icon-with-bg icon-xs text-info me-3 flex-shrink-0">
                  <FeatherIcon icon="map-pin" className="icon-dual-info" />
                </span>
                <div className="flex-grow-1">
                  <h5 className="m-0 fw-medium">Address</h5>
                  <Link to="#" className="text-muted fw-normal h5 my-1">
                    565 Brrom Str, NY
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
};

export default ContactUs;
