import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "../../../../../axios";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Spinner,
  Alert,
} from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import defaultImg from "../../../../../assets/default-resource-img.png";
import { ErrorNotification, SuccessNotification } from "../../../../../notifications/notifications"
import Navbar from "../../../../../Components/navbar/Navbar";


const url = process.env.REACT_APP_BE_URL;

const Resource = () => {

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const { resCode } = useParams();
  const [resource, setResource] = useState(null);
  const [loading, setLoading] = useState(true);
  const userToken = JSON.parse(localStorage.getItem("userInfo"))?.token;
  const navigate = useNavigate();

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  };

  const id = JSON.parse(localStorage.getItem("userInfo"));

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const _resource = userToken
        ? await axios.get(
          `${url}/resource/code/${resCode}/protected`,
          axiosConfig
        )
        : await axios.get(`${url}/resource/code/${resCode}`);
      setResource(_resource.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      ErrorNotification("Resource is either not approved yet or found.");
      setLoading(false);
    }
  };

  const isOwner = id?._id === resource?.owner?._id;

  const handleAction = async (action) => {
    if (!userToken) navigate("/signup");
    setLoading(true);

    let successMessage;

    try {
      await axios.post(`${url}/resource/${action}/${resCode}`, {}, axiosConfig);

      // Customize success messages based on action
      switch (action) {
        case "recommend":
          if (resource.recommendedByUser) {
            successMessage = "Resource has been successfully unrecommended.";
          } else {
            successMessage = "Resource has been successfully recommended.";
          }
          break;
        case "save":
          if (resource.savedByUser) {
            successMessage = "Resource has been successfully unsaved";
          } else {
            successMessage = "Resource has been successfully saved to your account.";
          }
          break;
        case "claim":
          successMessage = "Resource is added to your Resources and is under review for approval.";
          break;
        default:
          successMessage = `${action.charAt(0).toUpperCase() + action.slice(1)} action successful.`;
          break;
      }

      SuccessNotification(successMessage);
    } catch (error) {
      console.error(error);
      ErrorNotification(`Failed to perform ${action} action.`);
    } finally {
      fetchData();
      setLoading(false);
    }
  };

  return (
    <>
      {!id && <Navbar navClass="navbar-light zindex-10"
        isSticky={false}
        fixedWidth
        hideSearch
        buttonClass="btn-primary btn-sm" />}

      <section className="position-relative overflow-hidden bg-gradient2 py-3 px-3">
        <Container className="min-vh-100 bg-light p-4 mt-5 mt-lg-0">
          {loading ? (
            <div className="d-flex justify-content-center align-items-center min-vh-100">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : resource ? (
            <Row className="g-4 justify-content-center mt-lg-4 mt-0 mb-2">
              <Col xs="auto">
                <Card className="border-0 shadow-lg rounded-3">
                  <Card.Img
                    variant="top"
                    src={resource.image?.url || defaultImg}
                    alt={resource.name}
                    style={{
                      height: "auto",
                      objectFit: "cover",
                      maxHeight: "400px",
                      borderRadius: "1rem",
                    }}
                  />
                </Card>
              </Col>
              <Col xs="auto">
                <Card.Body className="d-flex flex-column p-4">
                  <Card.Title className="text-primary fs-3 fw-bold mb-3">
                    {resource.name}
                  </Card.Title>
                  <Card.Text className="text-muted fs-5 mb-4">
                    {resource.description}
                  </Card.Text>
                  <Row>
                    <div className="d-flex flex-row justify-content-center justify-content-md-end gap-2">
                      <Button
                        className="btn-soft-primary d-flex align-items-center px-4 py-2 rounded-pill shadow-sm"
                        onClick={() => handleAction("recommend")} disabled={isOwner}
                      >
                        <FeatherIcon icon="thumbs-up" className="me-2" />
                        <span className="d-none d-md-inline  ms-2">{resource.recommendedByUser
                          ? "Recommended"
                          : "Recommend"}{" "}
                        </span><span className="ms-1">{resource.recommends.length}</span>

                      </Button>
                      <Button
                        className="btn-soft-success d-flex align-items-center px-4 py-2 rounded-pill shadow-sm"
                        onClick={() => handleAction("save")}
                      >
                        <FeatherIcon icon="save" />
                        <span className="d-none d-md-inline  ms-2">{resource.savedByUser ? "Saved" : "Save"}</span>

                      </Button>
                      {!resource.owner && (
                        <Button
                          variant="outline-primary"
                          className="d-flex align-items-center px-4 py-2 rounded-pill shadow-sm"
                          onClick={() => handleAction("claim")}
                        >
                          <FeatherIcon icon="plus-square" />
                          <span className="d-none d-md-inline  ms-2">Claim</span>

                        </Button>
                      )}
                      {resource.website && (
                        <Button
                          variant="secondary"
                          className="d-flex align-items-center px-4 py-2 rounded-pill shadow-sm"
                          onClick={() =>
                            window.open(resource.website, "_blank")
                          }
                        >
                          <FeatherIcon icon="external-link" />
                          <span className="d-none d-md-inline  ms-2">Visit Website</span>
                        </Button>
                      )}
                    </div>
                  </Row>
                </Card.Body>
              </Col>
            </Row>
          ) : (
            <Alert variant="danger" className="text-center">
              Resource is either not approved yet or found
            </Alert>
          )}
        </Container>
      </section>
    </>
  );
};

export default Resource;
