import React, { useState } from "react";
import { Modal, Button, Alert, Spinner } from "react-bootstrap";
import axiosInstance from "../../axios";
import { ErrorNotification, SuccessNotification } from "../../notifications/notifications";

const url = process.env.REACT_APP_BE_URL;

const PublishOrganizationModal = ({
  show,
  handleClose,
  handleInviteCollaborators,
  orgId,
  fetchData,
}) => {
  const [loading, setLoading] = useState(false);

  const handlePublish = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };

    setLoading(true);

    try {
      const res = await axiosInstance.put(
        `${url}/organization/${orgId}`,
        {
          status: "published",
        },
        config
      );

      if (res.status === 200) {
        SuccessNotification(res.data?.message ? res.data?.message : res.data ? res.data : "Updated successfully");
        fetchData(); // Refresh the data after a successful update
      }
    } catch (error) {
      ErrorNotification(error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed");
    } finally {
      setLoading(false);
      handleClose(); // Close the modal after processing
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Publish Organization</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to publish this organization?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handlePublish} disabled={loading}>
          {loading ? <Spinner animation="border" size="sm" /> : "Publish"}
        </Button>
        <Button
          variant="info"
          onClick={() => {
            handleInviteCollaborators();
            handleClose();
          }}
        >
          Invite Collaborators
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PublishOrganizationModal;
