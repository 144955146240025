import {
  Button,
  Chip,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Typography,
  Alert,
  Snackbar
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import axios from "../axios";

import Drawer from "../Components/Drawer";

import { styled, alpha } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const url = process.env.REACT_APP_BE_URL;

//Styled Menu Component
const StyledMenu = styled((props) => (
  <Menu
    elevation={5}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    barder: "1px solid",
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const JobApplicantList = () => {
  const navigate = useNavigate()
  const { jobId } = useParams();
  const [applicantList, setApplicantList] = useState(null);
  const [job, setJob] = useState(null);
  const [project, setProject] = useState(null);
  const [index, setIndex] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isStatusLoading, setStatusLoading] = useState(false);
  const [isNDALoading, setNDALoading] = useState(false);

  //alerts
  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  function handleClose() {
    setAlert({
      ...alert,
      showAlert: false,
      severity: "",
      message: "",
    });
  }

  //applicant status
  const [completed, setCompleted] = useState(false)
  const [accepted, setAccepted] = useState(false)
  const [rejected, setRejected] = useState(false)

  //3 dot menu states
  const [anchorMobileView, setAnchorMobileView] = React.useState(null);
  const openMobileView = Boolean(anchorMobileView);

  //Choose action states
  const [anchorChooseAction, setAnchorChooseAction] = React.useState(null);
  const openChooseAction = Boolean(anchorChooseAction);

  //Choose action function
  const handleClickChooseAction = (e) => {

    setAnchorChooseAction(e.currentTarget);
  };
  const handleCloseMenuChooseAction = () => {
    setAnchorChooseAction(null);
  };

  //3 dot menu fuctions
  const handleClickMobileView = (event) => {
    setAnchorMobileView(event.currentTarget);
  };

  const handleCloseMenuMobileView = () => {
    setAnchorMobileView(null);
  };

  const loggedInUserId = JSON.parse(localStorage.getItem("userInfo"))._id

  const getApplicantList = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };
    try {
      // eslint-disable-next-line
      const { data, status } = await axios.get(
        `${url}/application/job/${jobId}`,
        config
      );
      setApplicantList(data);

    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed"}`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);
    }
  };

  const getJob = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };
    try {
      // eslint-disable-next-line
      const { data, status } = await axios.get(`${url}/job/${jobId}`, config);
      setJob(data);
      setProject(data?.projectID);
    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed"}`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);
    }
  };

  //update NDA status 
  const updateNdaStatus = async (ind) => {
    setNDALoading(true);
    setAnchorChooseAction(null);
    setAnchorMobileView(null);
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };

    try {

      let ndaPayLoad = {
        userId: applicantList[ind].userID,
        projectId: job?.projectID?._id,
        jobId: jobId
      };
      const res = await axios.post(`${url}/nda`, ndaPayLoad, config);

      if (res.status === 200) {
        setAlert({
          showAlert: true,
          severity: "success",
          message: `${res.data?.message ? res.data?.message : res.data ? res.data : "Email has been sent!"}`,
        });

        setTimeout(() => {
          setAlert({
            showAlert: false,
            severity: "success",
            message: "",
          });
        }, 16000);
      }

    } catch (error) {
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed"}`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);

    }
    setNDALoading(false);
  };

  useEffect(() => {
    if (project) {
      getApplicantList();
      if (applicantList?.length === 0) {
        setLoading(false)
      }
    }
    // eslint-disable-next-line
  }, [project]);


  useEffect(() => {
    if (applicantList) {
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [applicantList]);

  useEffect(() => {
    getJob();
    // eslint-disable-next-line
  }, []);

  const changeStatus = async (value) => {
    setStatusLoading(true);
    setAnchorChooseAction(null);
    setAnchorMobileView(null);

    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };
    let payload
    try {
      if (value === "accepted") {
        payload = {
          applicationID: applicantList[index]._id
        }
      }

      if (value === "completed") {
        // eslint-disable-next-line
        let { data, status } = await axios.put(`${url}/application/${applicantList[index]._id}`, {
          status: value
        }, config);
        if (status === 200) {
          setAlert({
            showAlert: true,
            severity: "success",
            message: data,
          });

          setTimeout(() => {
            setAlert({
              showAlert: false,
              severity: "success",
              message: "",
            });
          }, 16000);
        }
      } else if (value === 'rejected') {
        // eslint-disable-next-line
        let { data, status } = await axios.put(`${url}/application/${applicantList[index]._id}`, { status: value }, config);
        if (status === 200) {
          setAlert({
            showAlert: true,
            severity: "success",
            message: data,
          });

          setTimeout(() => {
            setAlert({
              showAlert: false,
              severity: "success",
              message: "",
            });
          }, 16000);
        }
      } else {
        // eslint-disable-next-line
        let { data, status } = await axios.put(`${url}/application/mail/${applicantList[index].userID}`, payload, config);
        if (status === 200) {
          if (data) {
            setAlert({
              showAlert: true,
              severity: "success",
              message: data,
            });
          } else {
            setAlert({
              showAlert: true,
              severity: "success",
              message: "Email is successfully sent!",
            });
          }
          await getApplicantList();
          setTimeout(() => {
            setAlert({
              showAlert: false,
              severity: "success",
              message: "",
            });
          }, 16000);
        }
      }
    } catch (error) {
      console.log(error)
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed"}`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);

    }
    setStatusLoading(false);
  }

  const mobileViewNavigate = () => {
    navigate(`/profile/${applicantList[index].userID}`)
  }

  const handleContactClick = (email) => {
    window.location.href = `mailto:${email}`;
  };


  return (
    <>

      <Drawer />
      <Grid container xs={12}>
        < Grid container item lg={0.8} md={1.2} sm={1.8}>
          {/* <Sidebar /> */}
        </Grid>
        {!loading ?
          (<Grid
            container
            item
            marginTop={10}
            marginBottom={5}
            lg={11}
            md={10}
            sm={10}
            xs={12}
            sx={{
              padding: {
                xs: "0 1rem",
                sm: "none",
              },
            }}
          >
            <Snackbar
              open={alert.showAlert}
              autoHideDuration={15000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <Alert
                severity={alert.severity}
                onClose={handleClose}
                sx={{
                  fontSize: "16px",
                  overflowWrap: "break-word",
                  marginTop: "4rem",
                  marginRight: {
                    lg: "2rem",
                    md: "2rem",
                    sm: "1rem",
                  },
                }}
              >
                {alert.message}
              </Alert>
            </Snackbar>

            <Grid item xs={12}>
              <Button variant="contained" onClick={() => navigate(`/jobDetails/${jobId}`)}>
                <ChevronLeftIcon />
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography
                fontWeight="700"
                sx={{
                  textAlign: {
                    lg: "left",
                    md: "left",
                    sm: "center",
                    xs: "center",
                  },
                  fontSize: {
                    xs: "1.2rem",
                    sm: "2rem",
                  },
                }}
              >
                {project.name} - {job.title} - Applicant List
              </Typography>
            </Grid>
            {applicantList.length > 0 ? (<Grid xs={12} mt={4} item container>
              {applicantList?.map((applicant, index) => {

                return (
                  <Paper
                    key={applicant.userID}
                    elevation={2}
                    sx={{
                      width: "100%",
                      border: "1px solid",
                      borderRadius: "8px",
                      padding: { xs: "10px", sm: "15px" },
                      mb: "1rem",
                    }}
                    alignItems="center"
                    container
                  >
                    <Grid container alignItems="center">
                      <Grid item xs={7} md={3.5}>
                        <Typography
                          sx={{
                            display: "flex",
                            justifyContent: "flex-Start",
                            alignItems: "center",
                            marginRight: "10px",
                            fontSize: {
                              xs: "0.8rem",
                              sm: "0.8rem",
                              md: "1rem",
                            },
                          }}
                          textAlign="center"
                          fontWeight="500"
                        >
                          {applicant.username}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} md={1.5}>
                        {process.env.REACT_APP_COMMUNITY_EDITION === 'false' &&
                          <Chip
                            sx={{
                              marginLeft: "1rem",
                              padding: { xs: "5px", sm: "15px 10px " },
                              fontWeight: 400,
                              fontSize: "0.8rem",
                            }}
                            variant="contained"
                            size="small"
                            label={applicant.status}
                            color={
                              applicant.status === "pending"
                                ? "warning"
                                : applicant.status === "rejected"
                                  ? "error"
                                  : applicant.status === "accepted"
                                    ? "info"
                                    : "success"} />
                        }
                      </Grid>
                      <Grid
                        container
                        item
                        md={7}
                        sx={{
                          display: { xs: "none", sm: "none", md: "flex" },
                        }}
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        {process.env.REACT_APP_COMMUNITY_EDITION === 'false' &&
                        <>
                          <Button
                            disabled={isStatusLoading || isNDALoading}
                            id="basic-button"
                            aria-controls={openChooseAction ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openChooseAction ? "true" : undefined}
                            color="success"
                            variant="contained"
                            onClick={(e) => {
                              setIndex(index)
                              handleClickChooseAction(e)
                              if (applicantList[index].status === "completed") {

                                setCompleted(true)
                                setAccepted(false)
                                setRejected(false)
                              }
                              if (applicantList[index].status === "rejected") {
                                setRejected(true)
                                setCompleted(false)
                                setAccepted(false)
                              }
                              if (applicantList[index].status === "accepted") {
                                setAccepted(true)
                                setRejected(false)
                                setCompleted(false)
                              }
                              if (applicantList[index].status === "pending") {
                                setAccepted(false)
                                setRejected(false)
                                setCompleted(false)
                              }
                            }}
                            sx={{
                              borderRadius: "8px",
                              textTransform: "none",
                            }} >
                            {isStatusLoading ?
                              <CircularProgress size={20} />
                              :
                              ' Choose Action'
                            }

                          </Button>
                          <Menu
                            id="basic-menu"
                            MenuListProps={{
                              'aria-labelledby': 'basic-button',
                            }}
                            aria-labelledby='basic-button'
                            anchorEl={anchorChooseAction}
                            open={openChooseAction}
                            onClose={handleCloseMenuChooseAction}
                          >

                            <MenuItem disableRipple disabled={completed || rejected || isStatusLoading || isNDALoading} onClick={() => changeStatus("completed")} >Completed</MenuItem>

                            <MenuItem disableRipple disabled={completed || rejected || accepted || isStatusLoading || isNDALoading} onClick={() => changeStatus("accepted")}>Accept</MenuItem>
                            <MenuItem disableRipple disabled={rejected || accepted || completed || isStatusLoading || isNDALoading} onClick={() => changeStatus("rejected")}>Reject</MenuItem>
                          </Menu>
                        </>
                        }
                        {process.env.REACT_APP_COMMUNITY_EDITION === 'false' &&
                        <Button
                          color="primary"
                          variant="contained"
                          sx={{
                            borderRadius: "8px",
                            textTransform: "none",
                            marginLeft: "1rem",
                          }}
                          onClick={() => updateNdaStatus(index)}
                          disabled={isStatusLoading || isNDALoading}
                        >
                          {isNDALoading ?
                            <CircularProgress size={20} />
                            :
                            'Request NDA'
                          }

                        </Button>
                        }
                        { process.env.REACT_APP_COMMUNITY_EDITION === 'true' &&
                        <Button
                          color="primary"
                          variant="contained"
                          sx={{
                            borderRadius: "8px",
                            textTransform: "none",
                            marginLeft: "1rem",
                          }}
                          onClick={() => handleContactClick(applicantList[index].email)}
                          disabled={isStatusLoading || isNDALoading}
                        >
                          Contact
                        </Button>
                        }
                        <Button
                          color="primary"
                          variant="contained"
                          sx={{
                            borderRadius: "8px",
                            textTransform: "none",
                            marginLeft: "1rem",
                          }}
                          onClick={() => navigate(`/profile/${applicantList[index].userID}`)}
                          disabled={isStatusLoading || isNDALoading}
                        >
                          View Profile
                        </Button>
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        sx={{ display: { xs: "flex", md: "none" } }}
                      >
                        <IconButton
                          aria-haspopup="true"
                          aria-expanded={openMobileView ? "true" : undefined}
                          variant="contained"
                          disableElevation
                          onClick={(e) => {
                            handleClickMobileView(e)
                            setIndex(index)
                            if (applicantList[index].status === "completed") {

                              setCompleted(true)
                              setAccepted(false)
                              setRejected(false)
                            }
                            if (applicantList[index].status === "rejected") {
                              setRejected(true)
                              setCompleted(false)
                              setAccepted(false)
                            }
                            if (applicantList[index].status === "accepted") {
                              setAccepted(true)
                              setRejected(false)
                              setCompleted(false)
                            }
                            if (applicantList[index].status === "pending") {
                              setAccepted(false)
                              setRejected(false)
                              setCompleted(false)
                            }
                          }} >

                          <MoreVertIcon />
                        </IconButton>
                        <StyledMenu
                          id="demo-customized-menu"
                          MenuListProps={{
                            "aria-labelledby": "demo-customized-button",
                          }}
                          anchorEl={anchorMobileView}
                          open={openMobileView}
                          onClose={handleCloseMenuMobileView}
                        >
                          <MenuItem disabled={completed || rejected || isStatusLoading || isNDALoading} onClick={() => changeStatus("completed")} sx={{ fontSize: "0.8rem" }} disableRipple>
                            Completed
                          </MenuItem>
                          <MenuItem disabled={completed || rejected || accepted || isStatusLoading || isNDALoading} onClick={() => changeStatus("accepted")} disableRipple sx={{ fontSize: "0.8rem" }}>
                            Accept
                          </MenuItem>
                          <MenuItem disabled={rejected || accepted || completed || isStatusLoading || isNDALoading} onClick={() => changeStatus("rejected")} disableRipple sx={{ fontSize: "0.8rem" }}>
                            Reject
                          </MenuItem>
                          <MenuItem onClick={() => updateNdaStatus(index)} disableRipple sx={{ fontSize: "0.8rem" }} disabled={isStatusLoading || isNDALoading}>
                            Request NDA
                          </MenuItem>
                          <MenuItem disableRipple sx={{ fontSize: "0.8rem" }} onClick={mobileViewNavigate} disabled={isStatusLoading || isNDALoading}>
                            View Profile
                          </MenuItem>
                        </StyledMenu>
                      </Grid>
                    </Grid>
                  </Paper>
                );
              })}
            </Grid>) : (<Grid xs={12} mt={4} container item sx={{ alignItems: "center", justifyContent: "center", height: "100%" }}>
              <Grid>
                <Typography variant="h5">
                  This job has no applicants yet
                </Typography>
              </Grid>
            </Grid>)}

          </Grid>) : (
            <Grid
              item
              container
              alignItems="center"
              justifyContent="center"
              height="100vh"
            >
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          )}
      </Grid>
    </>
  );
};

export default JobApplicantList;
