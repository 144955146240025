import axios from "../../axios";
import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Image } from "react-bootstrap";
import { ErrorNotification, SuccessNotification } from "../../notifications/notifications";

const url = process.env.REACT_APP_BE_URL;

const ListAsResourceModel = ({ show, handleClose, organization, fetchResourcesData, fetchData }) => {
    // State to manage form fields
    const [orgName, setOrgName] = useState("");
    const [description, setDescription] = useState("");
    const [website, setWebsite] = useState("");
    const [logoPreview, setLogoPreview] = useState("");
    const [categories, setCategories] = useState([]);
    const [resourceTypes, setResourceTypes] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [submissionLoading, setSubmissionLoading] = useState(false);
    const [typeId, setTypeId] = useState("");

    // Validation states
    const [categoryError, setCategoryError] = useState(false);

    // Initialize state with the organization prop when modal opens
    useEffect(() => {
        if (organization) {
            setOrgName(organization.name || "");
            setDescription(organization.description || "");
            setWebsite(organization.website || "");
            setLogoPreview(organization.logo?.url || "");
        }
    }, [organization]);

    const axiosConfig = {
        headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token}`,
        },
    };

    // Fetch resource types and categories
    useEffect(() => {
        const fetch = async () => {
            try {

                const _resourceTypes = await axios.get(`${url}/resource-type`, axiosConfig);
                setResourceTypes(_resourceTypes.data);

                const _categories = await axios.get(`${url}/category`, axiosConfig);
                setCategories(_categories.data);
            } catch (error) {
                ErrorNotification("Failed to load resource types or categories");
            }
        };

        fetch();
    }, []);

    useEffect(() => {
        if (resourceTypes.length > 0) {
            const organizationType = resourceTypes.find(type => type.code === "organization");
            if (organizationType) {
                setTypeId(organizationType._id); // Set the type ID for "organization"
            }
        }
    }, [resourceTypes]);

    const handleSubmit = async () => {
        // Reset category error state
        setCategoryError(false);

        // Validate if a category is selected
        if (!selectedCategory) {
            setCategoryError(true);  // Set error state for category
            return; // Stop submission if validation fails
        }
        setSubmissionLoading(true);

        try {
            await axios.post(`${url}/resource`, {
                name: orgName,
                description,
                website,
                image: logoPreview,
                type: typeId,
                category: selectedCategory
            }, axiosConfig);
            SuccessNotification("Resource listed successfully!");
            handleClose();
            fetchData(); // Refresh the data
            fetchResourcesData()
        } catch (error) {
            ErrorNotification(error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed");
        } finally {
            setSubmissionLoading(false);
        }
    };

    return (
        <Modal show={show} size="lg" centered onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>List As a Resource</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" value={orgName} readOnly />
                    </Form.Group>
                    <Form.Group controlId="formDescription">
                        <Form.Label>Description</Form.Label>
                        <Form.Control as="textarea" value={description} readOnly />
                    </Form.Group>
                    <Form.Group controlId="formWebsite">
                        <Form.Label>Website</Form.Label>
                        <Form.Control type="text" value={website} readOnly />
                    </Form.Group>
                    <Form.Group controlId="formImage">
                        <Form.Label>Logo</Form.Label>
                        <div>
                            <Image
                                src={logoPreview}
                                alt="Logo Preview"
                                thumbnail
                                style={{ maxWidth: "150px", maxHeight: "150px" }} />
                        </div>

                    </Form.Group>
                    <Form.Group controlId="formType">
                        <Form.Label>Type</Form.Label>
                        <Form.Control type="text" value="Organization" readOnly />
                    </Form.Group>
                    <Form.Group controlId="formCategory">
                        <Form.Label>Category</Form.Label>
                        <Form.Control
                            as="select"
                            value={selectedCategory}
                            onChange={(e) => setSelectedCategory(e.target.value)}
                            isInvalid={categoryError}  // Add validation state
                            required
                        >
                            <option value="">Select Category</option>
                            {categories.map((category) => (
                                <option key={category._id} value={category._id}>
                                    {category.name}
                                </option>
                            ))}
                        </Form.Control>
                        {/* Feedback message for invalid category selection */}
                        <Form.Control.Feedback type="invalid">
                            Please select a category.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit} disabled={submissionLoading}>
                    {submissionLoading ? <span className="spinner-border spinner-border-sm" /> : "Add Resource"}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ListAsResourceModel;
