import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Button,
  ListGroup,
  Card,
  Badge,
} from "react-bootstrap";
import ProfileWidget from "../ProfileWidget";
import StatWidget from "../StatWidget";
import FeatherIcon from "feather-icons-react";
import AddSkillModal from "../../../../Components/accountModels/AddSkillModal";
import AddExternalLinkModal from "../../../../Components/accountModels/AddExternalLinkModal";
import AddStartupTypeModal from "../../../../Components/accountModels/AddStartupTypeModal";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../../../axios";
import axiosInstance from "../../../../axios";
import FullScreenSpinner from "../../../../Components/FullScreenSpinner/FullScreenSpinner";
import { ErrorNotification, SuccessNotification } from "../../../../notifications/notifications"

const url = process.env.REACT_APP_BE_URL;

const DashboardPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isMainUser, setIsMainUser] = useState(true);

  const { userId } = useParams();

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    if (userInfo && userInfo._id !== userId) {
      setIsMainUser(false);
    } else {
      setIsMainUser(true);
    }
  }, [userId]);

  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    interesetedIn: "",
    pic: "",
  });

  const [projects, setProjects] = useState([]);
  const [projectsByUser, setProjectsByUser] = useState([]);
  const [organizationsByUser, setOrganizationsByUser] = useState([]);
  const [resourcesByUser, setResourcesByUser] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [equity, setEquity] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);

  //const [affiliationsList, setAffiliationsList] = useState([]);
  const [skillsList, setSkillsList] = useState([]);
  const [linksList, setLinksList] = useState([]);
  const [startupTypesList, setStartupTypesList] = useState([]);
  const [showSkillModal, setShowSkillModal] = useState(false);
  const [showExternalLinkModal, setShowExternalLinkModal] = useState(false);
  const [showStartupTypeModal, setShowStartupTypeModal] = useState(false);

  const [modalMode, setModalMode] = useState("add"); // State to track the mode ('add' or 'edit')
  const [currentIndex, setCurrentIndex] = useState(null); // State to store the current index being edited
  const [currentLink, setCurrentLink] = useState(null); // State to store the current link being edited

  const handleCloseSkillModal = () => setShowSkillModal(false);
  const handleShowSkillModal = () => setShowSkillModal(true);

  const handleCloseExternalLinkModal = () => {
    setShowExternalLinkModal(false);
    setCurrentLink(null);
  };
  const handleShowExternalLinkModal = (mode, link = null, index) => {
    setModalMode(mode);
    setCurrentLink(link);
    setCurrentIndex(index);
    setShowExternalLinkModal(true);
  };

  const handleCloseStartupTypeModal = () => setShowStartupTypeModal(false);
  const handleShowStartupTypeModal = () => setShowStartupTypeModal(true);

  const items = JSON.parse(localStorage.getItem("userInfo"));

  //Common Get Data
  const getData = async () => {
    setLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
            }`,
        },
      };
      // eslint-disable-next-line
      const { data, status } = await axios.get(
        `${url}/user/${userId ? userId : items._id}`,
        config
      );
      setData(data);
      setSkillsList(data.skills);
      setLinksList(data.links);
      setStartupTypesList(data.startupTypes);
    } catch (error) {
      ErrorNotification(error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed");
    } finally {
      setLoading(false);
    }
  };

  //Affiliations Function
  const getAffiliations = async () => {
    setLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token}`,
        },
      };

      // Fetch projects, organizations, and resources concurrently
      const [projectsResponse, organizationsResponse, resourcesResponse] = await Promise.all([
        axios.get(`${url}/project/user/${userId}`, config),
        axios.get(`${url}/organization/user/${userId}`, config),
        axios.get(`${url}/resource/my-resources/owned`, config),
      ]);

      // Check if each request was successful and update state accordingly
      if (projectsResponse.status === 200) {
        setProjectsByUser(projectsResponse.data);
      }

      if (organizationsResponse.status === 200) {
        setOrganizationsByUser(organizationsResponse.data); // You may need to create a state setter for organizations
      }

      if (resourcesResponse.status === 200) {
        setResourcesByUser(resourcesResponse.data); // You may need to create a state setter for resources
      }

      // Check if all responses are empty to set loading state
      if (
        projectsResponse.data?.length === 0 &&
        organizationsResponse.data?.length === 0 &&
        resourcesResponse.data?.length === 0
      ) {
        setLoading(true);
      }
    } catch (error) {
      ErrorNotification(error.response?.data?.message || error.response?.data || "Action Failed");
    } finally {
      setLoading(false);
    }
  };


  const getProjects = async () => {
    setLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
            }`,
        },
      };
      const { data } = await axios.get(
        `${url}/project?page_number=${pageNumber}`,
        config
      );

      setProjects(data);
    } catch (error) {
      ErrorNotification(error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed");
    } finally {
      setLoading(false);
    }
  };

  const getJobs = async () => {
    setLoading(true);
    let jobArr = [];
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };
    try {
      await data?.jobs?.forEach(async (jobID, index) => {
        const res = await axios.get(`${url}/job/${jobID}`, config);
        if (res.status === 200) {
          jobArr.push(res.data);
          if (data?.jobs?.length === jobArr.length) {
            if (jobArr?.length === 0) {
              setLoading(true);
            }
            return setJobs(jobArr);
          }
        }
      });
    } catch (error) {
      ErrorNotification(error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed");
    } finally {
      setLoading(false);
    }
  };

  const getUserRole = async () => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };
    try {
      const { data, status } = await axios.get(
        `${url}/equity/role/${userId}`,
        config
      );
      if (status === 200) {
        setEquity(data);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    getUserRole();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data) {
      setInitialValues({
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: data?.email,
        interesetedIn: data?.interests,
        pic: data?.profilePic.url,
      });
    }
  }, [data]);


  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [userId]);

  useEffect(() => {
    getProjects();
    // eslint-disable-next-line
  }, [userId]);

  useEffect(() => {
    getAffiliations();
    // eslint-disable-next-line
  }, [userId]);

  useEffect(() => {
    getJobs();
    // eslint-disable-next-line
  }, [data, userId]);

  const handleDelete = async (section, index) => {
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };

    let updatedList;
    let payLoad;

    switch (section) {
      case "skills":
        updatedList = [...skillsList];
        updatedList.splice(index, 1);
        payLoad = { skills: updatedList };
        break;
      case "links":
        updatedList = [...linksList];
        updatedList.splice(index, 1);
        payLoad = { links: updatedList };
        break;
      case "startupTypes":
        updatedList = [...startupTypesList];
        updatedList.splice(index, 1);
        payLoad = { startupTypes: updatedList };
        break;
      default:
        return;
    }

    try {
      const { status } = await axiosInstance.put(
        `${process.env.REACT_APP_BE_URL}/user/${userId}`,
        payLoad,
        config
      );

      if (status === 200) {
        SuccessNotification(`${section.slice(0, -1)} deleted successfully!`);
        getData();
      }
    } catch (error) {
      ErrorNotification(error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed");
    }
  };

  if (loading) {
    return <FullScreenSpinner />;
  }

  return (
    <>
      <section className="position-relative overflow-hidden bg-gradient2 py-3 px-3">
        <Container className="mt-6 mt-lg-0">
          {isMainUser ? <Row>
            <Col lg={12}>
              <div className="page-title">
                <h3 className="mb-0">Hi {initialValues.firstName}</h3>
                <p className="mt-1 fw-medium">Welcome to TINT!</p>
              </div>
            </Col>
          </Row> : <Row>
            <Col lg={12}>
              <div className="page-title">
                <h3 className="mb-3">User Profile</h3>

              </div>
            </Col>
          </Row>}

          <Row>
            {/* profile widget */}
            <ProfileWidget initialValues={initialValues} isMainUser={isMainUser} />

            {/* stat widgets */}
            <Col lg={12}>
              <Row>
                {/* First row */}
                <Col lg={6}>
                  <StatWidget
                    icon="users"
                    variant="primary"
                    stats={projectsByUser.length}
                    title="Affiliations"
                  />
                </Col>
                <Col lg={6}>
                  <StatWidget
                    icon="edit"
                    variant="info"
                    stats={skillsList.length}
                    title="Skills"
                  />
                </Col>

                {/* Second row */}
                <Col lg={6}>
                  <StatWidget
                    icon="link"
                    variant="warning"
                    stats={linksList.length}
                    title="External Links"
                  />
                </Col>
                <Col lg={6}>
                  <StatWidget
                    icon="star"
                    variant="success"
                    stats={startupTypesList.length}
                    title="Startup Types"
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="mt-4">
            {/* Affiliations Section (Projects) */}
            <Col lg={12} className="mb-4">
              <Card>
                <Card.Body>
                  <Card.Title className="d-flex justify-content-between align-items-center pb-2">
                    Affiliations
                  </Card.Title>
                  <Card.Body>

                    {/* Projects List */}
                    {projectsByUser.length > 0 ? (
                      <ListGroup className="mb-4">
                        <Card.Subtitle className="mb-2 text-muted">Projects</Card.Subtitle>
                        {projectsByUser.map((item, index) => (
                          <ListGroup.Item
                            key={index}
                            onClick={() => navigate(`/projectdetails/${item._id}`)}
                          >
                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <strong>{item.name}</strong> -{" "}
                                {equity.find((rec) =>
                                  item.equityID.find((equityId) => equityId === rec._id)
                                )?.role || "member"}
                                <Badge
                                  pill
                                  bg={item.status === "published" ? "success" : "warning"}
                                  className="ms-2"
                                >
                                  {item.status}
                                </Badge>
                              </div>
                            </div>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    ) : (
                      <div className="text-center mb-4">
                        <p>No projects found.</p>
                      </div>
                    )}

                    {/* Organizations List */}
                    {organizationsByUser.length > 0 ? (
                      <ListGroup className="mb-4">
                        <Card.Subtitle className="mb-2 text-muted">Organizations</Card.Subtitle>
                        {organizationsByUser.map((org, index) => (
                          <ListGroup.Item
                            key={index}
                            onClick={() => navigate(`/organizationdetails/${org._id}`)}
                          >
                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <strong>{org.name}</strong> -{" "}
                                {equity.find((rec) =>
                                  org.equityID.find((equityId) => equityId === rec._id)
                                )?.role || "member"}
                                <Badge
                                  pill
                                  bg={org.status === "published" ? "success" : "warning"}
                                  className="ms-2"
                                >
                                  {org.status}
                                </Badge>
                              </div>
                            </div>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    ) : (
                      <div className="text-center mb-4">
                        <p>No organizations found.</p>
                      </div>
                    )}

                    {/* Resources List */}
                    {resourcesByUser.length > 0 ? (
                      <ListGroup className="mb-4">
                        <Card.Subtitle className="mb-2 text-muted">Resources</Card.Subtitle>
                        {resourcesByUser.map((resource, index) => (
                          <ListGroup.Item
                            key={index}
                            onClick={() => navigate(`/resource/${resource.code}`)}
                          >
                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <strong>{resource.name}</strong>
                                <Badge
                                  pill
                                  bg={resource.status === "approved" ? "success" : "warning"}
                                  className="ms-2"
                                >
                                  {resource.status}
                                </Badge>
                              </div>
                            </div>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    ) : (
                      <div className="text-center mb-4">
                        <p>No resources found.</p>
                      </div>
                    )}
                  </Card.Body>

                </Card.Body>
              </Card>
            </Col>

            {/* Skills Section */}
            <Col lg={4} className="mb-4">
              <Card>
                <Card.Body>
                  <Card.Title className="d-flex justify-content-between align-items-center pb-2">
                    Skills
                    {isMainUser && <Button
                      variant="outline-primary"
                      size="sm"
                      className="ms-2 p-1"
                      onClick={handleShowSkillModal}
                    >
                      <FeatherIcon icon="plus" className="icon-xs" />
                    </Button>}

                  </Card.Title>
                  {skillsList.length > 0 ? (
                    <ListGroup>
                      {skillsList.map((skill, index) => (
                        <ListGroup.Item key={index}>
                          <div className="d-flex justify-content-between align-items-center">
                            {skill}
                            {isMainUser && <Button
                              variant="outline-danger"
                              size="sm"
                              className="p-1"
                              onClick={() => handleDelete("skills", index)}
                            >
                              <FeatherIcon icon="trash-2" className="icon-xs" />
                            </Button>}

                          </div>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  ) : (
                    <div className="text-center">
                      <p>No skills found.</p>
                      {isMainUser && <Button variant="primary" onClick={handleShowSkillModal}>
                        Add New
                      </Button>}

                    </div>
                  )}
                </Card.Body>
              </Card>
            </Col>

            {/* External Links Section */}
            <Col lg={4} className="mb-4">
              <Card>
                <Card.Body>
                  <Card.Title className="d-flex justify-content-between align-items-center pb-2">
                    External Links
                    {isMainUser && <Button
                      variant="outline-primary"
                      size="sm"
                      className="ms-2 p-1"
                      onClick={() => handleShowExternalLinkModal("add")}
                    >
                      <FeatherIcon icon="plus" className="icon-xs" />
                    </Button>}

                  </Card.Title>
                  {linksList.length > 0 ? (
                    <ListGroup>
                      {linksList.map((link, index) => (
                        <ListGroup.Item key={index}>
                          <div className="d-flex justify-content-between align-items-center">
                            <a
                              href={link.links}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {link.linkDescription}
                            </a>
                            {isMainUser && <div>
                              <Button
                                variant="outline-primary"
                                size="sm"
                                className="me-2 p-1"
                                onClick={() => {
                                  handleShowExternalLinkModal(
                                    "edit",
                                    link,
                                    index
                                  );
                                }}
                              >
                                <FeatherIcon icon="edit" className="icon-xs" />
                              </Button>
                              <Button
                                variant="outline-danger"
                                size="sm"
                                className="p-1"
                                onClick={() => handleDelete("links", index)}
                              >
                                <FeatherIcon
                                  icon="trash-2"
                                  className="icon-xs"
                                />
                              </Button>
                            </div>}

                          </div>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  ) : (
                    <div className="text-center">
                      <p>No external links found.</p>
                      {isMainUser && <Button
                        variant="primary"
                        onClick={() => handleShowExternalLinkModal("add")}
                      >
                        Add New
                      </Button>}

                    </div>
                  )}
                </Card.Body>
              </Card>
            </Col>

            {/* Startup Types Section */}
            <Col lg={4} className="mb-4">
              <Card>
                <Card.Body>
                  <Card.Title className="d-flex justify-content-between align-items-center pb-2">
                    Startup Types
                    {isMainUser && <Button
                      variant="outline-primary"
                      size="sm"
                      className="ms-2 p-1"
                      onClick={handleShowStartupTypeModal}
                    >
                      <FeatherIcon icon="plus" className="icon-xs" />
                    </Button>}

                  </Card.Title>
                  {startupTypesList.length > 0 ? (
                    <ListGroup>
                      {startupTypesList.map((type, index) => (
                        <ListGroup.Item key={index}>
                          <div className="d-flex justify-content-between align-items-center">
                            {type}
                            {isMainUser && <Button
                              variant="outline-danger"
                              size="sm"
                              className="p-1"
                              onClick={() =>
                                handleDelete("startupTypes", index)
                              }
                            >
                              <FeatherIcon icon="trash-2" className="icon-xs" />
                            </Button>}

                          </div>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  ) : (
                    <div className="text-center">
                      <p>No startup types found.</p>
                      {isMainUser && <Button
                        variant="primary"
                        onClick={handleShowStartupTypeModal}
                      >
                        Add New
                      </Button>}

                    </div>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Add Skill Modal */}
      <AddSkillModal
        show={showSkillModal}
        handleClose={handleCloseSkillModal}
        userId={userId}
        getData={getData}
        skillsList={skillsList}
      />

      {/* Add External Link Modal */}
      <AddExternalLinkModal
        mode={modalMode}
        show={showExternalLinkModal}
        handleClose={handleCloseExternalLinkModal}
        userId={userId}
        getData={getData}
        currentLink={currentLink}
        index={currentIndex}
        data={data}
      />

      {/* Add Startup Type Modal */}
      <AddStartupTypeModal
        show={showStartupTypeModal}
        handleClose={handleCloseStartupTypeModal}
        userId={userId}
        getData={getData}
        startupTypesList={startupTypesList}
      />
    </>
  );
};

export default DashboardPage;
