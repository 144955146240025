import React, { useState } from "react";
import {
  Grid,
  Button,
  TextField,
  Typography,
  Alert,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { Formik, Form } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../axios";
import Drawer from "../Components/Drawer";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { clearFormData } from "../store/actions/jobActions";

const url = process.env.REACT_APP_BE_URL;

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.gray,
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}));

let initialValues3 = {
  skills: "",
  starupTypes: "",
};

const TitleStyles = {
  backgroundColor: "#fff",
  color: "#000",
  fontWeight: "800",
  display: "flex",
  fontSize: "2rem",
  marginTop: "3rem",
  marginBottom: "1rem",
};

const fieldStyles = {
  width: { xs: 315, sm: 398 },
  marginBottom: "10px",
};

const formStyles = {
  display: "flex",
  flexDirection: "column",
};

const SubTitleHeaders = {
  fontWeight: "600",
  fontSize: "1rem",
  marginTop: "2rem",
};

const skills = [
  "HTML",
  "CSS",
  "Javascript",
  "Typescript",
  "NodeJS",
  "MongoDB",
  "MySQL",
];

function Skills() {
  const { state } = useLocation();
  const { id } = state;

  const dispatch = useDispatch();
  const data = useSelector((state) => state.job.jobDetails);

  const [open, setOpen] = useState(true);

  function handleClose() {
    setOpen(false);
    setAlert({
      ...alert,
      showAlert: false,
      severity: "",
      message: "",
    });
  }

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "success",
    message: "",
  });

  const [btnDisabled, setbtnDisabled] = React.useState(false);

  const navigate = useNavigate();

  const [value, setValue] = useState([]);
  let jobData = { ...data };

  const handleSubmit3 = async () => {
    setbtnDisabled(true);
    try {
      jobData.skills = value;

      // Add organizationID or projectID based on state
      if (id.organizationID) {
        jobData.organizationID = id.organizationID;
      } else if (id.projectID) {
        jobData.projectID = id.projectID;
      }
    } catch (error) {
      console.log(error);
    }

    console.log("jobData", jobData);

    const config = {
      headers: {
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("userInfo")).token
        }`,
      },
    };

    try {
      const { data, status } = await axiosInstance.post(
        `${url}/job`,
        jobData,
        config
      );

      if (status === 200) {
        dispatch(clearFormData());

        setAlert({
          showAlert: true,
          severity: "success",
          message: data,
        });
        if (id.organizationID) {
          setTimeout(() => {
            navigate(`/organizationdetails/${id.organizationID}`);
            setbtnDisabled(false);
          }, 3000);
        } else if (id.projectID) {
          setTimeout(() => {
            navigate(`/projectdetails/${id.projectID}`);
            setbtnDisabled(false);
          }, 3000);
        }
      }
      localStorage.removeItem("JOB");
    } catch (error) {
      setbtnDisabled(false);
      setAlert({
        showAlert: true,
        severity: "error",
        message: `${
          error.response.data.message
            ? error.response.data.message
            : error.response.data
            ? error.response.data
            : "Action Failed"
        }`,
      });

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "success",
          message: "",
        });
      }, 16000);
    }
  };

  return (
    <>
      <Drawer />
      <Grid container justifyContent="center" sx={{ paddingTop: "40px" }}>
        <Snackbar
          open={alert.showAlert}
          autoHideDuration={15000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            severity={alert.severity}
            onClose={handleClose}
            sx={{
              fontSize: "16px",
              overflowWrap: "break-word",
              marginTop: "4rem",
              marginRight: {
                lg: "2rem",
                md: "2rem",
                sm: "1rem",
              },
            }}
          >
            {alert.message}
          </Alert>
        </Snackbar>

        <Grid item style={TitleStyles}>
          Step 3
        </Grid>
        <Grid container item direction="column" alignItems="center">
          <Formik initialValues={initialValues3} onSubmit={handleSubmit3}>
            <Form style={formStyles}>
              <Typography color="primary" style={SubTitleHeaders}>
                Skills
              </Typography>

              <Autocomplete
                multiple
                options={skills}
                defaultValue={[skills[0]]}
                renderInput={(params) => (
                  <LightTooltip
                    title="Type and press enter to add custom values"
                    placement="top-start"
                  >
                    <TextField
                      sx={fieldStyles}
                      {...params}
                      size="small"
                      variant="outlined"
                    />
                  </LightTooltip>
                )}
                value={value}
                freeSolo
                onChange={(event, newValue) => setValue(newValue)}
              />

              <Grid item>
                <Button
                  sx={{
                    borderRadius: "8px",
                    fontSize: "1rem",
                    fontWeight: "700",
                    height: "2.5rem",
                    marginTop: "1rem",
                  }}
                  disabled={btnDisabled}
                  color="primary"
                  fullWidth
                  variant="contained"
                  type="submit"
                  size="large"
                >
                  {btnDisabled ? <CircularProgress size={20} /> : "Submit"}
                </Button>
              </Grid>
            </Form>
          </Formik>
        </Grid>
      </Grid>
    </>
  );
}

export default Skills;
