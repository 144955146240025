import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axiosInstance from "../../axios";
import { ErrorNotification, SuccessNotification } from "../../notifications/notifications";

const predefinedQualifications = [
  "Teamwork",
  "Multitasking",
  "Decision-making",
  "Self-starting ability",
  "Strategizing",
];

const QualificationsModal = ({
  show,
  handleClose,
  jobId,
  getData,
  qualifications,
}) => {
  const [selectedQualifications, setSelectedQualifications] = useState([]);
  const [newQualification, setNewQualification] = useState(""); // New qualification input
  const [allQualifications, setAllQualifications] = useState([...predefinedQualifications]); // Combined list
  const [validated, setValidated] = useState(false);
  const [submissionLoading, setSubmissionLoading] = useState(false);

  // Initialize selected qualifications and merge with predefined ones
  useEffect(() => {
    if (qualifications) {
      const uniqueQualifications = [...new Set([...predefinedQualifications, ...qualifications])]; // Merge and remove duplicates
      setAllQualifications(uniqueQualifications);
      setSelectedQualifications(qualifications);
    }
  }, [qualifications]);

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setSelectedQualifications((prev) => [...prev, value]);
    } else {
      setSelectedQualifications((prev) =>
        prev.filter((qualification) => qualification !== value)
      );
    }
  };

  const handleNewQualificationChange = (e) => {
    setNewQualification(e.target.value);
  };

  const handleAddNewQualification = () => {
    if (newQualification && !allQualifications.includes(newQualification)) {
      setAllQualifications((prev) => [...prev, newQualification]);
      setSelectedQualifications((prev) => [...prev, newQualification]);
      setNewQualification(""); // Clear input after adding
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission
      handleAddNewQualification(); // Add qualification on Enter
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedQualifications.length > 0) {
      setSubmissionLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token}`,
        },
      };

      try {
        const payLoad = { qualifications: selectedQualifications };

        const res = await axiosInstance.put(
          `${process.env.REACT_APP_BE_URL}/job/${jobId}`,
          payLoad,
          config
        );

        if (res.status === 200) {
          SuccessNotification(res.data?.message || "Qualifications updated successfully!");
          getData();
          handleClose();
        }
      } catch (error) {
        ErrorNotification(error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed");

      } finally {
        setSubmissionLoading(false);
      }
    } else {
      setValidated(true);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size="lg"
      backdrop="static"
      animation={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Qualifications</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group controlId="formQualifications">
            <Form.Label>Select Qualifications</Form.Label>
            {allQualifications.map((qualification, index) => (
              <Form.Check
                type="checkbox"
                key={qualification}
                id={`qualification-${index}`}
                value={qualification}
                label={qualification}
                onChange={handleCheckboxChange}
                checked={selectedQualifications.includes(qualification)}
              />
            ))}
            <Form.Label>Add New Qualification</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter new qualification"
              value={newQualification}
              onChange={handleNewQualificationChange}
              onKeyPress={handleKeyPress} // Capture Enter keypress
            />
            <Button
              variant="primary"
              className="mt-2"
              size="sm"
              onClick={handleAddNewQualification}
            >
              Add New Qualification
            </Button>
          </Form.Group>
          {validated && selectedQualifications.length === 0 && (
            <div className="text-danger">
              Please select at least one qualification.
            </div>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={handleSubmit}
          disabled={submissionLoading}
        >
          {submissionLoading ? (
            <span className="spinner-border spinner-border-sm" />
          ) : (
            "Add Qualifications"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default QualificationsModal;
