import { Container, Row, Col, Card } from "react-bootstrap";
import NavbarComponent from "../Components/navbar/Navbar";
import Footer from "../Components/footer/Footer";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  return (
    <>
      <div className="header-7 bg-gradient2">
        <NavbarComponent
          navClass="navbar-light zindex-10"
          isSticky={false}
          fixedWidth
          hideSearch
          buttonClass="btn-primary btn-sm"
        />
        <section className="hero-4 pt-lg-6 pb-sm-9 pb-6 pt-9">
          <Container>
            <Row className="justify-content-center">
              <Col lg={7} className="text-center">
                <h1 className="hero-title mb-0">Privacy Policy</h1>
                <p className="mb-4 fs-17 text-muted">
                  Learn more about our privacy practices.
                </p>
              </Col>
            </Row>
          </Container>
          <div className="shape bottom">
            <svg
              width="1440px"
              height="40px"
              viewBox="0 0 1440 40"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                id="shape-b"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g id="curve" fill="#fff">
                  <path
                    d="M0,30.013 C239.659,10.004 479.143,0 718.453,0 C957.763,0 1198.28,10.004 1440,30.013 L1440,40 L0,40 L0,30.013 Z"
                    id="Path"
                  ></path>
                </g>
              </g>
            </svg>
          </div>
        </section>
      </div>

      <section className="section pb-lg-7 py-4 position-relative">
        <Container>
          <Row className="justify-content-center">
            <Col lg={10}>
              <Card className="shadow-none">
                <Card.Body className="p-xl-5 p-0">
                  <h2 className="mb-4 mt-0 fw-medium">Our Privacy Policy</h2>
                  <p className="mb-4">
                    This Privacy Policy describes how your personal information
                    is collected, used, and shared when you visit or make a
                    purchase from our site.
                  </p>

                  <h4 className="mt-4">Personal Information We Collect</h4>
                  <p className="mb-3">
                    When you visit the site, we automatically collect certain
                    information about your device, including information about
                    your web browser, IP address, time zone, and some of the
                    cookies that are installed on your device. Additionally, as
                    you browse the site, we collect information about the
                    individual web pages or products that you view, what
                    websites or search terms referred you to the site, and
                    information about how you interact with the site.
                  </p>

                  <h4 className="mt-4">How We Use Your Personal Information</h4>
                  <p className="mb-3">
                    We use the Order Information that we collect generally to
                    fulfill any orders placed through the site (including
                    processing your payment information, arranging for shipping,
                    and providing you with invoices and/or order confirmations).
                    Additionally, we use this Order Information to:
                  </p>
                  <ul>
                    <li>Communicate with you;</li>
                    <li>Screen our orders for potential risk or fraud; and</li>
                    <li>
                      When in line with the preferences you have shared with us,
                      provide you with information or advertising relating to
                      our products or services.
                    </li>
                  </ul>

                  <h4 className="mt-4">Sharing Your Personal Information</h4>
                  <p className="mb-3">
                    We share your Personal Information with third parties to
                    help us use your Personal Information, as described above.
                    For example, we use Google Analytics to help us understand
                    how our customers use the site.
                  </p>

                  <h4 className="mt-4">Your Rights</h4>
                  <p className="mb-3">
                    If you are a European resident, you have the right to access
                    personal information we hold about you and to ask that your
                    personal information be corrected, updated, or deleted. If
                    you would like to exercise this right, please contact us
                    through the contact information below.
                  </p>

                  <h4 className="mt-4">Data Retention</h4>
                  <p className="mb-3">
                    When you place an order through the site, we will maintain
                    your Order Information for our records unless and until you
                    ask us to delete this information.
                  </p>

                  <h4 className="mt-4">Changes</h4>
                  <p className="mb-3">
                    We may update this privacy policy from time to time in order
                    to reflect, for example, changes to our practices or for
                    other operational, legal, or regulatory reasons.
                  </p>

                  <p className="mt-4">
                    For more information about our privacy practices, if you
                    have questions, or if you would like to make a complaint,
                    please contact us by e-mail at
                    <Link to="#"> privacy@tint.com </Link>
                    or by mail using the details provided below:
                  </p>

                  <address className="mt-4">
                    565 Broom Street, New York, NY
                  </address>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer />
    </>
  );
};

export default PrivacyPolicy;
