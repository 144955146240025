import { Container, Row, Col, Card } from "react-bootstrap";
import NavbarComponent from "../Components/navbar/Navbar";
import Footer from "../Components/footer/Footer";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  return (
    <>
      <div className="header-7 bg-gradient2">
        <NavbarComponent
          navClass="navbar-light zindex-10"
          isSticky={false}
          fixedWidth
          hideSearch
          buttonClass="btn-primary btn-sm"
        />
        <section className="hero-4 pt-lg-6 pb-sm-9 pb-6 pt-9">
          <Container>
            <Row className="justify-content-center">
              <Col lg={7} className="text-center">
                <h1 className="hero-title mb-0">Terms and Conditions</h1>
                <p className="mb-4 fs-17 text-muted">
                  Read our terms and conditions before using our services.
                </p>
              </Col>
            </Row>
          </Container>
          <div className="shape bottom">
            <svg
              width="1440px"
              height="40px"
              viewBox="0 0 1440 40"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                id="shape-b"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g id="curve" fill="#fff">
                  <path
                    d="M0,30.013 C239.659,10.004 479.143,0 718.453,0 C957.763,0 1198.28,10.004 1440,30.013 L1440,40 L0,40 L0,30.013 Z"
                    id="Path"
                  ></path>
                </g>
              </g>
            </svg>
          </div>
        </section>
      </div>

      <section className="section pb-lg-7 py-4 position-relative">
        <Container>
          <Row className="justify-content-center">
            <Col lg={10}>
              <Card className="shadow-none">
                <Card.Body className="p-xl-5 p-0">
                  <h2 className="mb-4 mt-0 fw-medium">Terms and Conditions</h2>
                  <p className="mb-4">
                    These Terms and Conditions ("Terms") govern your use of our
                    website and services. By accessing or using our services,
                    you agree to be bound by these Terms.
                  </p>

                  <h4 className="mt-4">1. Introduction</h4>
                  <p className="mb-3">
                    Welcome to TINT. By accessing or using our website and
                    services, you agree to comply with and be bound by these
                    Terms and Conditions.
                  </p>

                  <h4 className="mt-4">2. Use of Our Services</h4>
                  <p className="mb-3">
                    You must be at least 18 years old to use our services. You
                    agree to use our services only for lawful purposes and in
                    accordance with these Terms.
                  </p>

                  <h4 className="mt-4">3. Intellectual Property</h4>
                  <p className="mb-3">
                    All content and materials on our website are protected by
                    intellectual property laws. You may not use, reproduce, or
                    distribute any content from our website without our express
                    written permission.
                  </p>

                  <h4 className="mt-4">4. User Responsibilities</h4>
                  <p className="mb-3">
                    You are responsible for maintaining the confidentiality of
                    your account and for all activities that occur under your
                    account. You agree to notify us immediately of any
                    unauthorized use of your account.
                  </p>

                  <h4 className="mt-4">5. Limitation of Liability</h4>
                  <p className="mb-3">
                    To the fullest extent permitted by law, TINT shall not be
                    liable for any indirect, incidental, special, or
                    consequential damages arising out of or in connection with
                    your use of our services.
                  </p>

                  <h4 className="mt-4">6. Changes to Terms</h4>
                  <p className="mb-3">
                    We may update these Terms from time to time. Your continued
                    use of our services following any changes signifies your
                    acceptance of the updated Terms.
                  </p>

                  <h4 className="mt-4">7. Governing Law</h4>
                  <p className="mb-3">
                    These Terms shall be governed by and construed in accordance
                    with the laws of the jurisdiction in which TINT is based,
                    without regard to its conflict of law principles.
                  </p>

                  <h4 className="mt-4">8. Contact Us</h4>
                  <p className="mb-3">
                    If you have any questions about these Terms, please contact
                    us at <Link to="#">support@tint.com</Link>.
                  </p>

                  <address className="mt-4">
                    565 Broom Street, New York, NY
                  </address>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer />
    </>
  );
};

export default TermsAndConditions;
