import React, { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import axios from "../../axios";
import { ErrorNotification, SuccessNotification } from "../../notifications/notifications";

// components
import FormInput from "../../Components/form/FormInput";
import VerticalForm from "../../Components/form/VerticalForm";

import AuthLayout from "./AuthLayout";
import { CircularProgress } from "@mui/material";

const AuthPage = () => {
  const { t } = useTranslation();
  const [callBack, setCallBack] = useState(false);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const navigate = useNavigate();


  // Validation schema for reset password
  const resetPasswordSchema = yupResolver(
    yup.object().shape({
      newPassword: yup
        .string()
        .required(t("Please enter a new password"))
        .min(6, t("Password must be at least 6 characters long")),
    })
  );

  // Validation schema for forgot password
  const forgotPasswordSchema = yupResolver(
    yup.object().shape({
      email: yup
        .string()
        .required(t("Please enter Email"))
        .email(t("Please enter a valid Email")),
    })
  );

  // Handle forgot password form submission
  const onForgotPasswordSubmit = async (formData) => {
    try {
      setCallBack(true);
      const { data, status } = await axios.post(
        "user/sendPasswordToken",
        formData
      );

      if (status === 200) {
        SuccessNotification("Please check your mail inbox, password reset link has been sent!");
      }
      setCallBack(false);
    } catch (error) {
      setCallBack(false);
      ErrorNotification(error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed");
    }
  };

  // Handle reset password form submission
  const onResetPasswordSubmit = async (formData) => {
    try {
      setCallBack(true);
      const { data, status } = await axios.put(`user/resetPassword/${id}`, { password: formData.newPassword });

      if (status === 200) {
        SuccessNotification("Your password has been reset successfully!");
        navigate('/signin');
      }
      setCallBack(false);
    } catch (error) {
      setCallBack(false);
      ErrorNotification(error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed");
    }
  };

  return (
    <AuthLayout
      bottomLinks={
        !id && (
          <p className="text-muted">
            {t("Back to")}
            <Link to="/signin" className="text-primary fw-semibold ms-1">
              {t("Log In")}
            </Link>
          </p>
        )
      }
    >
      {id ? (
        <>
          <h6 className="h5 mb-0 mt-3">{t("Reset Password")}</h6>
          <p className="text-muted mt-1 mb-4">
            {t("Enter your new password below to reset it.")}
          </p>

          <VerticalForm
            onSubmit={onResetPasswordSubmit}
            resolver={resetPasswordSchema}
            defaultValues={{}}
          >
            <FormInput
              type="password"
              name="newPassword"
              label={t("New Password")}
              placeholder={t("Create a new strong password (min. 6 characters)")}
              containerClass={"mb-3"}
            />
            <div className="mb-0 text-center pt-3 d-grid">
              <Button type="submit">
                {callBack ? (
                  <CircularProgress size={20} sx={{ color: "white" }} />
                ) : (
                  t("Reset Password")
                )}
              </Button>
            </div>
          </VerticalForm>
        </>
      ) : (
        <>
          <h6 className="h5 mb-0 mt-3">{t("Forgot Password")}</h6>
          <p className="text-muted mt-1 mb-4">
            {t("Enter your email address and we'll send you an email with instructions to reset your password.")}
          </p>

          <VerticalForm
            onSubmit={onForgotPasswordSubmit}
            resolver={forgotPasswordSchema}
            defaultValues={{}}
          >
            <FormInput
              type="email"
              name="email"
              label={t("Email")}
              placeholder={t("Email")}
              containerClass={"mb-3"}
            />
            <div className="mb-0 text-center pt-3 d-grid">
              <Button type="submit">
                {callBack ? (
                  <CircularProgress size={20} sx={{ color: "white" }} />
                ) : (
                  t("Submit")
                )}
              </Button>
            </div>
          </VerticalForm>
        </>
      )}
    </AuthLayout>
  );
};

export default AuthPage;
