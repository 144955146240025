import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";

const AccessDenied = () => {
  return (
    <section className="d-flex align-items-center min-vh-100 bg-light">
      <Container className="text-center">
        <Row>
          <Col>
            <div className="d-flex flex-column align-items-center">
              <div className="display-1 fw-bold text-danger mb-4">403</div>
              <h1 className="mb-3">Access Denied</h1>
              <p className="text-muted mb-4">
                You do not have permission to view this page. Please check your
                credentials or contact the administrator.
              </p>
              <Link to="/">
                <Button
                  variant="danger"
                  className="d-flex align-items-center px-4 py-2 rounded-pill"
                >
                  <FeatherIcon icon="arrow-left" className="me-2" />
                  Go Back Home
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AccessDenied;
