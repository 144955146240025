import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import axiosInstance from "../../axios";
import { SuccessNotification, ErrorNotification } from "../../notifications/notifications";

const url = process.env.REACT_APP_BE_URL;

const AddOrganizationModal = ({
  show,
  onHide,
  getOrganizations,
  getTotalPageCount,
}) => {
  const [organizationName, setOrganizationName] = useState("");
  const [industry, setIndustry] = useState("");
  const [description, setDescription] = useState("");
  const [website, setWebsite] = useState("");
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [logoPreview, setLogoPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({
    organizationName: "",
    industry: "",
    description: "",
    website: "",
  });
  const [touched, setTouched] = useState({
    organizationName: false,
    industry: false,
    description: false,
    website: false,
  });

  // New states for "List as Resource"
  const [listAsResource, setListAsResource] = useState(false);
  const [resourceTypes, setResourceTypes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [typeId, setTypeId] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [resourceLoading, setResourceLoading] = useState(false);

  // Validation states
  const [categoryError, setCategoryError] = useState(false);

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token}`,
    },
  };

  // Fetch categories for "List as Resource"
  useEffect(() => {
    if (listAsResource) {
      const fetchCategories = async () => {
        try {

          const _resourceTypes = await axiosInstance.get(`${url}/resource-type`, axiosConfig);
          setResourceTypes(_resourceTypes.data);

          const _categories = await axiosInstance.get(`${url}/category`, axiosConfig);
          setCategories(_categories.data);
        } catch (error) {
          ErrorNotification("Failed to load resource types or categories");
        }
      };

      fetchCategories();
    }
  }, [listAsResource]);

  useEffect(() => {
    if (resourceTypes.length > 0) {
      const organizationType = resourceTypes.find(type => type.code === "organization");
      if (organizationType) {
        setTypeId(organizationType._id); // Set the type ID for "organization"
      }
    }
  }, [resourceTypes]);

  useEffect(() => {
    if (Object.values(touched).includes(true)) {
      validateForm();
    }
  }, [organizationName, industry, description, website, selectedLogo, touched]);

  const validateForm = () => {
    const websitePattern = /^(http:\/\/|https:\/\/)/;
    setFormErrors({
      organizationName: organizationName ? "" : "Organization Name is required",
      industry: industry ? "" : "Industry is required",
      description: description ? "" : "Description is required",
      website: website && !websitePattern.test(website)
        ? "URL needs to contain either http:// or https://"
        : "",
    });
  };

  const handleInputChange = (setter) => (e) => {
    setter(e.target.value);
    setTouched((prev) => ({ ...prev, [e.target.name]: true }));
  };

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setSelectedLogo(reader.result);
        setLogoPreview(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleRemoveLogo = () => {
    setSelectedLogo(null);
    setLogoPreview(null);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setTouched({
      organizationName: true,
      industry: true,
      description: true,
      website: true,
    });

    validateForm();

    // Reset category error state
    setCategoryError(false);

    // Validate if a category is selected
    if (!selectedCategory) {
      setCategoryError(true);  // Set error state for category
      return; // Stop submission if validation fails
    }

    if (
      !organizationName ||
      !industry ||
      !description ||
      !website
    ) {
      return;
    }

    if (
      formErrors.organizationName ||
      formErrors.industry ||
      formErrors.description ||
      formErrors.website
    ) {
      return;
    }

    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const payload = {
      name: organizationName,
      industry,
      description,
      website,
      logo: selectedLogo,
      users: [userInfo._id],
    };

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    setLoading(true);

    try {
      const res = await axiosInstance.post(
        `${process.env.REACT_APP_BE_URL}/organization`,
        payload,
        config
      );

      if (res.status === 200) {
        SuccessNotification(res.data?.message ? res.data?.message : res.data ? res.data : "Organization added successfully!");
        getOrganizations();
        getTotalPageCount();

        // If "List as Resource" is enabled, list the organization as a resource
        if (listAsResource && selectedCategory) {
          await listOrganizationAsResource();
        }
        handleClose();
      }
    } catch (error) {
      console.log(error);
      ErrorNotification(error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed");
    } finally {
      setLoading(false);
    }
  };

  const listOrganizationAsResource = async () => {
    setResourceLoading(true);

    try {
      const payload = {
        name: organizationName,
        description,
        website,
        image: selectedLogo, // Assuming selectedLogo is used as the image
        type: typeId,
        category: selectedCategory,
      };

      await axiosInstance.post(`${process.env.REACT_APP_BE_URL}/resource`, payload, axiosConfig);
      SuccessNotification("Organization listed as a Resource successfully!");
    } catch (error) {
      ErrorNotification(error.response?.data?.message || "Failed to list organization as a resource");
    } finally {
      setResourceLoading(false);
    }
  };

  const handleClose = () => {
    setOrganizationName("");
    setIndustry("");
    setDescription("");
    setWebsite("");
    setSelectedLogo(null);
    setLogoPreview(null);
    setTouched({
      organizationName: false,
      industry: false,
      description: false,
      website: false,
    });
    setListAsResource(false);
    setSelectedCategory("");
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      dialogClassName="modal-dialog-scrollable"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Add an Organization</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          maxHeight: "70vh", // Set maximum height for modal body
          overflowY: "auto", // Enable vertical scrolling
        }}
      >
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Organization Name <span className="text-danger">*</span></Form.Label>
            <Form.Control
              type="text"
              name="organizationName"
              value={organizationName}
              onChange={handleInputChange(setOrganizationName)}
              isInvalid={touched.organizationName && !!formErrors.organizationName}
              required
            />
            <Form.Control.Feedback type="invalid">
              {formErrors.organizationName}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Industry <span className="text-danger">*</span></Form.Label>
            <Form.Select
              name="industry"
              value={industry}
              onChange={handleInputChange(setIndustry)}
              isInvalid={touched.industry && !!formErrors.industry}
              required
            >
              <option value="">Select Industry</option>
              <option value="Construction">Construction</option>
              <option value="Energy">Energy</option>
              <option value="Materials">Materials</option>
              <option value="Industrials">Industrials</option>
              <option value="Healthcare">Healthcare</option>
              <option value="Financials">Financials</option>
              <option value="Information Technology">
                Information Technology
              </option>
              <option value="Real Estate">Real Estate</option>
              <option value="Communication Services">
                Communication Services
              </option>
              <option value="Utilities">Utilities</option>
              <option value="Other">Other</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {formErrors.industry}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Organization Description <span className="text-danger">*</span></Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="description"
              value={description}
              onChange={handleInputChange(setDescription)}
              isInvalid={touched.description && !!formErrors.description}
              required
            />
            <Form.Control.Feedback type="invalid">
              {formErrors.description}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Website</Form.Label>
            <Form.Control
              type="url"
              name="website"
              value={website}
              onChange={handleInputChange(setWebsite)}
              isInvalid={touched.website && !!formErrors.website}
            />
            <Form.Control.Feedback type="invalid">
              {formErrors.website}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Organization Logo (optional)</Form.Label>
            <Form.Control type="file" onChange={handleImageChange} />
            {logoPreview && (
              <div className="mt-3 text-center">
                <img
                  src={logoPreview}
                  alt="Logo Preview"
                  className="img-fluid mb-2"
                  style={{ maxHeight: "150px" }}
                />
                <br />
                <Button variant="danger" onClick={handleRemoveLogo}>
                  Remove
                </Button>
              </div>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Check
              type="switch"
              id="list-as-resource"
              label="List the Organization as a Resource"
              checked={listAsResource}
              onChange={(e) => setListAsResource(e.target.checked)}
            />
          </Form.Group>

          {/* Show category selection if listing as a resource */}
          {listAsResource && (
            <Form.Group controlId="formCategory">
              <Form.Label>Category</Form.Label>
              <Form.Control
                as="select"
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
                isInvalid={categoryError}  // Add validation state
                required
              >
                <option value="">Select Category</option>
                {categories.map((category) => (
                  <option key={category._id} value={category._id}>
                    {category.name}
                  </option>
                ))}
              </Form.Control>
              {/* Feedback message for invalid category selection */}
              <Form.Control.Feedback type="invalid">
                Please select a category.
              </Form.Control.Feedback>
            </Form.Group>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit} disabled={loading}>
          {loading ? (
            <Spinner animation="border" size="sm" />
          ) : (
            "Add Organization"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddOrganizationModal;
