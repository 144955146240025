import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../../../../axios";
import FeatherIcon from "feather-icons-react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Spinner,
  Modal,
  Form,
  Dropdown,
  Alert,
} from "react-bootstrap";
import defaultImg from "../../../../../assets/default-resource-img.png";
import { ErrorNotification, SuccessNotification } from "../../../../../notifications/notifications";

const url = process.env.REACT_APP_BE_URL;

const MyResources = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const initialFormValues = {
    name: "",
    description: "",
    website: "",
    image: "",
    type: "",
    category: "",
  };

  const navigate = useNavigate();
  const [resources, setResources] = useState([]);
  const [resourceTypes, setResourceTypes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [typeFilter, setTypeFilter] = useState("owned");
  const [formValues, setFormValues] = useState(initialFormValues);
  const [update, setUpdate] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const [submissionLoading, setSubmissionLoading] = useState(false);

  console.log(resources);


  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token}`,
    },
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchResources = async (filter) => {
    const _resources = await axios.get(
      `${url}/resource/my-resources/${filter}`,
      axiosConfig
    );
    setResources(_resources.data);
  };

  const fetchData = async () => {
    await fetchResources("owned");

    const _resourceTypes = await axios.get(`${url}/resource-type`, axiosConfig);
    setResourceTypes(_resourceTypes.data);

    const _categories = await axios.get(`${url}/category`, axiosConfig);
    setCategories(_categories.data);

    setLoading(false);
  };

  console.log("resourceTypes", resourceTypes);
  console.log("categories", categories);


  const handleTypeFilterChange = (filter) => {
    setTypeFilter(filter);
    fetchResources(filter);
  };

  const handleFormChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormValues((prevState) => ({
          ...prevState,
          image: reader.result,
        }));
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleClickEdit = (resource) => {
    setFormValues({
      ...resource,
      type: resource.type,  // Ensure correct binding for type
      category: resource.category,  // Ensure correct binding for category
      image: resource.image.url,  // Handle image URL for preview
    });
    setUpdate(true);
    setShowModal(true);
  };

  const validateForm = () => {
    let errors = {};
    const websitePattern = /^(http:\/\/|https:\/\/)/;
    if (!formValues.name.trim()) {
      errors.name = "Name is required";
    }
    if (!formValues.type) {
      errors.type = "Type is required";
    }
    if (!formValues.category) {
      errors.category = "Category is required";
    }
    // Validate website only if it's provided (optional)
    if (formValues.website && !websitePattern.test(formValues.website)) {
      errors.website = "URL needs to contain either http:// or https://";
    }

    setValidationErrors(errors);

    return Object.keys(errors).length === 0;
  };


  const handleSubmit = async () => {
    setSubmissionLoading(true);
    if (!validateForm()) {
      setSubmissionLoading(false);
      return;
    }

    try {
      if (update) {
        await axios.put(
          `${url}/resource/${formValues._id}`,
          formValues,
          axiosConfig
        );
      } else {
        await axios.post(`${url}/resource`, formValues, axiosConfig);
      }
      setUpdate(false);
      setFormValues(initialFormValues);
      fetchData();
      if (update) {
        SuccessNotification("Resources updated successfully!");
      } else {
        SuccessNotification("Resources added successfully!");
      }

      setShowModal(false);
    } catch (error) {
      ErrorNotification(error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed");
    } finally {
      setSubmissionLoading(false);
    }
  };

  return (
    <section className="position-relative overflow-hidden bg-light py-3 px-3">
      <Container className="min-vh-100 d-flex flex-column mt-6 mt-lg-0">
        <Row className="mb-4 justify-content-between align-items-center">
          <Col xs="auto">
            <h2 className="fw-bold">My Resources</h2>
          </Col>
          <Col xs="auto">
            <div className="d-flex flex-row">
              <Button
                className="btn-soft-primary me-2"
                onClick={() => {
                  setFormValues(initialFormValues);
                  setUpdate(false);
                  setShowModal(true);
                }}
              >
                <FeatherIcon icon="plus" />
                <span className="d-none d-md-inline ms-2">Add Resource</span>
              </Button>
              <Dropdown onSelect={handleTypeFilterChange}>
                <Dropdown.Toggle variant="secondary" id="type-filter-dropdown">
                  Claimed/Saved/Recommended
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="owned">Added/Claimed by me</Dropdown.Item>
                  <Dropdown.Item eventKey="saved">Saved by me</Dropdown.Item>
                  <Dropdown.Item eventKey="recommended">Recommended by me</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item eventKey="owned" >
                    Un-filter
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Col>
        </Row>

        {error && (
          <Row className="mb-4">
            <Col>
              <Alert variant="danger">{error}</Alert>
            </Col>
          </Row>
        )}
        <Row>
          {loading ? (
            <Row className="justify-content-center">
              <Spinner animation="border" />
            </Row>
          ) : resources.length > 0 ? (
            resources.map((resource) => (
              <Col
                xs={12}
                sm={6}
                md={4}
                lg={3}
                className="mb-4 d-flex align-items-stretch"
                key={resource._id}
              >
                <Card className="h-100 shadow-sm border-1 overflow-hidden transition-transform transform-scale-hover">
                  <Card.Img
                    variant="top"
                    src={resource?.image?.url || defaultImg}
                  />
                  <Card.Body>
                    <Card.Title>{resource.name}</Card.Title>
                    <Card.Text style={{ height: 64, overflow: "hidden" }}>
                      {resource.description}
                    </Card.Text>
                  </Card.Body>
                  {typeFilter === "owned" && (
                    <Card.Footer className="bg-light">
                      {/* First Row: Owner Approval */}
                      <div className="d-flex justify-content-between mb-2">
                        <div className="text-muted">
                          {resource.ownerApproval ? (
                            resource.status === "approved" ? (
                              <span className="badge bg-success">Published</span>
                            ) : (
                              <span className="badge bg-warning text-dark">Pending Approval</span>
                            )
                          ) : (
                            <span className="badge bg-secondary">Pending Claim</span>
                          )}
                        </div>
                      </div>
                      {/* Second Row: Buttons */}
                      <div className="d-flex">
                        <Button
                          variant="outline-primary"
                          size="sm"
                          className="d-flex align-items-center me-2 w-100"
                          onClick={() => handleClickEdit(resource)}
                        >
                          <FeatherIcon icon="edit" size={14} className="me-1" />
                          Edit
                        </Button>
                        <Button
                          variant="outline-secondary"
                          size="sm"
                          className="d-flex align-items-center w-100"
                          onClick={() => navigate(`/resource/${resource.code}`)} // Use navigate to route
                        >
                          <FeatherIcon icon="eye" size={14} className="me-1" />
                          View
                        </Button>
                      </div>
                    </Card.Footer>
                  )}
                </Card>
              </Col>
            ))
          ) : (
            <Row className="justify-content-center">
              <Col>
                <h4>No Resources Found</h4>
              </Col>
            </Row>
          )}
        </Row>



        <Modal show={showModal} centered onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>
              {update ? "Edit Resource" : "Add New Resource"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={formValues.name}
                  onChange={handleFormChange}
                  required
                  isInvalid={!!validationErrors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {validationErrors.name}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formDescription">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  name="description"
                  value={formValues.description}
                  onChange={handleFormChange}
                />
              </Form.Group>
              <Form.Group controlId="formWebsite">
                <Form.Label>Website</Form.Label>
                <Form.Control
                  type="text"
                  name="website"
                  value={formValues.website}
                  onChange={handleFormChange}
                  isInvalid={!!validationErrors.website} // This will trigger invalid feedback
                />
                <Form.Control.Feedback type="invalid">
                  {validationErrors.website}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formImage">
                <Form.Label>Image</Form.Label>
                <Form.Control
                  type="file"
                  onChange={handleImageChange}
                />
                {formValues.image && (
                  <img
                    src={formValues.image}
                    alt="Resource"
                    className="img-fluid mt-2"
                    style={{ maxHeight: "150px" }}
                  />
                )}
              </Form.Group>
              <Form.Group controlId="formType">
                <Form.Label>Type</Form.Label>
                <Form.Control
                  as="select"
                  name="type"
                  value={formValues.type}
                  onChange={handleFormChange}
                  required
                  isInvalid={!!validationErrors.type}
                >
                  <option value="">Select Type</option>
                  {resourceTypes.map((type) => (
                    <option key={type._id} value={type._id}>
                      {type.code}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {validationErrors.type}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formCategory">
                <Form.Label>Category</Form.Label>
                <Form.Control
                  as="select"
                  name="category"
                  value={formValues.category}
                  onChange={handleFormChange}
                  required
                  isInvalid={!!validationErrors.category}
                >
                  <option value="">Select Category</option>
                  {categories.map((category) => (
                    <option key={category._id} value={category._id}>
                      {category.name}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {validationErrors.category}
                </Form.Control.Feedback>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={handleSubmit}
              disabled={submissionLoading}
            >
              {submissionLoading ? (
                <span className="spinner-border spinner-border-sm" />
              ) : (
                update ? "Save Changes" : "Add Resource"
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </section>
  );
};

export default MyResources;
