import React, { useState, useEffect } from "react";
import { Modal, Button, Spinner, Badge, Container, Row, Col, Pagination } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import axios from "../../axios";
import { SuccessNotification, ErrorNotification } from "../../notifications/notifications";
import RequestModal from "../reqModel/RequestModal";

const url = process.env.REACT_APP_BE_URL;

const dummyRequests = [
  {
    _id: "1",
    organizationName: "Organization A",
    type: "Type 1",
    status: "pending",
    approvedCount: ["user1", "user2"], // List of user IDs who have approved
    rejectedApprovals: ["user3"], // List of user IDs who have rejected
    reqApprovCount: ["user1", "user2", "user4"], // Total list of required approvals
    changeData: {
      field1: "Old Value",
      field2: "New Value",
      // Include other fields and changes here
    }
  },
  {
    _id: "2",
    organizationName: "Organization B",
    type: "Type 2",
    status: "approved",
    approvedCount: ["user1", "user2"], // List of user IDs who have approved
    rejectedApprovals: ["user3"], // List of user IDs who have rejected
    reqApprovCount: ["user1", "user2", "user4"], // Total list of required approvals
    changeData: {
      field1: "Old Value",
      field2: "New Value",
      // Include other fields and changes here
    }
  },
];

const ApprovalRequestsModal = ({ show, onHide, handleShowRequestModal }) => {
  const [requests, setRequests] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const userID = JSON.parse(localStorage.getItem("userInfo"))._id;

  const fetchRequests = async () => {
    setLoading(true);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token}`,
        },
      };
      const { data } = await axios.get(`${url}/request/${userID}?page_number=${pageNumber}`, config);
      setRequests(data); // Replace with actual data: setRequests(data);
      setLoading(false);
    } catch (error) {
      ErrorNotification("Failed to load requests.");
      setLoading(false);
    }
  };

  const fetchTotalPages = async () => {
    try {
      const { data } = await axios.get(`${url}/request/pageCount/${userID}`);
      setTotalPages(data);
    } catch (error) {
      ErrorNotification("Failed to load total pages.");
    }
  };

  useEffect(() => {
    fetchRequests();
  }, [pageNumber]);

  useEffect(() => {
    fetchTotalPages();
  }, []);

  return (


    <><Modal show={show} onHide={onHide} centered backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Approval Requests</Modal.Title>
      </Modal.Header>
      <Modal.Body className="overflow-auto">
        {loading ? (
          <Container className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Container>
        ) : requests?.length > 0 ? (
          requests.map((request) => (
            <Container key={request._id} className="my-3 p-2 border rounded" onClick={() => handleShowRequestModal(request)}>
              <Row>
                <Col>
                  <strong>{request.organizationName}</strong> - {request.type}
                </Col>
                <Col className="text-end">
                  <Badge bg={request.status === "pending" ? "warning" : "success"}>
                    {request.status === "pending" ? (
                      <>
                        <FeatherIcon icon="alert-triangle" size="14" className="me-2" /> Pending
                      </>
                    ) : (
                      <>
                        <FeatherIcon icon="check-circle" size="14" className="me-2" /> Approved
                      </>
                    )}
                  </Badge>
                </Col>
              </Row>
            </Container>
          ))
        ) : (
          <p>You have no pending approval requests.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        {totalPages > 1 && (
          <Pagination>
            {[...Array(totalPages)].map((_, idx) => (
              <Pagination.Item key={idx + 1} active={idx + 1 === pageNumber} onClick={() => setPageNumber(idx + 1)}>
                {idx + 1}
              </Pagination.Item>
            ))}
          </Pagination>
        )}
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
    </>
  );
};

export default ApprovalRequestsModal;
