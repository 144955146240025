import React, { useState } from "react";
import { Modal, Button, Form, Table, Alert, Spinner } from "react-bootstrap";
import { PlusCircle, Trash2 } from "feather-icons-react"; // Import Feather icons
import axios from "../../axios";
import { ErrorNotification, SuccessNotification } from "../../notifications/notifications";

const url = process.env.REACT_APP_BE_URL;

const InviteCollaboratorsModal = ({
  showInviteCollaboratorsModal,
  handleCloseInviteCollaboratorsModal,
  projId,
  fetchData,
}) => {
  // State to manage the list of collaborators
  const [collaborators, setCollaborators] = useState([]);

  // State to manage input fields
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [isFounder, setIsFounder] = useState(false);

  // State to manage loading and alert messages
  const [loading, setLoading] = useState(false);

  // Function to add a new collaborator
  const handleAddCollaborator = () => {
    if (name && email && title) {
      const newCollaborator = {
        collabname: name,
        email,
        title,
        admin: isFounder,
      };
      setCollaborators([...collaborators, newCollaborator]);
      setName("");
      setEmail("");
      setTitle("");
      setIsFounder(false);
    }
  };

  // Function to delete a collaborator
  const handleDeleteCollaborator = (index) => {
    const updatedCollaborators = collaborators.filter((_, i) => i !== index);
    setCollaborators(updatedCollaborators);
  };

  // Function to handle the invite action
  const inviteSubmit = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };

    const collaboratorData = collaborators.map(({ name, ...rest }) => {
      return rest;
    });

    setLoading(true);

    try {
      const res = await axios.post(
        `${url}/invite/inviteCollaborator/${projId}`,
        collaboratorData,
        config
      );

      if (res.status === 200) {
        SuccessNotification(res.data?.message ? res.data?.message : res.data ? res.data : "Collaborators invited successfully!");

        // Fetch the updated data
        fetchData();

        // Clear the collaborators list
        setCollaborators([]);
      }
    } catch (error) {
      ErrorNotification(error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed");

    } finally {
      setLoading(false);
      handleCloseInviteCollaboratorsModal(); // Close the modal after processing
    }
  };

  return (
    <Modal
      show={showInviteCollaboratorsModal}
      onHide={handleCloseInviteCollaboratorsModal}
      centered
      size="lg" // Set modal size to large
    >
      <Modal.Header closeButton>
        <Modal.Title>Invite Collaborators</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "500px", overflowY: "auto" }}>
        <Form>
          <Form.Group controlId="inviteName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="inviteEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="inviteTitle">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group controlId="inviteRole">
            <Form.Check
              type="switch"
              label="Founder/Co-Founder"
              className="mt-2"
              checked={isFounder}
              onChange={(e) => setIsFounder(e.target.checked)}
            />
          </Form.Group>

          <Button
            variant="success"
            className="d-flex align-items-center mt-3"
            onClick={handleAddCollaborator}
          >
            <PlusCircle className="me-2" size={18} />
            Add Collaborator
          </Button>
        </Form>

        {collaborators.length > 0 && (
          <Table striped bordered hover className="mt-4">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Title</th>
                <th>Role</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {collaborators.map((collaborator, index) => (
                <tr key={index}>
                  <td>{collaborator.collabname}</td>
                  <td>{collaborator.email}</td>
                  <td>{collaborator.title}</td>
                  <td>
                    {collaborator.admin ? "Founder/Co-Founder" : "Collaborator"}
                  </td>
                  <td>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => handleDeleteCollaborator(index)}
                    >
                      <Trash2 size={18} />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={handleCloseInviteCollaboratorsModal}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={inviteSubmit}
          disabled={collaborators.length === 0 || loading}
        >
          {loading ? <Spinner animation="border" size="sm" /> : "Invite"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InviteCollaboratorsModal;
