import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, Col, Dropdown, Row } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";

// images
import profile from "../../../assets/defaultProfile.jpg";
import FullScreenSpinner from "../../../Components/FullScreenSpinner/FullScreenSpinner";

const ProfileWidget = ({ initialValues, isMainUser }) => {
  // State variables for profile information
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [interestedIn, setInterestedIn] = useState("");
  const [pic, setPic] = useState("");

  // State for loading
  const [loading, setLoading] = useState(true);

  // Set profile information from initialValues
  useEffect(() => {
    if (initialValues) {
      setFirstName(initialValues.firstName || "");
      setLastName(initialValues.lastName || "");
      setEmail(initialValues.email || "");
      setInterestedIn(initialValues.interesetedIn || "");
      setPic(initialValues.pic);
      setLoading(false); // Stop loading after setting the profile data
    }
  }, [initialValues]);

  if (loading) {
    return <FullScreenSpinner />;
  }

  return (
    <Card>
      <Card.Body>
        <Row>
          <Col>
            <div className="d-flex align-items-center">
              <img
                src={pic || profile}
                alt="Profile"
                className="img-fluid avatar-md rounded-sm me-3"
                style={{ width: '80px', height: '80px', objectFit: 'cover' }}
              />
              <div className="flex-grow-1">
                <h4 className="mb-1 mt-0 fs-16 d-flex align-items-center">

                  {firstName} {lastName}
                </h4>
                <p className="text-muted mb-1 fs-14 d-flex align-items-center">
                  <FeatherIcon icon="mail" size={15} className="me-2 text-secondary" />
                  {email}
                </p>
                <p className="text-muted mb-0 fs-14 d-flex align-items-center">
                  <FeatherIcon icon="star" size={15} className="me-2 text-warning" />
                  {interestedIn}
                </p>
              </div>
            </div>
          </Col>
          {isMainUser && <Col xs="auto">
            <Dropdown align="end">
              <Dropdown.Toggle
                as={Link}
                to="#"
                id="dropdownMenuLink-1"
                className="btn-link text-muted p-0"
              >
                <FeatherIcon
                  icon="more-horizontal"
                  className="icon icon-xs"
                />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item as={Link} to="/settings">
                  <FeatherIcon icon="edit" className="icon-xxs icon me-2" />{" "}
                  Edit
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>}

        </Row>
      </Card.Body>
    </Card>
  );
};

export default ProfileWidget;
