import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../../../axios";
import { Container, Row, Col, Card, Button, Pagination } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import FullScreenSpinner from "../../../../Components/FullScreenSpinner/FullScreenSpinner";
import ApprovalRequestsModal from "../../../../Components/projModels/ApprovalRequestsModal";
import AddProjectModal from "../../../../Components/projModels/AddProjectModal";
import RequestModal from "../../../../Components/reqModel/RequestModal";

const url = process.env.REACT_APP_BE_URL;

const ProjectsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [callback, setCallback] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [selectedRequest, setSelectedRequest] = useState(null);

  // State to manage modals
  const [showApprovalRequestsModal, setShowApprovalRequestsModal] =
    useState(false);
  const [showAddProjectModal, setShowAddProjectModal] = useState(false);
  const [showRequestModal, setShowRequestModal] = useState(false);

  const handleShowRequestModal = (request) => {
    setSelectedRequest(request);
    setShowRequestModal(true)
    setShowApprovalRequestsModal(false)
  };

  const handleCloseRequestModal = () => {
    setShowRequestModal(false);
    setSelectedRequest(null);
  };

  const handlePageChange = (page) => {
    setPageNumber(page);
  };

  useEffect(() => {
    getProjects();
    getTotalPageCount();
  }, [pageNumber]);

  const getProjects = async () => {
    setCallback(false);
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };

    try {
      const { data } = await axios.get(
        `${url}/project?page_number=${pageNumber}`,
        config
      );
      setProjects(data); // Adjust based on your API response
    } catch (error) {
      console.error("Failed to fetch projects:", error);
    } finally {
      setCallback(true);
    }
  };

  const getTotalPageCount = async () => {
    setCallback(false);
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };

    try {
      const { data } = await axios.get(`${url}/project/pageCount`, config);
      setPageCount(data); // Adjust based on your API response
    } catch (error) {
      console.error("Failed to fetch total page count:", error);
    } finally {
      setCallback(true);
    }
  };

  return (
    <section className="position-relative overflow-hidden bg-gradient2 py-5 px-3">
      <Container className="min-vh-100 d-flex flex-column mt-5 mt-lg-0">
        <Row className="mb-4 justify-content-between align-items-center">
          <Col xs="auto">
            <h2>My Projects</h2>
          </Col>
          <Col xs="auto" className="d-flex gap-2 flex-row">
            <Button
              className="btn-soft-primary"
              onClick={() => setShowApprovalRequestsModal(true)}
            >
              <FeatherIcon icon="check" className="me-2" />
              Approval Requests
            </Button>
            <Button
              className="btn-soft-secondary"
              onClick={() => setShowAddProjectModal(true)}
            >
              <FeatherIcon icon="plus" className="me-2" />
              Add a Project
            </Button>
          </Col>
        </Row>

        {callback ? (
          <>
            {projects.length === 0 ? (
              <Row className="align-items-center justify-content-center">
                <Col xs="auto" className="text-center mt-10">
                  <p className="mb-0">At TINT, a project can be an existing business or a business idea you are working on.</p>
                </Col>
              </Row>
            ) : (
              <>
                <Row>
                  {projects?.map((project) => (
                    <Col md={6} lg={4} key={project._id} className="mb-4">
                      <Card
                        className="h-100 shadow-lg border-0 project-card"
                        onClick={() => navigate(`/projectdetails/${project._id}`)}
                        style={{
                          cursor: "pointer",
                          transition: "transform 0.3s ease, box-shadow 0.3s ease",
                        }}
                      >
                        <Card.Body className="p-4">
                          <Card.Title className="text-primary mb-2">
                            {project.name}
                          </Card.Title>
                          <Card.Subtitle className="mb-2 text-secondary">
                            {project.industry}
                          </Card.Subtitle>
                          <Card.Text className="text-muted">
                            {project.description.length > 100
                              ? `${project.description.substring(0, 100)}...`
                              : project.description}
                          </Card.Text>
                        </Card.Body>
                        <Card.Footer className="bg-white border-0 text-end p-3">
                          <span
                            className={`badge rounded-pill ${project.status === "published"
                              ? "bg-success text-white fw-bold"
                              : "bg-warning text-white fw-bold"
                              }`}
                          >
                            {project.status.charAt(0).toUpperCase() +
                              project.status.slice(1)}
                          </span>
                        </Card.Footer>
                      </Card>
                    </Col>
                  ))}
                </Row>

                <Row className="pt-3">
                  <Col className="d-flex justify-content-center">
                    <Pagination className="pagination pagination-sm">
                      <Pagination.Prev
                        onClick={() =>
                          pageNumber > 1 && handlePageChange(pageNumber - 1)
                        }
                        disabled={pageNumber === 1}
                        className="page-item"
                      />
                      {[...Array(pageCount).keys()].map((num) => (
                        <Pagination.Item
                          key={num + 1}
                          active={num + 1 === pageNumber}
                          onClick={() => handlePageChange(num + 1)}
                          className={`page-item ${num + 1 === pageNumber ? "active" : ""
                            }`}
                        >
                          {num + 1}
                        </Pagination.Item>
                      ))}
                      <Pagination.Next
                        onClick={() =>
                          pageNumber < pageCount && handlePageChange(pageNumber + 1)
                        }
                        disabled={pageNumber === pageCount}
                        className="page-item"
                      />
                    </Pagination>
                  </Col>
                </Row>
              </>
            )}
          </>
        ) : (
          <FullScreenSpinner />
        )}

        {/* Approval Requests Modal */}
        <ApprovalRequestsModal
          show={showApprovalRequestsModal}
          onHide={() => setShowApprovalRequestsModal(false)}
          handleShowRequestModal={handleShowRequestModal}
        />

        <RequestModal
          open={showRequestModal}
          onClose={handleCloseRequestModal}
          data2={selectedRequest}
        />

        <AddProjectModal
          show={showAddProjectModal}
          onHide={() => setShowAddProjectModal(false)}
          getProjects={getProjects}
          getTotalPageCount={getTotalPageCount}
        />
      </Container>
    </section>
  );
};

export default ProjectsPage;
