import React, { Suspense, useEffect } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AOS from "aos";
import "./App.css";
import "./assets/scss/theme.scss";

// Pages and Components
import SignUp2 from "./Pages/auth/SignUp";
import Profile from "./Pages/Profile";
import Resource from "./Pages/Resource";
import Resource2 from "./Pages/account/Dashboard/Pages/InnerPages/Resource";
import Landing from "./Pages/Landing/Landing";
import ContactUs from "./Pages/ContactUs";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsAndConditions from "./Pages/TermsAndConditions";
import Login from "./Pages/auth/Login";
import ForgetPassword from "./Pages/auth/ForgetPassword";
import Confirm from "./Pages/auth/Confirm";
import ProjectsPage from "./Pages/account/Dashboard/Pages/ProjectsPage";
import JobSearchPage from "./Pages/account/Dashboard/Pages/JobSearchPage";
import ResourcesPage from "./Pages/account/Dashboard/Pages/ResourcesPage";
import OrganizationsPage from "./Pages/account/Dashboard/Pages/OrganizationsPage";
import DashboardPage from "./Pages/account/Dashboard/Pages/DashboardPage";
import NotFound from "./Components/NotFound";
import ProtectedRoute from "./common/ProtectedRoute";
import Auth from "./common/Auth";
import Loader from "./utils/loader";

// Layouts
import MainLayout from "./Pages/account/Dashboard/LayoutPage";

// Redux Store
import store from "./store/store";

// Routes
import { routes } from "./routes";
import Settings from "./Pages/account/Settings/Settings";
import ProjectDetails from "./Pages/account/Dashboard/Pages/InnerPages/ProjectDetails";
import OrganizationDetails from "./Pages/account/Dashboard/Pages/InnerPages/OrganizationDetails";
import JobDetails from "./Pages/account/Dashboard/Pages/InnerPages/JobDetails";
import JobApplicantList from "./Pages/account/Dashboard/Pages/InnerPages/JobApplicantList";
import ResourceCategory from "./Pages/account/Dashboard/Pages/InnerPages/ResourceCategory";
import MyResources from "./Pages/account/Dashboard/Pages/InnerPages/MyResources";
import AccessDenied from "./Components/PermissionDenied";
import { ReactNotifications } from "react-notifications-component";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0066B2",
    },
    secondary: {
      main: "#009E60",
    },
  },
  typography: {
    fontFamily: ["Manrope", "sans-serif"].join(","),
    fontSize: 14,
  },
});

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  const id = JSON.parse(localStorage.getItem("userInfo"));

  return (
    <ReduxProvider store={store}>
      <ThemeProvider theme={theme}>
        <Suspense
          fallback={
            <div>
              <Loader />
            </div>
          }
        >
          <Auth>
            <BrowserRouter>
              <ReactNotifications />
              <Routes>
                {/* Authenticated Routes */}
                {/* {id ? (
                  <Route path="/profile/:userId" element={<Profile />} />
                ) : (
                  <Route path="/" element={<Landing />} />
                )} */}

                {/* Auth Pages */}
                <Route path="/signup" element={<SignUp2 />} />
                <Route path="/signin" element={<Login />} />
                <Route path="/resetpassword" element={<ForgetPassword />} />
                <Route path="/confirm" element={<Confirm />} />

                {/* Landing Page */}
                <Route path="/" element={<Landing />} />

                {/* Pages with SimpleNavBar */}
                {/* <Route path="/simplenavbar" element={<SimpleNavBar />} /> */}

                {/* Layout with Navbar4 */}
                <Route element={<MainLayout />}>
                  {/* Test Pages */}
                  <Route path="/profile/:userId" element={<DashboardPage />} />
                  <Route path="/projects" element={<ProjectsPage />} />
                  <Route
                    path="/organizations"
                    element={<OrganizationsPage />}
                  />
                  <Route path="/jobsearch" element={<JobSearchPage />} />
                  <Route path="/categories" element={<ResourcesPage />} />
                  <Route path="/settings" element={<Settings />} />
                  <Route path="/myresources" element={<MyResources />} />

                  {/* Test Inner Pages */}
                  <Route
                    path="/projectdetails/:projId"
                    element={<ProjectDetails />}
                  />
                  <Route path="/jobdetails/:jobId" element={<JobDetails />} />
                  <Route
                    path="/jobapplicantlist/:jobId"
                    element={<JobApplicantList />}
                  />
                  <Route
                    path="/organizationdetails/:orgId"
                    element={<OrganizationDetails />}
                  />
                  <Route
                    path="/category/:catCode"
                    element={<ResourceCategory />}
                  />

                  {id && <Route path="/resource/:resCode" element={<Resource2 />} />}
                </Route>

                {/* Fallback Routes */}
                <Route path="*" element={<NotFound />} />
                <Route path="/permission-denied" element={<AccessDenied />} />

                {/* Protected Routes */}
                {/* {routes.map(({ element, path, name }) => (
                  <Route
                    key={name}
                    path={path}
                    element={<ProtectedRoute element={element} />}
                  />
                ))} */}

                {/* Other Pages */}
                <Route path="/contact" element={<ContactUs />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route
                  path="/terms-and-conditions"
                  element={<TermsAndConditions />}
                />
                <Route path="/resource/:resCode" element={<Resource2 />} />
                {!id && <Route path="/resource/:resCode" element={<Resource2 />} />}
              </Routes>
            </BrowserRouter>
          </Auth>
        </Suspense>
      </ThemeProvider>
    </ReduxProvider>
  );
}

export default App;
