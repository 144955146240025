import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import axiosInstance from "../../axios";
import { SuccessNotification, ErrorNotification } from "../../notifications/notifications";

const AddProjectModal = ({ show, onHide, getProjects, getTotalPageCount }) => {

  const [projectName, setProjectName] = useState("");
  const [industry, setIndustry] = useState("");
  const [description, setDescription] = useState("");
  const [funding, setFunding] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [loading, setLoading] = useState(false);

  const [alert, setAlert] = useState({
    showAlert: false,
    severity: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({
    projectName: "",
    industry: "",
    description: "",
  });

  const [touched, setTouched] = useState({
    projectName: false,
    industry: false,
    description: false,
  });

  useEffect(() => {
    if (Object.values(touched).includes(true)) {
      validateForm();
    }
  }, [projectName, industry, description, funding, selectedFile, touched]);

  const validateForm = () => {
    setFormErrors({
      projectName: projectName ? "" : "Project Name is required",
      industry: industry ? "" : "Industry is required",
      description: description ? "" : "Description is required",
    });
  };

  const handleInputChange = (setter) => (e) => {
    setter(e.target.value);
    setTouched((prev) => ({ ...prev, [e.target.name]: true }));
  };

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setSelectedFile(reader.result);
        setFilePreview(reader.result);
        setFileName(file.name);
        setFileType(file.type);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    setFilePreview(null);
    setFileName(null);
    setFileType(null);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setTouched({
      projectName: true,
      industry: true,
      description: true,
    });

    validateForm();

    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const payload = {
      name: projectName,
      industry,
      eequityID: [],
      description,
      jobs: [],
      status: "pending",
      public_id: fileName ? [fileName] : [],
      funding: funding ? parseFloat(funding) : 0,
      iPSensitiveDetails: selectedFile ? [selectedFile] : [],
      users: [userInfo._id],
    };

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    setLoading(true);

    if (payload.name == "" || payload.industry == "" || payload.description == "") {
      setLoading(false);
      return
    }

    try {
      const res = await axiosInstance.post(
        `${process.env.REACT_APP_BE_URL}/project`,
        payload,
        config
      );

      if (res.status === 200) {
        SuccessNotification(res.data?.message ? res.data?.message : res.data ? res.data : "Project added successfully!");
        getProjects();
        getTotalPageCount();
        handleClose();
      }
    } catch (error) {
      console.log(error);
      ErrorNotification(error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed");

      setTimeout(() => {
        setAlert({
          showAlert: false,
          severity: "error",
          message: "",
        });
      }, 16000);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setProjectName("");
    setIndustry("");
    setDescription("");
    setFunding("");
    setSelectedFile(null);
    setFilePreview(null);
    setFileName(null);
    setFileType(null);
    setTouched({
      projectName: false,
      industry: false,
      description: false,
    });
    onHide();
  };

  const renderFilePreview = () => {
    if (!filePreview) return null;

    if (fileType.startsWith("image/")) {
      return <img src={filePreview} alt="File preview" style={{ maxWidth: "100%", maxHeight: "200px" }} />;
    } else if (fileType.startsWith("application/pdf")) {
      return (
        <div className="mt-3 text-center">
          <iframe
            src={filePreview}
            title="PDF Preview"
            style={{ width: "100%", height: "500px" }}
          />
        </div>
      );
    } else {
      return (
        <div className="mt-3 text-center">
          <a href={filePreview} target="_blank" rel="noopener noreferrer">
            View File
          </a>
        </div>
      );
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      dialogClassName="modal-dialog-scrollable"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Add a Project</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          maxHeight: "70vh", // Set maximum height for modal body
          overflowY: "auto", // Enable vertical scrolling
        }}
      >
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Project Name <span className="text-danger">*</span></Form.Label>
            <Form.Control
              type="text"
              name="projectName"
              value={projectName}
              onChange={handleInputChange(setProjectName)}
              isInvalid={touched.projectName && !!formErrors.projectName}
              required
            />
            <Form.Control.Feedback type="invalid">
              {formErrors.projectName}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Industry <span className="text-danger">*</span></Form.Label>
            <Form.Select
              name="industry"
              value={industry}
              onChange={handleInputChange(setIndustry)}
              isInvalid={touched.industry && !!formErrors.industry}
              required
            >
              <option value="">Select Industry</option>
              <option value="Construction">Construction</option>
              <option value="Energy">Energy</option>
              <option value="Materials">Materials</option>
              <option value="Industrials">Industrials</option>
              <option value="Healthcare">Healthcare</option>
              <option value="Financials">Financials</option>
              <option value="Information Technology">
                Information Technology
              </option>
              <option value="Real Estate">Real Estate</option>
              <option value="Communication Services">
                Communication Services
              </option>
              <option value="Utilities">Utilities</option>
              <option value="Other">Other</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {formErrors.industry}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Description <span className="text-danger">*</span></Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="description"
              value={description}
              onChange={handleInputChange(setDescription)}
              isInvalid={touched.description && !!formErrors.description}
              required
            />
            <Form.Control.Feedback type="invalid">
              {formErrors.description}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Funding (optional)</Form.Label>
            <Form.Control
              type="number"
              value={funding}
              onChange={(e) => setFunding(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Documents (optional)</Form.Label>
            <Form.Control
              type="file"
              onChange={handleFileChange}
            />
            <div className="mt-3">
              {renderFilePreview()}
            </div>

            {filePreview && (
              <Button
                variant="danger"
                className="mt-3"
                onClick={handleRemoveFile}
              >
                Remove
              </Button>
            )}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit} disabled={loading}>
          {loading ? <Spinner animation="border" size="sm" /> : "Add Project"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddProjectModal;
