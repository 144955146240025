import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "../../../../../axios";
import { Container, Row, Col, Card, Button, Alert } from "react-bootstrap";
import FeatherIcon from "feather-icons-react";
import { Link } from "react-router-dom";

// images
import profile from "../../../../../assets/defaultProfile.jpg";
import FullScreenSpinner from "../../../../../Components/FullScreenSpinner/FullScreenSpinner";
import EditProjectModal from "../../../../../Components/projModels/EditProjectModal";
import PublishProjectModal from "../../../../../Components/projModels/PublishProjectModal";
import CreateJobModal from "../../../../../Components/projModels/CreateJobModal";
import DuplicateJobModal from "../../../../../Components/projModels/DuplicateJobModal";
import InviteCollaboratorsModal from "../../../../../Components/projModels/InviteCollaboratorsModal";
import { ErrorNotification, SuccessNotification } from "../../../../../notifications/notifications"

const url = process.env.REACT_APP_BE_URL;

const ProjectDetails = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  const navigate = useNavigate();
  const { projId } = useParams();

  const [project, setProject] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [adminUsers, setAdminUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  // State for job creation
  const [newJob, setNewJob] = useState({
    title: "",
    jobType: "",
    timeAvailability: "",
    description: "",
    responsibilities: [],
    requirements: [],
    qualifications: [],
    skills: [],
  });

  // Modals
  const [showCreateJobModal, setShowCreateJobModal] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [showEditProjectModal, setShowEditProjectModal] = useState(false);
  const [showDuplicateJobModal, setShowDuplicateJobModal] = useState(false); // Modal for duplicating a job
  const [showInviteCollaboratorsModal, setShowInviteCollaboratorsModal] =
    useState(false); // Modal for inviting collaborators

  const [selectedJob, setSelectedJob] = useState(null);
  const [updatedJob, setUpdatedJob] = useState(null);

  const handleCloseCreateJobModal = () => setShowCreateJobModal(false);
  const handleClosePublishModal = () => setShowPublishModal(false);
  const handleCloseEditProjectModal = () => setShowEditProjectModal(false);
  const handleCloseDuplicateJobModal = () => {
    setShowDuplicateJobModal(false);
    setSelectedJob(null);
    setUpdatedJob(null);
  };
  const handleCloseInviteCollaboratorsModal = () =>
    setShowInviteCollaboratorsModal(false);

  console.log(project);

  const fetchData = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
            }`,
        },
      };

      // Fetch project data
      const { data: projectData } = await axios.get(
        `${url}/project/${projId}`,
        config
      );
      setProject(projectData);

      // Fetch admin users
      const res = await axios.get(
        `${url}/project/adminUsers/${projId}`,
        config
      );
      setAdminUsers(res.data);

      // Fetch jobs
      const jobArr = await Promise.all(
        projectData.jobs.map(async (jobID) => {
          const { data: jobData } = await axios.get(
            `${url}/job/${jobID}`,
            config
          );
          return jobData;
        })
      );
      setJobs(jobArr);

      setLoading(false);
    } catch (error) {
      ErrorNotification(error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Failed to fetch data.");
    }
  };

  useEffect(() => {
    fetchData();
  }, [projId]);

  console.log(adminUsers);


  const userID = JSON.parse(localStorage.getItem("userInfo"))._id;
  const adminCheck = project?.admins?.filter((id) => {
    return userID === id;
  });

  const userCheck = project?.users?.filter((id) => {
    return userID === id;
  });

  const handlePublish = () => {
    setShowPublishModal(true);
  };

  const handleEditProject = () => {
    setShowEditProjectModal(true);
  };

  const handleDuplicateJob = (job) => {
    setSelectedJob(job);
    setUpdatedJob({ ...job }); // Create a copy of the job for updating
    setShowDuplicateJobModal(true);
  };

  const handleInviteCollaborators = () => {
    setShowInviteCollaboratorsModal(true);
  };

  const handlePublishInvite = () => {
    handleClosePublishModal();
    setShowInviteCollaboratorsModal(true);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleDeleteProj = async () => {

    if (!project) {
      ErrorNotification("Project data is not available.");
      return;
    }

    // Check if the project has jobs before attempting to delete
    if (project?.jobs?.length > 0) {
      ErrorNotification("Cannot delete project with existing jobs.");
      return;
    }

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token}`,
        },
      };

      const response = await axios.delete(`${url}/project/delete/${project._id}`, config);
      SuccessNotification(response.data.message); // Show success notification

      // Optionally navigate back or refresh the data after deletion
      navigate("/projects"); // or wherever you want to redirect
    } catch (error) {
      ErrorNotification(error.response?.data?.message || "Failed to delete project.");
    }
  };


  return (
    <section className="position-relative overflow-hidden bg-gradient2 py-3 px-3">
      <Container className="min-vh-100 bg-light p-4 mt-5 mt-lg-0">
        {loading ? (
          <FullScreenSpinner />
        ) : (
          <>
            <Row className="mb-4 justify-content-between align-items-center">
              <Col xs="auto">
                <div className="d-flex justify-center">
                  <span className="h2">{capitalizeFirstLetter(project.name)}</span>
                </div>
              </Col>
              <Col xs="auto" className="d-flex gap-2 flex-row">
                {userCheck?.length > 0 && (
                  <Button
                    className={` ${project.status === "pending" ? "btn-soft-warning" : "btn-soft-success"}`}
                  >
                    <FeatherIcon icon={project.status === "pending" ? "clock" : "check"} />
                    <span className="d-none d-md-inline  ms-2">
                      {project.status === "pending" ? "Pending" : "Published"}
                    </span>
                  </Button>
                )}
                {adminCheck?.length > 0 && project.status === "pending" && (
                  <>
                    <Button
                      className="btn-soft-primary"
                      onClick={handlePublish}
                    >
                      <FeatherIcon icon="check" />
                      <span className="d-none d-md-inline  ms-2">Publish</span>
                    </Button>

                    <Button className="btn-soft-danger" onClick={handleDeleteProj}>
                      <FeatherIcon icon="trash-2" />
                      <span className="d-none d-md-inline ms-2">Delete</span>
                    </Button>

                    <Button
                      className="btn-soft-secondary"
                      onClick={handleInviteCollaborators}
                    >
                      <FeatherIcon icon="plus" />
                      <span className="d-none d-md-inline  ms-2">Invite Collaborators</span>
                    </Button>
                  </>
                )}
                {adminCheck?.length > 0 && <Button
                  className="btn-soft-warning"
                  onClick={handleEditProject}
                >
                  <FeatherIcon icon="edit-3" />
                  <span className="d-none d-md-inline ms-2">Edit Details</span>
                </Button>}

              </Col>
            </Row>
            <Row className="mb-4">
              <Col md={12}>
                <p>
                  <strong>Industry:</strong> {project.industry}
                </p>
                <p>
                  <strong>Description:</strong> {project.description}
                </p>

                <p>
                  <strong>Funding:</strong> $ {project.funding}
                </p>
              </Col>
            </Row>

            {/* Documents */}
            <Row className="mb-4">
              <Col>
                <h2>Documents</h2>
                {project.iPSensitiveDetails &&
                  project.iPSensitiveDetails.length > 0 ? (
                  <div>
                    {project.iPSensitiveDetails.map((doc) => (
                      <Card
                        key={doc.public_id}
                        className="mb-3 shadow-sm border-0 rounded"
                      >
                        <Card.Body>
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <Card.Title className="mb-1">Document</Card.Title>
                              <Card.Text className="mb-0">
                                <a
                                  href={doc.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  View/Download {doc.public_id.split("/").pop()}
                                </a>
                              </Card.Text>
                            </div>
                            <Button
                              variant="outline-info"
                              size="sm"
                              href={doc.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FeatherIcon icon="download" />
                              <span className="d-none d-md-inline  ms-2">Download</span>
                            </Button>
                          </div>
                        </Card.Body>
                      </Card>
                    ))}
                  </div>
                ) : (
                  <p>No Documents found.</p>
                )}
              </Col>
            </Row>

            {/* Founding Members */}
            {adminUsers?.length > 0 && (
              <Row className="mb-4">
                <Col>
                  <h2>Admins</h2>
                  {adminUsers && adminUsers.length > 0 ? (
                    <div className="d-flex flex-column">
                      {adminUsers.map((user) => (
                        <Card
                          key={user._id}
                          className="mb-3 shadow-sm border-0 rounded"
                          style={{ width: "100%" }}
                        >
                          <Card.Body className="d-flex align-items-center">
                            <img
                              src={user.profilePic.url ? user.profilePic.url : profile}
                              alt={`${user.firstName} ${user.lastName}`}
                              className="avatar-lg rounded-circle me-3"
                              style={{ width: "60px", height: "60px" }}
                            />
                            <div className="flex-grow-1">
                              <Card.Title className="mb-1">
                                {user.firstName} {user.lastName}
                              </Card.Title>
                            </div>
                            {/* <Link to="#" className="btn btn-soft-info">
                              View Profile
                            </Link> */}
                          </Card.Body>
                        </Card>
                      ))}
                    </div>
                  ) : (
                    <p>No admin users found.</p>
                  )}
                </Col>
              </Row>
            )}

            {/* Jobs */}
            <Row className="mb-4">
              <Col>
                <Row className="mb-2 justify-content-between align-items-center">
                  <Col xs="auto">
                    <h2>Jobs</h2>
                  </Col>
                  {adminCheck?.length > 0 && (
                    <Col xs="auto">
                      <Button
                        className="btn-soft-secondary"
                        onClick={() => {
                          setShowCreateJobModal(true);
                        }}
                      >
                        <FeatherIcon icon="plus" className="me-2" />
                        Create a Job
                      </Button>
                    </Col>
                  )}
                </Row>
                {jobs && jobs.length > 0 ? (
                  jobs.map((job) => (
                    <Card
                      key={job._id}
                      className="mb-3 shadow-sm border-0 rounded"
                      style={{ width: "100%" }}
                    >
                      <Card.Body>
                        <div className="d-block d-md-flex justify-content-between align-items-center mb-2">
                          <div>
                            <Card.Title className="mb-1">
                              {job.title}
                            </Card.Title>
                            <Card.Text className="mb-0">
                              <small>
                                {job.jobType} - {job.timeAvailability}
                              </small>
                            </Card.Text>
                          </div>
                          <div className="mt-2 mt-md-0">
                            {adminCheck?.length > 0 && <Button
                              variant="outline-info"
                              size="sm"
                              className="me-2"
                              onClick={() => handleDuplicateJob(job)}
                            >
                              <FeatherIcon icon="copy" className="me-1" /> Duplicate
                            </Button>}
                            <Link
                              to={`/jobdetails/${job._id}`}
                              className="btn btn-outline-success btn-sm"
                            >
                              <FeatherIcon icon="eye" />
                            </Link>
                          </div>
                        </div>
                        <Button
                          variant={
                            job.status === "active"
                              ? "success"
                              : "outline-secondary"
                          }
                          size="sm"
                        >
                          {job.status === "active" ? "Active" : "Deactivated"}
                        </Button>
                      </Card.Body>
                    </Card>
                  ))
                ) : (
                  <p>No jobs found.</p>
                )}
              </Col>
            </Row>
          </>
        )}
      </Container>

      {/* Create Job Modal */}
      <CreateJobModal
        show={showCreateJobModal}
        handleClose={handleCloseCreateJobModal}
        newJob={newJob}
        fetchData={fetchData}
        projId={projId}
        setNewJob={setNewJob}
      />

      {showPublishModal && (
        <PublishProjectModal
          projId={projId}
          show={showPublishModal}
          handleClose={handleClosePublishModal}
          handleInviteCollaborators={handlePublishInvite}
          fetchData={fetchData}
        />
      )}
      {showEditProjectModal && (
        <EditProjectModal
          show={showEditProjectModal}
          handleClose={handleCloseEditProjectModal}
          project={project}
          projId={projId}
          fetchData={fetchData}
        />
      )}
      {/* Duplicate Job Modal */}
      <DuplicateJobModal
        show={showDuplicateJobModal}
        handleClose={handleCloseDuplicateJobModal}
        updatedJob={updatedJob}
        fetchData={fetchData}
      />
      {/* Invite Collaborators Modal */}
      <InviteCollaboratorsModal
        showInviteCollaboratorsModal={showInviteCollaboratorsModal}
        handleCloseInviteCollaboratorsModal={
          handleCloseInviteCollaboratorsModal
        }
        projId={projId}
        fetchData={fetchData}
      />
    </section>
  );
};

export default ProjectDetails;
