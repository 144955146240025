import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axiosInstance from "../../axios";
import CircularProgress from "@mui/material/CircularProgress"; // Ensure you have @mui/material installed
import { ErrorNotification, SuccessNotification } from "../../notifications/notifications";

const url = process.env.REACT_APP_BE_URL;

const EditDetailsModal = ({
  show,
  handleClose,
  jobData,
  getData,
  jobId,
  setJobData,
}) => {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    jobType: "",
    timeAvailability: "",
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (jobData) {
      setFormData({
        title: jobData.title || "",
        description: jobData.description || "",
        jobType: jobData.jobType || "",
        timeAvailability: jobData.timeAvailability || "",
      });
    }
  }, [jobData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const userId = JSON.parse(localStorage.getItem("userInfo"));

  const config = {
    headers: {
      Authorization: `Bearer ${userId.token}`,
    },
  };

  const validateForm = () => {
    let formIsValid = true;
    let errors = {};

    if (!formData.title.trim()) {
      formIsValid = false;
      errors.title = "Title is required.";
    }

    if (!formData.description.trim()) {
      formIsValid = false;
      errors.description = "Description is required.";
    }

    if (!formData.jobType) {
      formIsValid = false;
      errors.jobType = "Job type is required.";
    }

    if (!formData.timeAvailability) {
      formIsValid = false;
      errors.timeAvailability = "Time availability is required.";
    }

    setErrors(errors);
    return formIsValid;
  };

  const handleEditDetails = async (editedDetails) => {
    try {
      setLoading(true);
      const res = await axiosInstance.put(
        `${url}/job/${jobId}`,
        editedDetails,
        config
      );
      if (res.status === 200) {
        setJobData(res.data);
        SuccessNotification(res.data?.message ? res.data?.message : res.data ? res.data : "Job details updated successfully!");
        getData();
        handleClose();
      }
    } catch (error) {
      ErrorNotification(error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      handleEditDetails(formData);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size="lg"
      backdrop="static"
      animation={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Job Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit} className="py-2">
          <Form.Group controlId="formTitle" className="mb-3">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter job title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              isInvalid={!!errors.title}
            />
            <Form.Control.Feedback type="invalid">
              {errors.title}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formDescription" className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter job description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              isInvalid={!!errors.description}
            />
            <Form.Control.Feedback type="invalid">
              {errors.description}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="jobType">
            <Form.Label>Job Type</Form.Label>
            <Form.Control
              as="select"
              name="jobType"
              value={formData.jobType}
              onChange={handleChange}
              isInvalid={!!errors.jobType}
            >
              <option value="">Select Job Type</option>
              <option>Accounting</option>
              <option>Legal</option>
              <option>Engineering</option>
              <option>Marketing</option>
              <option>Sales</option>
              <option>Management</option>
              <option>Other</option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.jobType}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="timeAvailability">
            <Form.Label>Time Availability</Form.Label>
            <Form.Control
              as="select"
              name="timeAvailability"
              value={formData.timeAvailability}
              onChange={handleChange}
              isInvalid={!!errors.timeAvailability}
            >
              <option value="">Select Time Availability</option>
              <option value="full-time">Full-Time</option>
              <option value="part-time">Part-Time</option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.timeAvailability}
            </Form.Control.Feedback>
          </Form.Group>

          <Button
            variant="primary"
            type="submit"
            disabled={loading}
            className="mt-3"
          >
            {loading ? <CircularProgress size={24} /> : "Save Changes"}
          </Button>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditDetailsModal;
