import axios from "../../../../axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  Card,
  Button,
  Spinner,
  Pagination,
  InputGroup,
  Alert,
} from "react-bootstrap";
import FeatherIcon from "feather-icons-react";

const url = process.env.REACT_APP_BE_URL;

const JobSearchPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const [jobs, setJobs] = useState(null);
  const [value, setValue] = useState("");
  const [timeAvailability, setTimeAvailability] = useState("none");
  const [pageNumber, setPageNumber] = useState(1);
  const [count, setCount] = useState(10);
  const [callback, setCallback] = useState(false);
  const [checked, setChecked] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setPageNumber(1);
    getSearch(value);
    // eslint-disable-next-line
  }, [timeAvailability]);

  useEffect(() => {
    if (value === "") {
      setPageNumber(1);
      getSearch(value);
    }
    // eslint-disable-next-line
  }, [value]);

  useEffect(() => {
    getSearch(value);
    // eslint-disable-next-line
  }, [pageNumber, checked]);

  const handlePageChange = (event, value) => {
    setPageNumber(value);
  };

  const getSearch = async (searchValue) => {
    setCallback(true);
    let arrangedUR = "";
    if (checked) {
      if (searchValue !== "" && timeAvailability === "none") {
        arrangedUR = `${url}/job/jobs/applied/${pageNumber}?title=${searchValue}`;
      } else if (searchValue === "" && timeAvailability === "none") {
        arrangedUR = `${url}/job/jobs/applied/${pageNumber}`;
      } else if (searchValue !== "" && timeAvailability !== "none") {
        arrangedUR = `${url}/job/jobs/applied/${pageNumber}?title=${searchValue}&timeAvailability=${timeAvailability}`;
      } else if (searchValue === "" && timeAvailability !== "none") {
        arrangedUR = `${url}/job/jobs/applied/${pageNumber}?timeAvailability=${timeAvailability}`;
      }
    } else {
      if (searchValue !== "" && timeAvailability === "none") {
        arrangedUR = `${url}/job/jobs/${pageNumber}?title=${searchValue}`;
      } else if (searchValue === "" && timeAvailability === "none") {
        arrangedUR = `${url}/job/jobs/${pageNumber}`;
      } else if (searchValue !== "" && timeAvailability !== "none") {
        arrangedUR = `${url}/job/jobs/${pageNumber}?title=${searchValue}&timeAvailability=${timeAvailability}`;
      } else if (searchValue === "" && timeAvailability !== "none") {
        arrangedUR = `${url}/job/jobs/${pageNumber}?timeAvailability=${timeAvailability}`;
      }
    }

    setValue(searchValue);
    setJobs(null);
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token
          }`,
      },
    };
    try {
      const { data } = await axios.get(arrangedUR, config);
      setJobs(data?.data);
      setCount(data?.allPageCount);
    } catch (error) {
      console.error("Error fetching jobs:", error);
    } finally {
      setCallback(false);
    }
  };

  const handleRadioChange = (event) => {
    setTimeAvailability(event.target.value);
  };

  const handleSearchEnter = (e) => {
    setPageNumber(1);
    getSearch(value);
  };

  const handleReset = (e) => {
    setPageNumber(1);
    setValue("");
    setChecked(false);
    setTimeAvailability("none");
    getSearch("");
  };

  return (
    <section className="position-relative overflow-hidden bg-gradient2 py-5 px-3">
      <Container className="min-vh-100 d-flex flex-column mt-5 mt-lg-0">
        <Row className="mb-4 justify-content-center">
          <Col xs={12} md={8} lg={6}>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleSearchEnter();
              }}
            >
              <InputGroup>
                <Form.Control
                  type="text"
                  placeholder="Search for jobs..."
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  onKeyPress={(e) => e.key === "Enter" && handleSearchEnter()}
                />
                <Button
                  variant="secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    handleSearchEnter();
                  }}
                >
                  Search
                </Button>
              </InputGroup>
            </Form>
          </Col>
        </Row>

        <Row className="mb-4 justify-content-center">
          <Col xs="auto">
            <Form.Check
              type="radio"
              label="Any"
              name="timeAvailability"
              value="none"
              checked={timeAvailability === "none"}
              onChange={handleRadioChange}
            />
          </Col>
          <Col xs="auto">
            <Form.Check
              type="radio"
              label="Full-time"
              name="timeAvailability"
              value="full-time"
              checked={timeAvailability === "full-time"}
              onChange={handleRadioChange}
            />
          </Col>
          <Col xs="auto">
            <Form.Check
              type="radio"
              label="Part-time"
              name="timeAvailability"
              value="part-time"
              checked={timeAvailability === "part-time"}
              onChange={handleRadioChange}
            />
          </Col>
        </Row>

        <Row className="mb-4 justify-content-center">
          <Col xs="auto">
            <Form.Check
              type="switch"
              id="appliedJobsSwitch"
              label="Applied Jobs"
              checked={checked}
              onChange={(e) => setChecked(e.target.checked)}
            />
          </Col>
        </Row>

        {callback ? (
          <Row className="flex-grow-1 justify-content-center align-items-center">
            <Spinner animation="border" variant="primary" />
          </Row>
        ) : jobs && jobs.length > 0 ? ( // Check if there are jobs
          <Row>
            {jobs.map((job) => (
              <Col md={6} lg={4} key={job._id} className="mb-4">
                <Card
                  className="h-100 shadow-lg border-0 job-card transition-transform transform-hover"
                  onClick={() => navigate(`/jobdetails/${job._id}`)}
                  style={{
                    cursor: "pointer",
                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  }}
                >
                  <Card.Body className="p-4 d-flex flex-column justify-content-between">
                    <div>
                      <Card.Title className="text-dark fw-bold mb-2 fs-4">
                        {job.title}
                      </Card.Title>
                      <Card.Subtitle className="text-primary mb-3 fw-semibold">
                        {job.jobType}
                      </Card.Subtitle>
                      <Card.Text className="text-secondary mb-4">
                        {job.description.length > 100
                          ? `${job.description.substring(0, 100)}...`
                          : job.description}
                      </Card.Text>
                    </div>
                    <div>
                      <Card.Text className="mb-2 d-flex align-items-center">
                        <FeatherIcon
                          icon="clock"
                          className="me-1 text-primary"
                          size={18}
                        />
                        <span>
                          <strong>Time Availability :</strong>{" "}
                          {job.timeAvailability}
                        </span>
                      </Card.Text>
                      <div className="mt-3">
                        <strong>Skills:</strong>
                        <div className="mt-2">
                          {job.skills.map((skill, index) => (
                            <span
                              key={index}
                              className="badge bg-primary text-light me-2 mb-2"
                            >
                              {skill}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        ) : (
          // Display "No jobs found" message if no jobs
          <Row className="flex-grow-1 justify-content-center">
            <Col xs={12} md={8} lg={6}>
              <p className="text-center">No jobs found</p>
            </Col>
          </Row>
        )}
      </Container>
    </section>
  );
};

export default JobSearchPage;
