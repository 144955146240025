import { useEffect } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import classNames from "classnames";
import { Link, NavLink } from "react-router-dom";

// images
import logo from "../../assets/TINT.png";
import logoLight from "../../assets/logo-light.png";

const NavbarComponent = ({
  isSticky,
  navClass,
  buttonClass,
  fixedWidth,
  hideSearch,
}) => {
  // Add navbar-sticky class on scroll
  useEffect(() => {
    const btnTop = document.getElementById("btn-back-to-top");
    const navbar = document.getElementById("sticky");
    window.addEventListener("scroll", (e) => {
      e.preventDefault();
      if (btnTop) {
        if (
          document.body.scrollTop >= 50 ||
          document.documentElement.scrollTop >= 50
        ) {
          btnTop.classList.add("show");
        } else {
          btnTop.classList.remove("show");
        }
      }
      if (navbar) {
        if (
          document.body.scrollTop >= 240 ||
          document.documentElement.scrollTop >= 240
        ) {
          navbar.classList.add("navbar-sticky");
        } else {
          navbar.classList.remove("navbar-sticky");
        }
      }
    });
  }, []);

  return (
    <header>
      <Navbar
        id={isSticky ? "sticky" : ""}
        collapseOnSelect
        expand="lg"
        className={classNames("topnav-menu", navClass)}
      >
        <Container fluid={!fixedWidth}>
          {/* Align logo to the left */}
          <Navbar.Brand as={Link} to="/" className="logo">
            <img
              src={logo}
              height="30"
              className="align-top logo-dark"
              alt="Logo"
            />
            <img
              src={logoLight}
              height="30"
              className="align-top logo-light"
              alt="Logo Light"
            />
          </Navbar.Brand>

          {/* Custom Toggle Button */}
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            className="border-0 shadow-none"
          >
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>

          <Navbar.Collapse id="topnav-menu-content">
            <Nav className="ms-auto align-items-lg-center">
              {!hideSearch && (
                <Nav.Item as="li" className="d-none d-lg-flex">
                  <form id="search" className="form-inline">
                    <div className="form-control-with-hint ms-lg-2 ms-xl-4">
                      <input
                        type="text"
                        className="form-control"
                        id="search-input"
                        placeholder="What are you looking for?"
                      />
                      <span className="form-control-feedback uil uil-search fs-16"></span>
                    </div>
                  </form>
                </Nav.Item>
              )}

              <Nav.Item as="li" className="me-3">
                <NavLink
                  to="/signin"
                  end
                  className="nav-link text-dark"
                  style={{ fontWeight: "bold", fontSize: "1rem" }} // Custom styling for the text
                >
                  <span>Login</span>
                </NavLink>
              </Nav.Item>
              <Nav.Item as="li">
                <Link
                  to="/signup"
                  className={classNames("btn", buttonClass, "btn-primary")}
                  style={{ fontSize: "1rem" }} // Ensures button text matches the font size
                >
                  Register
                </Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default NavbarComponent;
