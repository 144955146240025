import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../../../axios";
import { Container, Row, Col, Card, Button, Spinner, Form, Modal } from "react-bootstrap";
import * as Icons from "@mui/icons-material";
import FeatherIcon from "feather-icons-react";
import defaultImg from "../../../../assets/default-resource-img.png";
import { ErrorNotification, SuccessNotification } from "../../../../notifications/notifications";

const url = process.env.REACT_APP_BE_URL;

const ResourcesPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const [searchResults, setSearchResults] = useState([]); // State for search results
  const [isSearching, setIsSearching] = useState(false); // State to track if a search has been performed
  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const [newCategory, setNewCategory] = useState({ code: "", name: "", icon: "" }); // State for new category input
  const [submissionLoading, setSubmissionLoading] = useState(false);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token}`,
        },
      };
      const { data } = await axios.get(`${url}/category`, config);
      setCategories(data);
    } catch (error) {
      console.error("Failed to fetch categories:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch approved resources based on the search term
  const fetchResources = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token}`,
        },
      };
      const { data } = await axios.get(`${url}/resource/search?name=${searchTerm}`, config);
      setSearchResults(data); // Store search results in state
      setIsSearching(true); // Update searching state
    } catch (error) {
      console.error("Failed to fetch resources:", error);
    }
  };

  // Handle search term change
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Handle search submit
  const handleSearchSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission
    fetchResources(); // Fetch resources based on search term
  };

  // Reset search results and show categories
  const handleReset = () => {
    setSearchTerm(""); // Clear search term
    setSearchResults([]); // Clear search results
    setIsSearching(false); // Reset search state
  };

  // Handle modal open and close
  const handleShow = () => setShowModal(true);
  const handleClose = () => {
    setShowModal(false);
    setNewCategory({ code: "", name: "", icon: "" }); // Reset form on close
  };

  // Handle form change
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewCategory((prev) => ({ ...prev, [name]: value }));
  };

  // Handle form submission to add a new category
  const handleSubmit = async (event) => {
    setSubmissionLoading(true);
    event.preventDefault();
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token}`,
        },
      };
      await axios.post(`${url}/category/user-category`, newCategory, config); // Adjust endpoint as necessary
      SuccessNotification("Category created successfully and is now pending approval.");
      fetchCategories(); // Refresh categories
      handleClose(); // Close the modal
    } catch (error) {
      console.error("Failed to add category:", error);
      ErrorNotification(error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed");
    } finally {
      setSubmissionLoading(false);
    }
  };

  return (
    <section className="position-relative overflow-hidden bg-gradient2 py-3 px-3">
      <Container className="min-vh-100 d-flex flex-column mt-6 mt-lg-0">
        <Row className="mb-4 justify-content-between align-items-center">
          <Col xs="auto">
            <h2 className="fw-bold">Resources</h2>
          </Col>
          <Col xs="auto" className="d-flex gap-2 flex-row">
            <Button className="btn-soft-secondary" onClick={handleShow}>
              <FeatherIcon icon="plus" />
              <span className="d-none d-md-inline ms-2">Add New Category</span>
            </Button>
            <Button className="btn-soft-primary" onClick={() => navigate("/myresources")}>
              <FeatherIcon icon="file" />
              <span className="d-none d-md-inline ms-2">My Resources</span>
            </Button>
          </Col>
        </Row>

        {/* Search Input */}
        <Row className="mb-4 align-items-center">
          <Col xs={10} className="pe-0">
            <Form onSubmit={handleSearchSubmit}>
              <Form.Control
                type="text"
                placeholder="Search Resources..."
                value={searchTerm}
                onChange={handleSearchChange}
                className="rounded-start"
                style={{ height: "50px", fontSize: "16px" }}
              />
            </Form>
          </Col>

          <Col xs={2} className="ps-2">
            <Button
              onClick={handleReset}
              className="btn-secondary d-md-none"
              style={{ height: "50px", fontSize: "16px", display: "flex", alignItems: "center" }}
            >
              <FeatherIcon icon="x" size={18} className="me-1" />
            </Button>

            <Button
              onClick={handleReset}
              className="btn-secondary d-none d-md-block w-100"
              style={{ height: "50px", fontSize: "16px" }}
            >
              Reset
            </Button>
          </Col>
        </Row>

        <Row>
          {loading ? (
            <Col xs={12} className="d-flex justify-content-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </Col>
          ) : isSearching ? (
            searchResults.length > 0 ? (
              searchResults.map((resource) => (
                <Col xs={12} sm={6} md={4} lg={3} key={resource._id} className="mb-4">
                  <Card className="h-100 shadow-sm border-0 overflow-hidden transition-transform transform-scale-hover">
                    <div className="bg-image hover-overlay ripple" data-mdb-ripple-color="light">
                      <Card.Img
                        variant="top"
                        src={resource?.image?.url || defaultImg}
                        alt={resource.name}
                        style={{ height: "200px", objectFit: "cover" }}
                      />
                    </div>
                    <Card.Body className="d-flex flex-column">
                      <Card.Title className="text-primary mb-1">{resource.name}</Card.Title>
                      <Card.Text className="text-muted flex-grow-1 mb-3">
                        {resource.description.length > 100
                          ? `${resource.description.substring(0, 100)}...`
                          : resource.description}
                      </Card.Text>
                      <Button
                        variant="outline-primary"
                        className="w-100 mt-auto shadow-sm"
                        onClick={() => navigate(`/resource/${resource.code}`)}
                      >
                        View Details
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              ))
            ) : (
              <Col xs={12} className="d-flex flex-column align-items-center">
                <h4 className="fw-bold">No Resources Found</h4>
                <Button onClick={handleReset} className="mt-3">Show Categories</Button>
              </Col>
            )
          ) : categories.length > 0 ? (
            categories.map((category) => (
              <Col xs={12} sm={6} md={4} lg={3} key={category.name} className="mb-4">
                <Card
                  className="h-100 shadow-sm border-0 rounded-3 transition-transform transform-scale-hover"
                  onClick={() => navigate(`/category/${category.code}`)}
                  style={{ cursor: "pointer", overflow: "hidden", transition: "transform 0.3s ease" }}
                  onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.05)")}
                  onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                >
                  <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                    {category.icon && Icons[category.icon] ? (
                      React.createElement(Icons[category.icon], {
                        sx: { fontSize: 80, color: "primary" },
                      })
                    ) : (
                      <Icons.PriorityHigh sx={{ fontSize: 80, color: "primary" }} />
                    )}
                    <Card.Title
                      className="mt-3 text-center fw-bold"
                      style={{
                        fontWeight: "700",
                        fontSize: "1.2rem",
                        color: "#333",
                      }}
                    >
                      {category.name}
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            ))
          ) : (
            <Col xs={12} className="d-flex justify-content-center">
              <h4 className="fw-bold">No Categories Found</h4>
            </Col>
          )}
        </Row>

        {/* Add New Category Modal */}
        <Modal show={showModal} onHide={handleClose} centered size="md">
          <Modal.Header closeButton>
            <Modal.Title>Add New Category</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formCategoryCode">
                <Form.Label>Category Code</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter category code"
                  name="code"
                  value={newCategory.code}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formCategoryName">
                <Form.Label>Category Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter category name"
                  name="name"
                  value={newCategory.name}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formCategoryIcon">
                <Form.Label>Category Icon</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter icon name (from Material UI)"
                  name="icon"
                  value={newCategory.icon}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Button variant="primary" type="submit" className="mt-3">
                {submissionLoading ? (
                  <span className="spinner-border spinner-border-sm" />
                ) : (
                  "Add Category"
                )}
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </Container>
    </section>
  );
};

export default ResourcesPage;
