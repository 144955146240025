import React, { useState } from "react";
import { Modal, Button, Form, Card } from "react-bootstrap";
import axiosInstance from "../../axios";
import { ErrorNotification, SuccessNotification } from "../../notifications/notifications";

const url = process.env.REACT_APP_BE_URL;

const predefinedJobResponsibilities = [
  "Phone answering",
  "Customer relations",
  "Product and delivery",
  "Complaint response",
  "Regular Attendance",
];
const predefinedJobRequirements = [
  "Qualified Bsc Degree",
  "Interpersonal skills.",
  "Qualified Msc Degree",
  "Extended or uncommon working hours",
  "4 year working Experience",
];
const predefinedJobQualifications = [
  "Teamwork",
  "Multitasking",
  "Decision-making",
  "Self-starting ability",
  "Strategizing",
];
const predefinedSkills = [
  "HTML",
  "CSS",
  "JavaScript",
  "TypeScript",
  "NodeJS",
  "MongoDB",
  "MySQL",
];

const CreateJobModal = ({
  show,
  handleClose,
  newJob,
  fetchData,
  orgId,
  setNewJob,
}) => {
  const [validationErrors, setValidationErrors] = useState({
    title: "",
    jobType: "",
    timeAvailability: "",
    description: "",
  });

  const [submissionLoading, setSubmissionLoading] = useState(false);

  // States to manage dynamic input
  const [newResponsibility, setNewResponsibility] = useState("");
  const [newRequirement, setNewRequirement] = useState("");
  const [newQualification, setNewQualification] = useState("");
  const [newSkill, setNewSkill] = useState("");

  // Local copies of predefined lists
  const [jobResponsibilities, setJobResponsibilities] = useState(predefinedJobResponsibilities);
  const [jobRequirements, setJobRequirements] = useState(predefinedJobRequirements);
  const [jobQualifications, setJobQualifications] = useState(predefinedJobQualifications);
  const [skills, setSkills] = useState(predefinedSkills);

  const validateForm = () => {
    const errors = {};
    if (!newJob.title.trim()) {
      errors.title = "Job title is required";
    }
    if (!newJob.jobType) {
      errors.jobType = "Job type is required";
    }
    if (!newJob.timeAvailability) {
      errors.timeAvailability = "Time availability is required";
    }
    if (!newJob.description.trim()) {
      errors.description = "Job description is required";
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleCreateJob = async () => {
    setSubmissionLoading(true);
    if (!validateForm()) {
      setSubmissionLoading(false);
      return;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("userInfo")).token}`,
      },
    };

    const jobData = {
      title: newJob.title,
      description: newJob.description,
      jobType: newJob.jobType,
      skills: newJob.skills,
      timeAvailability: newJob.timeAvailability,
      status: "active",
      responsibilities: newJob.responsibilities,
      requirements: newJob.requirements,
      qualifications: newJob.qualifications,
      organizationID: orgId,
    };

    try {
      const { data, status } = await axiosInstance.post(`${url}/job`, jobData, config);
      SuccessNotification("Job created successfully!");
      fetchData();

      setNewJob({
        title: "",
        jobType: "",
        timeAvailability: "",
        description: "",
        responsibilities: [],
        requirements: [],
        qualifications: [],
        skills: [],
      });
      handleClose();
    } catch (error) {
      ErrorNotification(error.response.data.message ? error.response.data.message : error.response.data ? error.response.data : "Action Failed");
    } finally {
      setSubmissionLoading(false);
    }
  };

  // Function to handle dynamic addition of new responsibilities, requirements, etc.
  const handleAddToList = (list, setList, newItem, setNewItem) => {
    if (newItem.trim()) {
      setList([...list, newItem]);
      setNewItem(""); // Clear input field
    }
  };

  // Handle field changes for text input fields (title, description, etc.)
  const handleNewJobChange = (field, value) => {
    setNewJob((prevJob) => ({
      ...prevJob,
      [field]: value,
    }));
  };

  // Handle checkbox toggling for responsibilities, requirements, qualifications, and skills
  const handleCheckboxChange = (field, item) => {
    setNewJob((prevJob) => {
      const currentFieldItems = prevJob[field];
      const isChecked = currentFieldItems.includes(item);

      return {
        ...prevJob,
        [field]: isChecked
          ? currentFieldItems.filter((i) => i !== item) // Remove if already checked
          : [...currentFieldItems, item],               // Add if not checked
      };
    });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="modal-dialog-scrollable"
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Create a New Job</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="jobTitle">
            <Form.Label>Job Title</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter job title"
              value={newJob.title}
              onChange={(e) => {
                handleNewJobChange("title", e.target.value);
                setValidationErrors({ ...validationErrors, title: "" });
              }}
              isInvalid={!!validationErrors.title}
              required
            />
            <Form.Control.Feedback type="invalid">
              {validationErrors.title}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="jobType">
            <Form.Label>Job Type</Form.Label>
            <Form.Control
              as="select"
              value={newJob.jobType}
              onChange={(e) => {
                handleNewJobChange("jobType", e.target.value);
                setValidationErrors({ ...validationErrors, jobType: "" });
              }}
              isInvalid={!!validationErrors.jobType}
              required
            >
              <option value="">Select Job Type</option>
              <option>Accounting</option>
              <option>Legal</option>
              <option>Engineering</option>
              <option>Marketing</option>
              <option>Sales</option>
              <option>Management</option>
              <option>Other</option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {validationErrors.jobType}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="timeAvailability">
            <Form.Label>Time Availability</Form.Label>
            <Form.Control
              as="select"
              value={newJob.timeAvailability}
              onChange={(e) => {
                handleNewJobChange("timeAvailability", e.target.value);
                setValidationErrors({ ...validationErrors, timeAvailability: "" });
              }}
              isInvalid={!!validationErrors.timeAvailability}
              required
            >
              <option value="">Select Time Availability</option>
              <option value="full-time">Full-Time</option>
              <option value="part-time">Part-Time</option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {validationErrors.timeAvailability}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="jobDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter job description"
              value={newJob.description}
              onChange={(e) => {
                handleNewJobChange("description", e.target.value);
                setValidationErrors({ ...validationErrors, description: "" });
              }}
              isInvalid={!!validationErrors.description}
              required
            />
            <Form.Control.Feedback type="invalid">
              {validationErrors.description}
            </Form.Control.Feedback>
          </Form.Group>

          {/* Job Responsibilities */}
          <Form.Group controlId="jobResponsibilities">
            <Form.Label>Job Responsibilities</Form.Label>
            <Card body className="mb-3">
              {jobResponsibilities.map((responsibility, index) => (
                <Form.Check
                  key={responsibility}
                  id={`responsibility-${index}`}
                  type="checkbox"
                  label={responsibility}
                  checked={newJob.responsibilities.includes(responsibility)}
                  onChange={() => handleCheckboxChange("responsibilities", responsibility)}
                />
              ))}
              <Form.Control
                type="text"
                placeholder="Add new responsibility"
                value={newResponsibility}
                onChange={(e) => setNewResponsibility(e.target.value)}
                // Add the key press event to detect "Enter" key
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault(); // Prevent form submission on Enter
                    handleAddToList(jobResponsibilities, setJobResponsibilities, newResponsibility, setNewResponsibility);
                  }
                }}
              />
              <Button
                variant="primary"
                className="mt-2"
                size="sm"
                onClick={() => handleAddToList(jobResponsibilities, setJobResponsibilities, newResponsibility, setNewResponsibility)}
              >
                Add New Responsibility
              </Button>
            </Card>
          </Form.Group>

          {/* Job Requirements */}
          <Form.Group controlId="jobRequirements">
            <Form.Label>Job Requirements</Form.Label>
            <Card body className="mb-3">
              {jobRequirements.map((requirement, index) => (
                <Form.Check
                  key={requirement}
                  id={`requirement-${index}`}
                  type="checkbox"
                  label={requirement}
                  checked={newJob.requirements.includes(requirement)}
                  onChange={() => handleCheckboxChange("requirements", requirement)}
                />
              ))}
              <Form.Control
                type="text"
                placeholder="Add new requirement"
                value={newRequirement}
                onChange={(e) => setNewRequirement(e.target.value)}
                // Add the key press event to detect "Enter" key
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault(); // Prevent form submission on Enter
                    handleAddToList(jobRequirements, setJobRequirements, newRequirement, setNewRequirement);
                  }
                }}
              />
              <Button
                variant="primary"
                className="mt-2"
                size="sm"
                onClick={() => handleAddToList(jobRequirements, setJobRequirements, newRequirement, setNewRequirement)}
              >
                Add New Requirement
              </Button>
            </Card>
          </Form.Group>

          {/* Job Qualifications */}
          <Form.Group controlId="jobQualifications">
            <Form.Label>Job Qualifications</Form.Label>
            <Card body className="mb-3">
              {jobQualifications.map((qualification, index) => (
                <Form.Check
                  key={qualification}
                  id={`qualification-${index}`}
                  type="checkbox"
                  label={qualification}
                  checked={newJob.qualifications.includes(qualification)}
                  onChange={() => handleCheckboxChange("qualifications", qualification)}
                />
              ))}
              <Form.Control
                type="text"
                placeholder="Add new qualification"
                value={newQualification}
                onChange={(e) => setNewQualification(e.target.value)}
              />
              <Button
                variant="primary"
                className="mt-2"
                size="sm"
                onClick={() => handleAddToList(jobQualifications, setJobQualifications, newQualification, setNewQualification)}
              >
                Add New Qualification
              </Button>
            </Card>
          </Form.Group>

          {/* Skills */}
          <Form.Group controlId="jobSkills">
            <Form.Label>Skills</Form.Label>
            <Card body className="mb-3">
              {skills.map((skill, index) => (
                <Form.Check
                  key={skill}
                  id={`skill-${index}`}
                  type="checkbox"
                  label={skill}
                  checked={newJob.skills.includes(skill)}
                  onChange={() => handleCheckboxChange("skills", skill)}
                />
              ))}
              <Form.Control
                type="text"
                placeholder="Add new skill"
                value={newSkill}
                onChange={(e) => setNewSkill(e.target.value)}
              />
              <Button
                variant="primary"
                className="mt-2"
                size="sm"
                onClick={() => handleAddToList(skills, setSkills, newSkill, setNewSkill)}
              >
                Add New Skill
              </Button>
            </Card>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleCreateJob}
          disabled={submissionLoading}
        // disabled={
        //   !newJob.title.trim() ||
        //   !newJob.jobType ||
        //   !newJob.timeAvailability ||
        //   !newJob.description.trim()
        // }
        >
          {submissionLoading ? (
            <span className="spinner-border spinner-border-sm" />
          ) : (
            "Create Job"
          )}

        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateJobModal;
