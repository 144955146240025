const features = [
  {
    avatar: "share-2",
    title: "Resource Sharing",
    description:
      "Access and contribute to a vetted collection of tools, technologies, and processes. If you can't find what you need, we're here to help.",
    variant: "primary",
    containerClass: "d-flex border-bottom pb-4",
  },
  {
    avatar: "shield",
    title: "Trusted Community",
    description:
      "Access and contribute to a vetted collection of tools, technologies, and processes. If you can't find what you need, we're here to help.",
    variant: "success",
    containerClass: "d-flex border-bottom py-4",
  },
  {
    avatar: "cpu",
    title: "Cutting-edge TechHub",
    description: "Explore and share the latest in startup technologies.",
    variant: "orange",
    containerClass: "d-flex border-bottom py-4",
  },
  {
    avatar: "book-open",
    title: "Knowledge Exchange",
    description:
      "Benefit from a repository of articles, guides, and shared experiences.",
    variant: "info",
    containerClass: "d-flex border-bottom py-4",
  },
  {
    avatar: "users",
    title: "Mentorship and Collaboration",
    description:
      "Connect for guidance and collaborate on innovative solutions.",
    variant: "warning",
    containerClass: "d-flex pt-4",
  },
];

export { features };
